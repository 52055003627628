import styled from 'styled-components';
import colors from '../../styles/colors';

export const InfoWithSubInfoWrapper = styled.div<{ flexGrow: number }>`
  flex: 0 1 ${(props) => props.flexGrow}%;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-align: left;
`;

export const Info = styled.div`
  color: ${colors.grayDark};
  font-weight: 600;
`;

export const SubInfo = styled.div`
  color: ${colors.gray};
`;
