import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HeaderItem = styled.li`
  position: relative;
  list-style: none;
  padding: 0.4rem 1rem;
`;

export const HeaderSelectedItem = styled.div`
  position: absolute;
  top: 6px;
  right: 0px;
  height: 3rem;
  border-right: 2px solid ${colors.orange};
`;

export const HeaderLink = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  height: 3rem;
  font-size: 1rem;
  background-color: ${(props) => (props.isSelected ? colors.blueLight : colors.white)};
  border-radius: 4px;
  padding-left: 13px;
  color: ${(props) => (props.isSelected ? colors.grayDark : colors.gray)};
  font-weight: bold;
  svg {
    path {
      fill: ${(props) => (props.isSelected ? colors.blueDark : colors.gray)};
    }
  }

  transition: background-color 400ms;

  :hover {
    background-color: ${colors.blueLight};
  }
`;

export const LinkContent = styled.span`
  padding-left: 2rem;
`;
