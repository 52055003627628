/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import { AudienceInfoContent, AudienceInfoTitle, AudienceInfoWrapper } from './AudienceInfo.styles';
import useApi from '../../../../hooks/useApi';
import { AudienceResponseType } from '../../../../types/Audience.type';

type AudienceInfoProps = {
  audienceId: string;
};

const AudienceInfo: React.FC<AudienceInfoProps> = ({ audienceId }) => {
  const [{ responseData, isLoading }, makeRequest] = useApi<AudienceResponseType>();

  useEffect(() => {
    if (audienceId) {
      makeRequest({
        url: `/msg-box-api/audiences/${audienceId}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId]);

  return (
    <AudienceInfoWrapper>
      {isLoading && !responseData ? (
        <Loader height={0.8} width={0.3} />
      ) : (
        <>
          <AudienceInfoTitle>送信先</AudienceInfoTitle>
          <AudienceInfoContent>{responseData?.title}</AudienceInfoContent>
        </>
      )}
    </AudienceInfoWrapper>
  );
};

export default AudienceInfo;
