import React from 'react';
import { useHistory } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import BaseContentModal from '../../../components/BaseContentModal/BaseContentModal';
import {
  InfoTitle,
  InfoWrapper,
  DateCreationInfo,
  Flag,
  Separator,
  Title,
  InfoContent,
  DescriptionWrapper,
  DescriptionTitle,
  DescriptionContent,
  LoaderWrapper,
} from './MessageModal.styles';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import AudienceInfo from './AudienceInfo/AudienceInfo';
import Loader from '../../../components/Loader/Loader';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import HtmlMessage from '../../../components/HtmlMessage/HtmlMessage';

type MessageType = {
  id: string;
  title: string;
  createdAt: string;
  importantFlag: boolean;
  audienceId: string;
  sentOn: string;
  description: string;
};

type MessageModalProps = {
  messageId: string;
  closeModal(): void;
};

const MessageModal: React.FC<MessageModalProps> = ({ messageId, closeModal }) => {
  const history = useHistory();
  const [{ responseData, isLoading, errorResponseData }] = useApi<MessageType>({
    url: `/msg-box-api/messages/${messageId}?fields=id,audienceId,createdAt,sentOn,title,importantFlag,description`,
  });

  return (
    <>
      <BaseContentModal title={'メッセージ'} closeModal={closeModal}>
        {!isLoading && responseData ? (
          <>
            <Title>{responseData.title}</Title>
            <DateCreationInfo>{fromDateToJapaneseStringDate(new Date(responseData.createdAt as string))}</DateCreationInfo>
            {responseData.importantFlag && <Flag>重要</Flag>}
            <Separator />
            <AudienceInfo audienceId={responseData.audienceId as string} />
            <Separator />
            <InfoWrapper>
              <InfoTitle>送信日程</InfoTitle>
              <InfoContent>{fromDateToJapaneseStringDate(new Date(responseData.sentOn as string))}</InfoContent>
            </InfoWrapper>
            <Separator />
            <HtmlMessage isReadOnly onClick={() => history.push({ pathname: `/message-html/${messageId}`, state: { from: 'fromSignUp' } })} />
            <Separator />
            <DescriptionWrapper>
              <DescriptionTitle>詳細</DescriptionTitle>
              <DescriptionContent>{responseData.description}</DescriptionContent>
            </DescriptionWrapper>
          </>
        ) : (
          <LoaderWrapper>
            <Loader height={1} width={1} />
          </LoaderWrapper>
        )}
        {errorResponseData && <ErrorMessages messages={[...errorResponseData]} />}
      </BaseContentModal>
    </>
  );
};

export default MessageModal;
