import { Field, ValidatorSetup } from '@alj-react/efo';

export type FilterFormFields = {
  isFilterByDate: boolean;
  isFilterByKeyword: boolean;
  isFilterByTitle: boolean;
  dateFrom4Y: string;
  dateFromDD: string;
  dateFromMM: string;
  dateTo4Y: string;
  dateToDD: string;
  dateToMM: string;
  title: string;
  word: string;
};

export const initFilterForm: ValidatorSetup<FilterFormFields> = {
  dateFrom4Y: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateFromDD || !allValues.dateFromMM)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  dateFromDD: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateFromMM || !allValues.dateFrom4Y)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  dateFromMM: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateFromDD || !allValues.dateFrom4Y)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  dateTo4Y: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateToDD || !allValues.dateToMM)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  dateToDD: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateToMM || !allValues.dateTo4Y)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  dateToMM: {
    custom: (value, allValues) => {
      if (value && (!allValues.dateToDD || !allValues.dateTo4Y)) {
        return 'フィールドがありません';
      }
      return '';
    },
  },
  title: {},
  word: {},
  isFilterByDate: { defaultValue: false },
  isFilterByKeyword: { defaultValue: false },
  isFilterByTitle: { defaultValue: false }
};

export type FilterForm = {
  fields: {
    [K in keyof FilterFormFields]: Field<FilterFormFields[K]>;
  };
  values: FilterFormFields;
  validate: () => void;
  isValid: boolean;
  reset: () => void;
};
