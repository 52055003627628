import React from 'react';
import { CloneSubmitWrapper, FooterFormReadOnlyWrapper, FooterFormWrapper } from './FormFooter.styles';
import Button from '../../../../components/Button/Button';
import colors from '../../../../styles/colors';

type FormFooterProps = {
  isLoading: boolean;
  isReadOnly: boolean;
  cloneMessage(): void;
  closeModal(withRefresh: boolean): void;
};

const FormFooter: React.FC<FormFooterProps> = ({ isLoading, isReadOnly, cloneMessage, closeModal }) => {
  return (
    <>
      {!isReadOnly ? (
        <FooterFormWrapper>
          <Button backgroundColor={colors.grayLight} textColor={colors.gray} hoverBackgroundColor={colors.white} onClick={() => closeModal(false)}>
            キャンセル
          </Button>
          <CloneSubmitWrapper>
            <Button backgroundColor={colors.blueLight} textColor={colors.blue} isLoading={isLoading} onClick={cloneMessage}>
              クローン
            </Button>
            <Button type="submit" backgroundColor={colors.axaBlue} textColor={colors.white} isLoading={isLoading}>
              確認する
            </Button>
          </CloneSubmitWrapper>
        </FooterFormWrapper>
      ) : (
        <FooterFormReadOnlyWrapper>
          <Button backgroundColor={colors.blueLight} textColor={colors.blue} isLoading={isLoading} onClick={cloneMessage}>
            クローン
          </Button>
        </FooterFormReadOnlyWrapper>
      )}
    </>
  );
};

export default FormFooter;
