import styled from 'styled-components';
import colors from '../../styles/colors';

const InformationItemButton = styled.button<{ flexGrow: number }>`
  flex: 0 1 ${(props) => props.flexGrow}%;
  border: none;
  padding: 0;
  background-color: ${colors.grayLighter};
  color: ${colors.gray};
  font-size: 0.8rem;
  text-align: left;
  svg {
    margin-right: 0.5rem;
  }
`;

export default InformationItemButton;
