import styled from 'styled-components';
import colors from '../../styles/colors';

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  margin: 0;
  width: 100%;
  height: 1.3rem;
  opacity: 0;
  top: 0;
  left: 0;
`;

export const Icon = styled.svg`
  position: absolute;
  height: 1rem;
  width: 0.9rem;
  fill: none;
  stroke: white;
  stroke-width: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Label = styled.span<{ checked: boolean }>`
  display: inline-block;
  margin-left: 1.3rem;
  color: ${(props) => (props.checked ? colors.blue : colors.gray)};
  font-weight: 600;
  font: 0.8rem;
`;

export const StyledCheckbox = styled.label<{ checked: boolean; disabled: boolean }>`
  position: relative;
  display: inline-block;
  height: 1.3rem;
  width: 1.3rem;
  cursor: pointer;
  background: ${(props) => {
    if (props.disabled) {
      return colors.grayLighter;
    }
    if (props.checked) {
      return colors.axaBlue;
    }
    return colors.grayLight;
  }};
  border-radius: 4px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${colors.blueLight};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const CheckMark = styled.span``;
