import { Method } from 'axios';

export enum ApiActionsType {
  REQUEST_INIT,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
}

export type RequestInit = {
  type: ApiActionsType.REQUEST_INIT;
};

export type RequestSuccess<T> = {
  type: ApiActionsType.REQUEST_SUCCESS;
  payload: {
    responseData: T;
    responseStatus: number;
    responseMethod?: Method;
    responseHeaders: {
      'pagination-total-count': string;
      'pagination-current-page': string;
      'pagination-page-size': string;
    };
  };
};

export type RequestFailure = {
  type: ApiActionsType.REQUEST_FAILURE;
  payload: {
    errorMessage: string;
    errorResponseData: string[];
    responseStatus: number;
    responseMethod?: Method;
  };
};

export type ApiActions<T> = RequestInit | RequestSuccess<T> | RequestFailure;
