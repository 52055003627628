import styled from 'styled-components';
import colors from '../../styles/colors';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentStepWrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
  overflow: hidden;
`;

export const CreateMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0 1.4rem;
`;

export const HtmlCodeStepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const HtmlCodeStepContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const SideBox = styled.div`
  flex: 1 1 50%;
  border-right: 2px solid ${colors.background};
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HtmlCodeHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
`;

export const HtmlCodeHeaderTitle = styled.h3`
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${colors.grayDark};
`;

export const PreviewContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const CodeHtmlContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const CodeSideContent = styled.div`
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  line-height: 1.4rem;
  font-size: 1rem;
  word-break: break-all;
  overflow-wrap: anywhere;
  overflow-y: auto;
  resize: none;
  :focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;