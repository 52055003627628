/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  DatePickerInput,
  DatePickerInputIcon,
  DatePickerInputWrapper,
  DatePickerLabelInput,
  DatePickerLabelSpan,
  DatePickerWrapper,
} from './DatePicker.styles';
import Calendar from './Calendar/Calendar';
import CalendarIcon from '../../svgs/CalendarIcon';

type DatePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
  updateDate(timestamp: number): void;
  minTimestamp?: number;
  maxTimestamp?: number;
};

const DatePicker: React.FC<DatePickerProps> = ({ updateDate, minTimestamp, maxTimestamp, ...rest }) => {
  const [showDatePickerContent, setShowDatePickerContent] = useState(false);

  const toggleDatePicker = () => {
    setShowDatePickerContent((currentState) => !currentState);
  };

  const updateDateInput = (timestamp: number) => {
    updateDate(timestamp);
  };

  return (
    <DatePickerWrapper>
      <DatePickerLabelInput onClick={toggleDatePicker}>
        <DatePickerLabelSpan>送信日</DatePickerLabelSpan>
        <DatePickerInputWrapper>
          <DatePickerInput readOnly={true} {...rest} />
          <DatePickerInputIcon>
            <CalendarIcon height={25} width={25} />
          </DatePickerInputIcon>
        </DatePickerInputWrapper>
      </DatePickerLabelInput>
      {showDatePickerContent && (
        <Calendar updateDate={updateDateInput} closeCalendar={toggleDatePicker} minTimestamp={minTimestamp} maxTimestamp={maxTimestamp} />
      )}
    </DatePickerWrapper>
  );
};

export default DatePicker;
