import React, { useState } from 'react';
import InformationItemButton from './TabHeaderItem.styles';
import SortAlphaDownIcon from '../../svgs/SortAlphaDownIcon';
import SortAlphaUpIcon from '../../svgs/SortAlphaUpIcon';

const sortIconRender = (hasSortItem: boolean, isSortDown: boolean) => {
  if (hasSortItem) {
    return isSortDown ? <SortAlphaDownIcon height="14" width="14" /> : <SortAlphaUpIcon height="14" width="14" />;
  }
  return null;
};

type TabHeaderItemProps = {
  hasSortItem: boolean;
  title: string;
  reverseSortAction?: () => void;
  flexGrow: number;
};

const TabHeaderItem: React.FC<TabHeaderItemProps> = ({ hasSortItem, reverseSortAction, title, flexGrow }) => {
  const [isSortDown, isSortDownSet] = useState(true);
  const reverseSort = () => {
    isSortDownSet(!isSortDown);
    if (reverseSortAction) {
      reverseSortAction();
    }
  };

  return (
    <InformationItemButton flexGrow={flexGrow} onClick={reverseSort}>
      {sortIconRender(hasSortItem, isSortDown)}
      {title}
    </InformationItemButton>
  );
};

export default TabHeaderItem;
