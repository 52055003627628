import React, { useState } from 'react';
import {
  Background,
  CalendarBody,
  CalendarDayItem,
  CalendarDayItemButton,
  CalendarDaysList,
  CalendarHeaderDaysItem,
  CalendarHeaderDaysList,
  CalendarNavigation,
  CalendarNavigationButton,
  CalendarNavigationCurrentDateWrapper,
  CalendarNavigationCurrentMonth,
  CalendarNavigationCurrentYear,
  CalendarWrapper,
} from './Calendar.styles';

type DayDetails = {
  index: number;
  numberOfDaysInTheMonth: number;
  firstDay: number;
  year: number;
  month: number;
};

type MonthDetails = {
  date: number;
  month: number;
  timestamp: number;
};

const oneDayInMilliseconds = 60 * 60 * 24 * 1000;
const todayTimestamp = Date.now() - (Date.now() % oneDayInMilliseconds) + new Date().getTimezoneOffset() * 1000 * 60;

const daysMap = ['日', '月', '火', '水', '木', '金', '土'];
const monthMap = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月 ', '九月', '十月', '十一月', '十二月'];

const getMonthStr = (month: number) => monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

// the number 32 is a number superior of the maximum day in one month (could be 33, 34...)
const getNumberOfDaysInTheMonth = (year: number, month: number) => {
  return 32 - new Date(year, month, 32).getDate();
};

const getCurrentMonth = (newDate: number, numberOfDays: number) => {
  if (newDate < 0) {
    return -1;
  }
  if (newDate >= numberOfDays) {
    return 1;
  }
  return 0;
};

// because a row start from sunday to saturday, we can determine a day before and after the current month
const getDayDetails = (data: DayDetails): MonthDetails => {
  const diffIndexFromFirstDay = data.index - data.firstDay;
  let prevMonth = data.month - 1;
  let prevYear = data.year;
  if (prevMonth < 0) {
    prevMonth = 11;
    prevYear -= 1;
  }
  const prevMonthNumberOfDays = getNumberOfDaysInTheMonth(prevYear, prevMonth);
  const date = (diffIndexFromFirstDay < 0 ? prevMonthNumberOfDays + diffIndexFromFirstDay : diffIndexFromFirstDay % data.numberOfDaysInTheMonth) + 1;
  const month = getCurrentMonth(diffIndexFromFirstDay, data.numberOfDaysInTheMonth);

  const timestamp = new Date(data.year, data.month, date).getTime();
  return {
    date,
    month,
    timestamp,
  };
};

// generate the days of the month with days before and after the current month
const getMonthDetails = (year: number, month: number): MonthDetails[] => {
  const firstDay = new Date(year, month).getDay(); // get the day of the week, start from 0
  const numberOfDaysInTheMonth = getNumberOfDaysInTheMonth(year, month);
  const monthArray = [];
  const rows = 6;
  let currentDay = null;
  let index = 0;
  const cols = 7;

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      currentDay = getDayDetails({ index, numberOfDaysInTheMonth, firstDay, year, month });
      monthArray.push(currentDay);
      index += 1;
    }
  }
  return monthArray;
};

type CalendarProps = {
  updateDate(timestamp: number): void;
  closeCalendar(): void;
  minTimestamp?: number;
  maxTimestamp?: number;
};

const Calendar: React.FC<CalendarProps> = ({ updateDate, closeCalendar, minTimestamp, maxTimestamp }) => {
  const initialDate = new Date();
  const [monthDetails, setMonthDetails] = useState(getMonthDetails(initialDate.getFullYear(), initialDate.getMonth()));
  const [selectedDay, setSelectedDay] = useState(todayTimestamp);
  const [currentYear, setCurrentYear] = useState(initialDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(initialDate.getMonth());

  const setYear = (offset: number) => {
    const year = currentYear + offset;
    setCurrentYear(year);
    setMonthDetails(getMonthDetails(year, currentMonth));
  };

  const setMonth = (offset: number) => {
    let year = currentYear;
    let month = currentMonth + offset;
    if (month === -1) {
      month = 11;
      year -= 1;
    }
    if (month === 12) {
      month = 0;
      year += 1;
    }
    setCurrentYear(year);
    setCurrentMonth(month);
    setMonthDetails(getMonthDetails(year, month));
  };

  const changeSelectedDate = (timestamp: number) => {
    setSelectedDay(timestamp);
    updateDate(timestamp);
  };

  // Day after max timestamp and before min timestamp are disabled
  const isDisableDayItem = (day: MonthDetails) => {
    const isMin = minTimestamp ? day.timestamp < minTimestamp : false;
    const isMax = maxTimestamp ? day.timestamp > maxTimestamp : false;
    if (day.month !== 0 || isMin || isMax) {
      return true;
    }
    return false;
  };

  return (
    <Background onClick={closeCalendar}>
      <CalendarWrapper
        data-testid="calendar"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CalendarNavigation>
          <CalendarNavigationButton type="button" onClick={() => setYear(-1)}>
            {'<<'}
          </CalendarNavigationButton>
          <CalendarNavigationButton data-testid="calendar_previous_month" type="button" onClick={() => setMonth(-1)}>
            {'<'}
          </CalendarNavigationButton>
          <CalendarNavigationCurrentDateWrapper>
            <CalendarNavigationCurrentYear>{currentYear}</CalendarNavigationCurrentYear>
            <CalendarNavigationCurrentMonth>{getMonthStr(currentMonth)}</CalendarNavigationCurrentMonth>
          </CalendarNavigationCurrentDateWrapper>
          <CalendarNavigationButton data-testid="calendar_next_month" type="button" onClick={() => setMonth(1)}>
            {'>'}
          </CalendarNavigationButton>
          <CalendarNavigationButton type="button" onClick={() => setYear(1)}>
            {'>>'}
          </CalendarNavigationButton>
        </CalendarNavigation>
        <CalendarBody>
          <CalendarHeaderDaysList>
            {daysMap.map((day) => (
              <CalendarHeaderDaysItem key={day}>{day}</CalendarHeaderDaysItem>
            ))}
          </CalendarHeaderDaysList>
          <CalendarDaysList>
            {monthDetails.map((day) => (
              <CalendarDayItem key={`${day.timestamp}${day.date}${day.month}`}>
                <CalendarDayItemButton
                  data-testid={`${day.date}${day.month}`}
                  type="button"
                  isCurrentDay={day.timestamp === todayTimestamp}
                  isSelectedDay={day.timestamp === selectedDay}
                  disabled={isDisableDayItem(day)}
                  onClick={() => changeSelectedDate(day.timestamp)}
                >
                  {day.date}
                </CalendarDayItemButton>
              </CalendarDayItem>
            ))}
          </CalendarDaysList>
        </CalendarBody>
      </CalendarWrapper>
    </Background>
  );
};

export default Calendar;
