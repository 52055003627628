/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ErrorMessagesItem, ErrorMessagesText, ErrorMessagesWrapper } from './ErrorMessages.styles';

type ErrorMessageProps = {
  messages: string[];
};

const ErrorMessages: React.FC<ErrorMessageProps> = ({ messages }) => {
  const renderMessages = () => {
    return messages.map(
      (message, index) =>
        message && (
          <ErrorMessagesItem key={`${message}${index}`}>
            <ErrorMessagesText>{message}</ErrorMessagesText>
          </ErrorMessagesItem>
        ),
    );
  };

  return <ErrorMessagesWrapper>{renderMessages()}</ErrorMessagesWrapper>;
};

export default ErrorMessages;
