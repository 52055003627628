import React from 'react';

const CaretIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M9 12L17.6603 0H0.339746L9 12Z" fill={fill} />
    </svg>
  );
};

export default CaretIcon;
