import styled from 'styled-components';

export const DialogueBoxContent = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

export const DialogueBoxFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
