import styled from 'styled-components';
import colors from '../../../styles/colors';

export const CreateMessageForm = styled.form`
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: start;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin: 2rem 0;
  border-bottom: solid 1px ${colors.grayLight};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const ErrorMessage = styled.p`
  font-size: 1rem;
  font-weight: 600;
`;
