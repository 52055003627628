import React, { createContext, useContext, useReducer } from 'react';
import { MessageResponseType } from '../../types/Message.type';
import { MessageActionType, MessageActions } from './actions';
import messageContextInitialState, { MessageContextState, ValidationMessageEnum } from './state';

export const MessageContext = createContext<{
  state: MessageContextState;
  dispatch: React.Dispatch<MessageActions>;
}>({
  state: messageContextInitialState,
  dispatch: () => undefined,
});

type MessageProviderProps = {
  messageContextInit?: MessageContextState;
};

const messageReducer = (state: MessageContextState, action: MessageActions): MessageContextState => {
  switch (action.type) {
    case MessageActionType.ResetForm:
      return { ...state, isSubmitted: false, isValid: false };
    case MessageActionType.Update:
      // eslint-disable-next-line no-case-declarations
      const { audienceId, sentOn, lastModifiedBy, createdFrom, title, content, importantFlag, description, createdAt, updatedAt, id } =
        action.payload.message;
      return {
        ...state,
        message: {
          ...state.message,
          id,
          audienceId,
          sentOn,
          lastModifiedBy,
          createdFrom,
          title,
          content,
          importantFlag,
          description,
          createdAt,
          updatedAt,
        },
      };
    case MessageActionType.UpdateTitle:
      return { ...state, message: { ...state.message, title: action.payload.title } };
    case MessageActionType.UpdateDescription:
      return { ...state, message: { ...state.message, description: action.payload.description } };
    case MessageActionType.UpdateImportantFlag:
      return { ...state, message: { ...state.message, importantFlag: !state.message.importantFlag } };
    case MessageActionType.UpdateContent:
      return { ...state, message: { ...state.message, content: action.payload.content } };
    case MessageActionType.UpdateAudience:
      return { ...state, message: { ...state.message, audienceId: action.payload.audience } };
    case MessageActionType.UpdateScheduler:
      return { ...state, message: { ...state.message, sentOn: action.payload.scheduler } };
    case MessageActionType.ValidationFormTitle:
      if (!state.message.title) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.Title };
      }
      return { ...state, isSubmitted: true, isValid: true, validationMessage: '' };
    case MessageActionType.ValidationContent:
      if (!state.message.content) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.Content };
      }
      return { ...state, isSubmitted: true, isValid: true, validationMessage: '' };
    case MessageActionType.ValidationTargetUser:
      // AudienceId is required
      if (!state.message.audienceId) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.Audience };
      }
      return { ...state, isSubmitted: true, isValid: true, validationMessage: '' };
    case MessageActionType.LastValidation:
      // Title is required
      if (!state.message.title) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.Title };
      }
      // Content is required
      if (!state.message.content) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.PreviousStepContent };
      }
      // AudienceId is required
      if (!state.message.audienceId) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.PreviousStepAudience };
      }
      // SentOn is required and cannot be in the past
      if (!state.message.sentOn || new Date(state.message.sentOn) < new Date()) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationMessageEnum.Scheduler };
      }
      return { ...state, isSubmitted: true, isValid: true, validationMessage: '' };
    default:
      return state;
  }
};

export const MessageProvider: React.FC<MessageProviderProps> = ({ messageContextInit, children }) => {
  const initialState = {
    ...messageContextInitialState,
    ...messageContextInit,
  };
  const [state, dispatch] = useReducer(messageReducer, initialState);

  return <MessageContext.Provider value={{ state, dispatch }}>{children}</MessageContext.Provider>;
};

type UseMessageContextType = {
  resetForm: () => void;
  update: (message: MessageResponseType) => void;
  updateTitle: (newTitle: string) => void;
  updateDescription: (newDescription: string) => void;
  updateContent: (newContent: string) => void;
  updateAudience: (newAudience: string) => void;
  updateScheduler: (newScheduler: string | null) => void;
  updateImportant: () => void;
  validationFormTitle: () => void;
  validationContent: () => void;
  validationTargetUser: () => void;
  LastValidation: () => void;
  state: MessageContextState;
};

export const useMessageContext = (): UseMessageContextType => {
  const { state, dispatch } = useContext(MessageContext);

  const resetForm = () => dispatch({ type: MessageActionType.ResetForm });
  const update = (message: MessageResponseType) => dispatch({ type: MessageActionType.Update, payload: { message } });
  const updateTitle = (newTitle: string) => dispatch({ type: MessageActionType.UpdateTitle, payload: { title: newTitle } });
  const updateDescription = (newDescription: string) =>
    dispatch({ type: MessageActionType.UpdateDescription, payload: { description: newDescription } });
  const updateContent = (newContent: string) => dispatch({ type: MessageActionType.UpdateContent, payload: { content: newContent } });
  const updateImportant = () => dispatch({ type: MessageActionType.UpdateImportantFlag });
  const updateAudience = (newAudience: string) => dispatch({ type: MessageActionType.UpdateAudience, payload: { audience: newAudience } });
  const updateScheduler = (newScheduler: string | null) =>
    dispatch({ type: MessageActionType.UpdateScheduler, payload: { scheduler: newScheduler } });
  const validationFormTitle = () => dispatch({ type: MessageActionType.ValidationFormTitle });
  const validationContent = () => dispatch({ type: MessageActionType.ValidationContent });
  const validationTargetUser = () => dispatch({ type: MessageActionType.ValidationTargetUser });
  const LastValidation = () => dispatch({ type: MessageActionType.LastValidation });

  return {
    resetForm,
    update,
    updateTitle,
    updateDescription,
    updateContent,
    updateImportant,
    updateAudience,
    updateScheduler,
    validationFormTitle,
    validationContent,
    validationTargetUser,
    LastValidation,
    state,
  };
};

export default messageReducer;
