import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HtmlCodeStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HtmlCodeStepContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const SideBox = styled.div`
  flex: 1 1 50%;
  border-right: 2px solid ${colors.background};
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HtmlCodeHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
`;

export const HtmlCodeHeaderTitle = styled.h3`
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${colors.grayDark};
`;

export const HtmlCodeHeaderButton = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
`;

export const CodeHtmlContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const PreviewContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const ContentLabel = styled.label`
  display: block;
  height: 0;
  margin: 0;
  padding: 0;
`;

export const CodeSideContentTLineNumber = styled.ul`
  padding: 0;
  margin: 0 1rem 0 0;
  width: 50%;
  li {
    text-align: left;
    font-size: 1rem;
    line-height: 1.4rem;
    width: 1.2rem;
  }
`;

export const CodeSideContentTextArea = styled.textarea`
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  line-height: 1.4rem;
  font-size: 1rem;
  word-break: break-all;
  overflow-wrap: anywhere;
  overflow-y: auto;
  resize: none;
  :focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  background-color: ${colors.background};
  button:last-child {
    margin-left: 1.5rem;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translate(0, -50%);
  color: ${colors.axaRed};
  font-size: 1rem;
  line-height: 1rem;
`;
