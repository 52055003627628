/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { DeleteButtonWrapper, ImageBase64Content, UploadImageWrapper, UploadInfoSpan, UploadLabel, UploadSubInfoSpan } from './UploadImage.styles';
import colors from '../../../../styles/colors';
import Button from '../../../../components/Button/Button';
import ErrorMessages from '../../../../components/ErrorMessages/ErrorMessages';
import CopyButton from './CopyButton/CopyButton';
import CloudDownloadIcon from '../../../../svgs/CloudDownloadIcon';
import CloudUploadIcon from '../../../../svgs/CloudUploadIcon';

enum ImageError {
  InternalError = 'Internal error with image file',
  FileSize = 'Maximum file upload size is 500ko',
  FileType = 'File is not an image',
}

const UploadImage: React.FC = () => {
  const IMAGE_FILE_SIZE = parseInt(process.env.REACT_APP_IMAGE_FILE_MAX_SIZE as string, 10);
  const [imageBase64, setImageBase64] = useState('');
  const [imageErrorMessage, setImageErrorMessage] = useState('');

  const onDropAccepted = useCallback((files) => {
    setImageErrorMessage('');
    const reader = new FileReader();
    try {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const imageHtmlWithContent = `<img height="100" width="100" src="${reader.result}" />`;
        setImageBase64(imageHtmlWithContent);
      };
    } catch (error) {
      setImageErrorMessage(ImageError.InternalError);
    }
  }, []);

  const onDropRejected = useCallback((files: FileRejection[]) => {
    if (files[0].file.size > IMAGE_FILE_SIZE) {
      setImageErrorMessage(ImageError.FileSize);
    }
    if (!files[0].file.type.startsWith('image/')) {
      setImageErrorMessage(ImageError.FileType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    onDropRejected,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accept: { 'image/*': ['.avif', '.bmp', '.jpeg', '.jpg', '.png', '.svg', '.webp'] },
    maxSize: IMAGE_FILE_SIZE,
  });

  const deleteImageBase64 = () => {
    setImageBase64('');
  };

  return (
    <>
      <UploadImageWrapper {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        {!imageBase64 ? (
          <UploadLabel htmlFor="upload_image">
            <CloudUploadIcon height={180} width={190} color={colors.grayLight} />
            <UploadInfoSpan>Drag your files here to upload</UploadInfoSpan>
            <UploadSubInfoSpan>or click to browse</UploadSubInfoSpan>
            <input data-testid="drop-input" {...getInputProps()} />
          </UploadLabel>
        ) : (
          <ImageBase64Content data-testid="image_base_64">
            <p>{imageBase64}</p>
            <CopyButton imageBase64={imageBase64} />
            <DeleteButtonWrapper>
              <Button
                backgroundColor={colors.grayLighter}
                textColor={colors.gray}
                icon={<CloudDownloadIcon height={20} width={20} />}
                onClick={deleteImageBase64}
              >
                削除
              </Button>
            </DeleteButtonWrapper>
          </ImageBase64Content>
        )}
      </UploadImageWrapper>
      <ErrorMessages messages={[imageErrorMessage]} />
    </>
  );
};

export default UploadImage;
