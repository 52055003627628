import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Title = styled.h2`
  width: 100%;
  margin: 0;
  border: none;
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: 600;
  outline: none;
`;

export const DateCreationInfo = styled.p`
  margin: 1rem 0;
  padding: 0;
  color: ${colors.gray};
  font-size: 0.9rem;
`;

export const Flag = styled.div`
  display: inline-block;
  background-color: ${colors.redLight};
  color: ${colors.red};
  border-radius: 5px;
  padding: 0.6rem 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  background-color: ${colors.white};
  padding: 0;
  margin: 0;
  min-height: 21px;
`;

export const InfoTitle = styled.span`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: bold;
`;

export const InfoContent = styled.span`
  font-size: 1.1rem;
  color: ${colors.grayDark};
`;

export const DescriptionWrapper = styled.div`
`;

export const DescriptionTitle = styled.p`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: bold;
`;

export const DescriptionContent = styled.p`
  font-size: 1.1rem;
  color: ${colors.grayDark};
`;

export const Separator = styled.div`
  margin: 1.2rem 0;
  border-bottom: solid 1px ${colors.grayLight};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;