import React from 'react';
import { PaginationWrapper, BlocWrapper } from './Pagination.styles';
import PaginationBloc from './PaginationBloc/PaginationBloc';

export type PaginationProps = {
  numberOfItems: number;
  currentPage: number;
  itemPerPage: number;
  changePage(pageNumber: number): void;
};

const Pagination: React.FC<PaginationProps> = ({ numberOfItems, currentPage, itemPerPage, changePage }) => {
  const totalPage = Math.ceil(numberOfItems / itemPerPage);

  const blocs: JSX.Element[] = [];

  const firstBlocPage = (): number => {
    if (totalPage <= 5) {
      return 1;
    }
    if (currentPage === totalPage) {
      return totalPage - 4;
    }
    const target = currentPage - 3 > 0 ? currentPage - 2 : 1;
    return totalPage - target <= 3 ? currentPage - (totalPage - target) : target;
  };

  const lastBlocPage = (): number => {
    if (totalPage <= 5 || totalPage === currentPage) {
      return totalPage;
    }
    const target = currentPage + 2 > 4 ? currentPage + 2 : 5;
    return target > totalPage ? totalPage : target;
  };

  for (let index = firstBlocPage(); index <= lastBlocPage(); index++) {
    const isCurrentPage = currentPage === index;
    const blocPage = (
      <PaginationBloc
        key={index}
        isCurrentPage={isCurrentPage}
        currentPage={currentPage}
        content={index.toString()}
        targetPage={index}
        totalPage={totalPage}
        changePage={changePage}
      />
    );
    blocs.push(blocPage);
  }

  return (
    <PaginationWrapper id="pagination">
      <PaginationBloc
        key="<"
        isCurrentPage={false}
        currentPage={currentPage}
        content="PREV"
        targetPage={currentPage - 1}
        totalPage={totalPage}
        changePage={changePage}
      />
      <li>
        <BlocWrapper>{blocs}</BlocWrapper>
      </li>
      <PaginationBloc
        key=">"
        isCurrentPage={false}
        currentPage={currentPage}
        content="NEXT"
        targetPage={currentPage + 1}
        totalPage={totalPage}
        changePage={changePage}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
