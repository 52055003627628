import React from 'react';
import FileIcon from '../../../svgs/FileIcon';
import ClockIcon from '../../../svgs/ClockIcon';
import PaperPlaneIcon from '../../../svgs/PaperPlaneIcon';
import { TitleWrapper, MessageStatus, FlagWrapper, DateWrapper, DateInfo, Flag } from './MessageCard.styles';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import Card from '../../../components/Card/Card';

enum DateStatusEnum {
  Sent = 'に送信',
  Draft = 'に作成',
  Scheduled = '予定',
}

const infoStatusIconRender = (sentOn: string, sentOnDate: Date): React.SVGProps<SVGSVGElement> => {
  if (!sentOn) {
    return <FileIcon height="22" width="22" />;
  }
  if (sentOnDate > new Date()) {
    return <ClockIcon height="22" width="22" />;
  }
  return <PaperPlaneIcon height="22" width="22" />;
};

const dateInfoFormat = (sentOn: string, sentOnDate: Date): string => {
  let statusText = DateStatusEnum.Sent;
  if (!sentOn) {
    statusText = DateStatusEnum.Draft;
  }
  if (sentOnDate > new Date()) {
    statusText = DateStatusEnum.Scheduled;
  }
  return `${fromDateToJapaneseStringDate(sentOnDate)}${statusText}`;
};

type MessageCardProps = {
  id: string;
  sentOn: string;
  importantFlag: boolean;
  title: string;
  onClickItem(id: string): void;
};

const MessageCard: React.FC<MessageCardProps> = ({ id, title, sentOn, importantFlag, onClickItem }) => {
  const sentOnDate = new Date(sentOn);

  return (
    <Card id={id} title={title} onClickItem={onClickItem}>
      <TitleWrapper>
        <MessageStatus>{infoStatusIconRender(sentOn, sentOnDate)}</MessageStatus>
        {title}
      </TitleWrapper>
      <FlagWrapper>{importantFlag && <Flag>重要</Flag>}</FlagWrapper>
      <DateWrapper>{sentOn && <DateInfo>{dateInfoFormat(sentOn, sentOnDate)}</DateInfo>}</DateWrapper>
    </Card>
  );
};

export default MessageCard;
