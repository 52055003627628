import styled from "styled-components";
import colors from "../../styles/colors";

export const UsersPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
`;

export const UsersContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 36rem;
  margin: 0 1.8rem 1.8rem 1.8rem;
  border-radius: 0.8rem;
  background-color: ${colors.white};
`;

export const ErrorWrapper = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;
`;

export const SearchForm = styled.form`
  display: flex;
  position: relative;
  justify-content: start;
  align-items: center;
  border: 1px solid ${colors.blue};
  border-radius: 6px;
  padding: 0 0.8rem;
  height: 2.7rem;
  width: 530px;
`;

export const ActionSearchButton = styled.button`
  all: unset;

  height: 20px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  all: unset;
  width: 100%;
`;

export const ContentListUsers = styled.ul`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 1.5rem 1.5rem 1.5rem;
  padding: 0;
`;

export const PaginationWrapper = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;
`;

