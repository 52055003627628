import styled from 'styled-components';

const ContentHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem;
`;

export default ContentHeader;
