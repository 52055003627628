import React, { useState } from 'react';
import { PageHeaderLeftSection, PageHeaderTitle, PageHeaderWrapper, QuickActionModal } from './PageHeader.styles';
import ModalMenuItem, { ModalMenuItemProps } from './ModalMenuItem/ModalMenuItem';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import PlusIcon from '../../svgs/PlusIcon';
import Modal from '../Modal/Modal';
import colors from '../../styles/colors';
import GroupIcon from '../../svgs/GroupIcon';
import EnvelopeIcon from '../../svgs/EnvelopeIcon';

type PageHeaderProps = {
  pageTitle: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ pageTitle }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const quickActionOnClick = () => {
    setModalOpen((currentState) => !currentState);
  };

  const modalMenuItems: ModalMenuItemProps[] = [
    {
      id: 1,
      icon: <EnvelopeIcon height="16" width="16" fill={colors.blue} stroke={colors.blue} />,
      title: '新しいメッセージ',
      url: '/messages',
    },
    {
      id: 2,
      icon: <GroupIcon height="16" width="16" fill={colors.blue} stroke={colors.blue} />,
      title: '新しい連絡先リスト',
      url: '/audiences',
    },
  ];

  return (
    <PageHeaderWrapper>
      <PageHeaderLeftSection>
        <PageHeaderTitle>{pageTitle}</PageHeaderTitle>
      </PageHeaderLeftSection>
      <ButtonIcon
        icon={<PlusIcon height="16" width="16" fill={modalOpen ? colors.white : colors.gray} stroke={modalOpen ? colors.white : colors.gray} />}
        backgroundColor={modalOpen ? colors.blueDark : colors.grayLight}
        onClick={quickActionOnClick}
      />
      <Modal isDisplay={modalOpen} close={quickActionOnClick}>
        <QuickActionModal isDisplay={modalOpen}>
          {modalMenuItems.map((item) => {
            return <ModalMenuItem key={item.id} id={item.id} icon={item.icon} title={item.title} url={item.url} />;
          })}
        </QuickActionModal>
      </Modal>
    </PageHeaderWrapper>
  );
};

export default PageHeader;
