import styled from 'styled-components';

const ContentHeaderTitleSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  button {
    margin-right: 0.5rem;
    :last-child {
      margin-right: 0rem;
    }
  }
`;

export default ContentHeaderTitleSide;
