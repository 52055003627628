/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  width = '100%',
  height = '100%',
  ...rest
}) => {
  return (
    <svg fill={fill} viewBox="0 0 32 32" enableBackground="new 0 0 32 32" width={width} height={height} {...rest}>
      <circle fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" cx="19.5" cy="12.5" r="8.5" />
      <line fill={fill} stroke={stroke} strokeWidth="2" strokeMiterlimit="10" x1="4" y1="28" x2="14" y2="18" />
    </svg>
  );
};

export default SearchIcon;
