import React from 'react';
import { Link } from 'react-router-dom';
import { ModalMenuItemWrapper, ContentWrapper, ItemIcon, ItemTitle } from './ModalMenuItem.styles';

export type ModalMenuItemProps = {
  id: number;
  icon: React.ReactNode;
  title: string;
  url: string;
};

const ModalMenuItem: React.FC<ModalMenuItemProps> = ({ title, icon, url }) => {
  return (
    <ModalMenuItemWrapper>
      <Link to={url}>
        <ContentWrapper>
          <ItemIcon>{icon}</ItemIcon>
          <ItemTitle>{title}</ItemTitle>
        </ContentWrapper>
      </Link>
    </ModalMenuItemWrapper>
  );
};

export default ModalMenuItem;
