import React from 'react';
import { AuthenticationProvider, oidcLog, withOidcSecure, InMemoryWebStorage } from '@axa-fr/react-oidc-context';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import oidcConfig from './config/oidcConfig';

import Home from './pages/home/Home';
import Messages from './pages/messages/Messages';
import MessagePreview from './pages/message-preview/MessagePreview';
import Users from './pages/users/Users';
import Audiences from './pages/audiences/Audiences';
import CreateMessage from './pages/create-message/CreateMessage';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import CustomCallback from './components/CustomCallback/CustomCallback';
import NotAuthenticatedPage from './pages/not-authenticated/NotAuthenticatedPage';
import NotAuthorizedPage from './pages/not-authorized/NotAuthorizedPage';
import NotFound from './pages/not-found/NotFound';
import { HeaderProvider } from './context/HeaderContext/context';

import GlobalStyles from './styles/globalStyle';
import { MessageProvider } from './context/MessageContext/context';

const App: React.FC = () => {
  return (
    <HeaderProvider headerStatus="normal">
      <GlobalStyles />
      <AuthenticationProvider
        configuration={oidcConfig}
        loggerLevel={oidcLog.DEBUG}
        callbackComponentOverride={CustomCallback}
        authenticating={CustomCallback}
        notAuthenticated={NotAuthenticatedPage}
        notAuthorized={NotAuthorizedPage}
        UserStore={InMemoryWebStorage}
      >
        <Router>
          <Header />
          <Main>
            <Switch>
              <Route path="/" exact component={withOidcSecure(Home)} />
              <Route path="/messages" exact component={withOidcSecure(Messages)} />
              <Route path="/message-preview/:messageId" exact component={withOidcSecure(MessagePreview)} />
              <Route
                path="/create-message/:messageId"
                render={withOidcSecure(() => (
                  <MessageProvider>
                    <CreateMessage />
                  </MessageProvider>
                ))}
              />
              <Route path="/audiences" exact component={withOidcSecure(Audiences)} />
              <Route path="/users" exact component={withOidcSecure(Users)} />
              <Route path="/not-found" component={NotFound} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Main>
        </Router>
      </AuthenticationProvider>
    </HeaderProvider>
  );
};

export default App;
