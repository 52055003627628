import styled from 'styled-components';
import colors from '../../../styles/colors';

export const DescriptionLabel = styled.label`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: bold;
`;

export const DescriptionTextarea = styled.textarea`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  border: none;
  resize: none;
  outline: none;
`;
