import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { Footer, InputContainer, SchedulerContent, SchedulerForm, SchedulerStepWrapper, TitleForm } from './SchedulerStep.styles';
import Button from '../../../components/Button/Button';
import InputCustom from '../../../components/InputCustom/InputCustom';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import colors from '../../../styles/colors';
import { CreateMessageParams } from '../../../types/CreateMessage.type';
import IllustrationScheduler from '../../../svgs/IllustrationScheduler';
import { useMessageContext } from '../../../context/MessageContext/context';
import useApi from '../../../hooks/useApi';
import { MessageResponseType, PatchMessageType } from '../../../types/Message.type';
import { getTimestampOfTheCurrentDay, transformTimestampToJapaneseStringDate } from '../../../utils/date';

enum DateError {
  TimeErrorRegex = 'Time is not valid',
  DateIsMissing = 'Date is missing',
}

const SchedulerStep: React.FC = () => {
  const { updateScheduler, LastValidation, resetForm, state } = useMessageContext();
  const history = useHistory();
  const [dateScheduler, setDateScheduler] = useState<Date>();
  const [timeScheduler, setTimeScheduler] = useState('');
  const [timeErrorMessage, setTimeErrorMessage] = useState('');
  const { messageId } = useParams<CreateMessageParams>();
  const [apiState, makeRequest] = useApi<MessageResponseType, PatchMessageType>();

  const updatedDate = (timestamp: number) => {
    setTimeScheduler('');
    setDateScheduler(new Date(timestamp));
  };

  const onChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeScheduler(event.target.value);
  };

  const saveScheduler = () => {
    // Examples string accepted => 12:30, 1:25, 1:02
    const regExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/gs;
    if (!dateScheduler) {
      setTimeErrorMessage(DateError.DateIsMissing);
    } else if (!regExp.test(timeScheduler)) {
      setTimeErrorMessage(DateError.TimeErrorRegex);
    } else {
      const time = timeScheduler.split(':');
      dateScheduler.setHours(Number(time[0]), Number(time[1]));
      setTimeErrorMessage('');
      updateScheduler(dateScheduler.toISOString());
      LastValidation();
    }
  };

  useEffect(() => {
    const timestamp = Date.parse(state.message.sentOn ?? '');
    if (!Number.isNaN(timestamp)) {
      const date = new Date(timestamp);
      setTimeScheduler(`${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`);
      setDateScheduler(date);
    } else {
      setTimeScheduler('');
    }
  }, [state.message.sentOn]);

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 200) {
      history.push(`/create-message/${messageId}/confirmation`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      makeRequest({
        url: `/msg-box-api/messages/${messageId}`,
        method: 'PATCH',
        data: {
          audienceId: state.message.audienceId,
          sentOn: state.message.sentOn,
          lastModifiedBy: state.message.lastModifiedBy,
          title: state.message.title,
          content: state.message.content,
          importantFlag: state.message.importantFlag,
          description: state.message.description,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  return (
    <SchedulerStepWrapper>
      <IllustrationScheduler height="500" width="500" />
      <SchedulerContent>
        <SchedulerForm>
          <TitleForm>スケジュールを設定する</TitleForm>
          <DatePicker
            updateDate={updatedDate}
            value={dateScheduler ? transformTimestampToJapaneseStringDate(dateScheduler.getTime()) : ''}
            minTimestamp={getTimestampOfTheCurrentDay()}
            placeholder="〇〇〇〇年〇〇月〇〇日"
          />
          <InputContainer>
            <InputCustom
              label="時刻を設定する"
              value={timeScheduler}
              pattern="([0-1]?[0-9]|2[0-3]):[0-5][0-9]"
              onChange={onChangeTime}
              placeholder="〇〇：〇〇"
            />
          </InputContainer>
          <ErrorMessages messages={[...(apiState.errorResponseData ?? []), state.isSubmitted ? state.validationMessage : '', timeErrorMessage]} />
        </SchedulerForm>
      </SchedulerContent>
      <Footer>
        <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={() => history.push(`/create-message/${messageId}/audience`)}>
          戻る
        </Button>
        <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={saveScheduler} isLoading={apiState.isLoading}>
          スケジュールする
        </Button>
      </Footer>
    </SchedulerStepWrapper>
  );
};

export default SchedulerStep;
