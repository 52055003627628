import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  CodeHtmlContentWrapper,
  CodeSideContent,
  ContentStepWrapper,
  CreateMessageWrapper,
  Header,
  HtmlCodeHeader,
  HtmlCodeHeaderTitle,
  HtmlCodeStepWrapper,
  LoaderWrapper,
  PreviewContentWrapper,
  SideBox,
} from './MessagePreview.styles';
import AxaIcon from '../../svgs/AxaIcon';
import useToggleHeader from '../../hooks/useToggleHeader';
import { MessageResponseType } from '../../types/Message.type';
import { CreateMessageParams } from '../../types/CreateMessage.type';
import useApi from '../../hooks/useApi';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import colors from '../../styles/colors';

const MessagePreview: React.FC = () => {
  const history = useHistory();
  const { hide } = useToggleHeader();
  const { messageId } = useParams<CreateMessageParams>();
  const [{ responseData, isLoading }] = useApi<MessageResponseType>({
    url: `/msg-box-api/messages/${messageId}`,
  });

  const renderMessageHtml = () => {
    try {
      return responseData && responseData.content ? parse(responseData?.content) : '';
    } catch (error) {
      return 'Error with html code';
    }
  };

  useEffect(() => {
    hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <CreateMessageWrapper>
      <Header>
        <AxaIcon height={40} width={40} />
        <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={() => history.push('/users')}>
          ユーザーに戻る
        </Button>
      </Header>
      <ContentStepWrapper>
        <HtmlCodeStepWrapper>
          <SideBox>
            <HtmlCodeHeader>
              <HtmlCodeHeaderTitle>コードを編集する</HtmlCodeHeaderTitle>
            </HtmlCodeHeader>
            <CodeHtmlContentWrapper>
              <CodeSideContent>{responseData?.content}</CodeSideContent>
            </CodeHtmlContentWrapper>
          </SideBox>
          <SideBox>
            <HtmlCodeHeader>
              <HtmlCodeHeaderTitle>プレビュー</HtmlCodeHeaderTitle>
            </HtmlCodeHeader>
            <PreviewContentWrapper data-testid="preview_render">{renderMessageHtml()}</PreviewContentWrapper>
          </SideBox>
        </HtmlCodeStepWrapper>
      </ContentStepWrapper>
    </CreateMessageWrapper>
  ) : (
    <LoaderWrapper>
      <Loader height={2} width={1} />
    </LoaderWrapper>
  );
};

export default MessagePreview;
