import styled from 'styled-components';
import colors from '../../../styles/colors';
import { background, base } from '../../../styles/zindex';

export const ConfirmationStepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: ${base};

  svg {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0, -55%);
    z-index: ${background};
  }
`;

export const ConfirmationContent = styled.div`
  height: 100%;
`;

export const ConfirmationContentText = styled.p`
  margin-top: 8rem;
  padding-left: 12rem;
  font-size: 2rem;
  font-weight: 600;
`;

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  background-color: ${colors.background};
`;
