import styled from 'styled-components';
import colors from '../../styles/colors';

export const CardWrapper = styled.li`
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
`;

export const CardButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayLight};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1rem 1.2rem;
  cursor: pointer;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayLight};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1rem 1.2rem;
`;