import React, { useEffect, useState } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import PageHeader from '../../components/PageHeader/PageHeader';
import useToggleHeader from '../../hooks/useToggleHeader';
import { AudiencesContentWrapper, AudiencesPageWrapper, ContentListAudience, PaginationWrapper, ErrorWrapper } from './Audiences.styles';
import ItemAudience from './ItemAudience/ItemAudience';
import ListAltIcon from '../../svgs/ListAltIcon';
import TrashIcon from '../../svgs/TrashIcon';
import SyncIcon from '../../svgs/SyncIcon';
import colors from '../../styles/colors';
import TabHeaderItem from '../../components/TabHeaderItem/TabHeaderItem';
import Pagination from '../../components/Pagination/Pagination';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import TabHeader from '../../components/TabHeader/TabHeader';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ContentHeaderTitleSide from '../../components/ContentHeader/ContentHeaderTitleSide/ContentHeaderTitleSide';
import ContentHeaderActionSide from '../../components/ContentHeader/ContentHeaderActionSide/ContentHeaderActionSide';
import DialogueBox from '../../components/DialogueBox/DialogueBox';
import ErrorMessages from '../../components/ErrorMessages/ErrorMessages';
import Loader from '../../components/Loader/Loader';
import CreateAudience from './CreateAudience/CreateAudience';
import { AudienceProvider } from '../../context/AudienceContext/context';

import useApi, { errorMessageFormatting } from '../../hooks/useApi';
import axiosInstance from '../../utils/axios';
import { AudienceResponseType } from '../../types/Audience.type';

const Audiences: React.FC = () => {
  const PAGE_NUMBER = 1;
  const PAGE_SIZE = process.env.REACT_APP_AUDIENCE_DEFAULT_PAGE_SIZE;
  const [shouldDisplayDialogueDeleteModal, setShouldDisplayDialogueDeleteModal] = useState(false);
  const [shouldDisplayCreateAudienceModal, setShouldDisplayCreateAudienceModal] = useState(false);
  const [errorDeleteRequest, setErrorDeleteRequest] = useState<string[]>([]);
  const [errorDeleteLoadingRequest, setErrorDeleteLoadingRequest] = useState<boolean>(false);
  const { oidcUser } = useReactOidc();
  const [listAudienceSelected, setListAudienceSelected] = useState<string[]>([]);
  const { show } = useToggleHeader();
  const [{ responseData, responseHeaders, errorResponseData, isLoading }, makeRequest] = useApi<AudienceResponseType[]>({
    url: `/msg-box-api/audiences?pageSize=${PAGE_SIZE}&pageNumber=${PAGE_NUMBER}&withAdditionalData=true`,
  });

  useEffect(() => {
    show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (pageNumber: number) => {
    setListAudienceSelected([]);
    makeRequest({
      url: `/msg-box-api/audiences?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}&withAdditionalData=true`,
    });
  };

  const selectAudience = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    if (!listAudienceSelected.includes(id)) {
      setListAudienceSelected((arr) => [...arr, id]);
    } else {
      setListAudienceSelected((arr) => arr.filter((item) => item !== id));
    }
  };

  const selectOrUnselectAllAudience = () => {
    if (responseData) {
      if (listAudienceSelected.length === responseData.filter((audience) => !audience.additionalData?.isAttachedToMessage).length) {
        setListAudienceSelected([]);
      } else {
        responseData
          .filter((audience) => !audience.additionalData?.isAttachedToMessage)
          .forEach((audience) => {
            if (!listAudienceSelected.includes(audience.id)) {
              setListAudienceSelected((arr) => [...arr, audience.id]);
            }
          });
      }
    }
  };

  const deleteAudience = async () => {
    try {
      setErrorDeleteLoadingRequest(true);
      setErrorDeleteRequest([]);
      const deletePromises = listAudienceSelected.map(async (audienceSelected) => {
        await axiosInstance.request({
          url: `/msg-box-api/audiences/${audienceSelected}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${oidcUser.access_token}`,
          },
        });
      });
      await Promise.all(deletePromises);
    } catch (error) {
      const listError = errorMessageFormatting(error);
      setErrorDeleteRequest(listError);
    } finally {
      setErrorDeleteLoadingRequest(false);
      setListAudienceSelected([]);
    }
    makeRequest({
      url: `/msg-box-api/audiences?pageSize=${PAGE_SIZE}&pageNumber=${PAGE_NUMBER}&withAdditionalData=true`,
    });
    setShouldDisplayDialogueDeleteModal((currentState) => !currentState);
  };

  const displayDialogueDelete = () => {
    if (listAudienceSelected.length > 0) {
      setShouldDisplayDialogueDeleteModal((currentState) => !currentState);
    }
  };

  const displayCreateAudience = (withRefresh = false) => {
    setShouldDisplayCreateAudienceModal((currentState) => !currentState);
    if (withRefresh) {
      makeRequest({
        url: `/msg-box-api/audiences?pageSize=${PAGE_SIZE}&pageNumber=${PAGE_NUMBER}&withAdditionalData=true`,
      });
    }
  };

  return (
    <>
      <AudiencesPageWrapper>
        <PageHeader pageTitle="送信先" />
        <AudiencesContentWrapper>
          {[...(errorResponseData ?? []), ...errorDeleteRequest].length > 0 && (
            <ErrorWrapper>
              <ErrorMessages messages={[...(errorResponseData ?? []), ...errorDeleteRequest]} />
            </ErrorWrapper>
          )}
          <ContentHeader>
            <ContentHeaderTitleSide>
              <Button
                icon={<ListAltIcon height="22" width="22" />}
                backgroundColor={colors.blueLight}
                textColor={colors.blue}
                onClick={selectOrUnselectAllAudience}
              >
                すべて選択
              </Button>
              <Button
                icon={<TrashIcon height="20" width="20" />}
                backgroundColor={colors.blueLight}
                textColor={colors.blue}
                onClick={displayDialogueDelete}
              >
                削除
              </Button>
              {responseData && responseHeaders && responseData.length > 0 && (
                <Button
                  icon={<SyncIcon height="20" width="20" />}
                  backgroundColor={colors.blueLight}
                  textColor={colors.blue}
                  onClick={() => changePage(Number(responseHeaders['pagination-current-page']))}
                >
                  更新
                </Button>
              )}
            </ContentHeaderTitleSide>
            <ContentHeaderActionSide>
              <Button backgroundColor={colors.blue} textColor={colors.white} onClick={() => displayCreateAudience()}>
                新しい送信先リスト
              </Button>
            </ContentHeaderActionSide>
          </ContentHeader>
          <TabHeader>
            <TabHeaderItem title="リスト" flexGrow={50} hasSortItem={false} />
            <TabHeaderItem title="送信先" flexGrow={20} hasSortItem={false} />
            <TabHeaderItem title="日付" flexGrow={30} hasSortItem={false} />
          </TabHeader>
          {isLoading && <Loader height={2} width={1} />}
          {responseData && !isLoading && (
            <ContentListAudience data-testid="audience_list">
              {responseData.map((audience) => (
                <ItemAudience
                  key={audience.id}
                  id={audience.id}
                  title={audience.title}
                  status={audience.additionalData?.status ?? 'error'}
                  count={audience.additionalData?.totalTargetedUsers ?? 0}
                  date={audience.createdAt}
                  isAttached={audience.additionalData?.isAttachedToMessage ?? false}
                  isChecked={listAudienceSelected.includes(audience.id)}
                  onClickCheckBox={selectAudience}
                />
              ))}
            </ContentListAudience>
          )}
          {responseData && responseHeaders && responseData.length > 0 && (
            <PaginationWrapper>
              <Pagination
                itemPerPage={Number(responseHeaders['pagination-page-size'])}
                currentPage={Number(responseHeaders['pagination-current-page'])}
                numberOfItems={Number(responseHeaders['pagination-total-count'])}
                changePage={changePage}
              />
            </PaginationWrapper>
          )}
        </AudiencesContentWrapper>
      </AudiencesPageWrapper>
      <Modal isDisplay={shouldDisplayDialogueDeleteModal} close={displayDialogueDelete}>
        <DialogueBox
          title="削除"
          confirmationButtonText="削除する"
          isLoading={errorDeleteLoadingRequest}
          confirmation={deleteAudience}
          closeModal={displayDialogueDelete}
        >
          選択されたリストを本当に削除してよろしいですか？
        </DialogueBox>
      </Modal>
      <Modal isDisplay={shouldDisplayCreateAudienceModal} close={() => displayCreateAudience(false)}>
        <AudienceProvider>
          <CreateAudience closeModal={(withRefresh) => displayCreateAudience(withRefresh)} />
        </AudienceProvider>
      </Modal>
    </>
  );
};

export default Audiences;
