import { AudienceCreationType } from '../../types/Audience.type';

// TODO: replace with japanese text
export enum ValidationAudienceEnum {
  Title = 'Title is missing ',
  File = 'File is missing ',
  Method = 'Method is missing',
}

export enum FileError {
  EmptyFile = 'Empty file',
  PartyId = 'Party IDs should be 15 characters long',
  PolicyNumbers = 'Policy numbers should be between 9 and 11 characters long',
  ComaSeparation = 'There should be only 2 fields separated by 1 coma values',
}

export type AudienceFileErrorType = {
  line: number;
  error: FileError;
};

const audienceContextInitialState: AudienceContextState = {
  isSubmitted: false,
  isValid: false,
  validationMessage: '',
  audience: {
    title: '',
    description: '',
    method: 'partyIdAndPolicyNb',
    audienceFile: null,
  },
  verificationFile: [],
};

export type AudienceContextState = {
  audience: AudienceCreationType;
  validationMessage: ValidationAudienceEnum | '';
  verificationFile: string[];
  isSubmitted: boolean;
  isValid: boolean;
};

export default audienceContextInitialState;
