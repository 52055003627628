import styled from 'styled-components';

const ButtonCustom = styled.button<{ backgroundColor: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  padding-top: 3px;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 6px;
  border: none;
`;

export default ButtonCustom;
