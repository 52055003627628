/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const ClockIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg viewBox="0 0 32 32" width={width} height={height} {...rest}>
      <path
        fill={fill}
        d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 15 8 L 15 17 L 22 17 L 22 15 L 17 15 L 17 8 Z"
      />
    </svg>
  );
};

export default ClockIcon;
