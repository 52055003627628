import React from 'react';
import { Info, SubInfo, InfoWithSubInfoWrapper } from './CeilInfoSubInfo.styles';

type CeilInfoSubInfoProps = {
  info: string;
  subInfo: string;
  flexGrow: number;
};

const CeilInfoSubInfo: React.FC<CeilInfoSubInfoProps> = ({ info, subInfo, flexGrow }) => {
  return (
    <InfoWithSubInfoWrapper flexGrow={flexGrow}>
      <Info>{info}</Info>
      <SubInfo>{subInfo}</SubInfo>
    </InfoWithSubInfoWrapper>
  );
};

export default CeilInfoSubInfo;
