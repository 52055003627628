import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem/MenuItem';
import HomeIcon from '../../svgs/HomeIcon';
import AxaIcon from '../../svgs/AxaIcon';
import ChevronIcon from '../../svgs/ChevronIcon';
import EnvelopeIcon from '../../svgs/EnvelopeIcon';
import UserIcon from '../../svgs/UserIcon';
import { HeaderNav, HeaderList, HeaderLogo, HeaderWrapper, HeaderMenuCollapse } from './Header.styles';
import useToggleHeader from '../../hooks/useToggleHeader';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import colors from '../../styles/colors';
import UserListIcon from '../../svgs/UserList';

type MenuItemsType = {
  id: string;
  name: string;
  url: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const menuItems: MenuItemsType[] = [
  {
    id: 'home',
    url: '/',
    icon: () => <HomeIcon width="24" />,
    name: 'ダッシュボード',
  },
  {
    id: 'messages',
    url: '/messages',
    icon: () => <EnvelopeIcon width="24" />,
    name: 'メッセージ',
  },
  {
    id: 'audiences',
    url: '/audiences',
    icon: () => <UserListIcon width="24" />,
    name: '送信先',
  },
  {
    id: 'users',
    url: '/users',
    icon: () => <UserIcon width="24" />,
    name: 'ユーザー',
  },
];

const Header: React.FC = () => {
  const locationHistory = useLocation();
  const { headerStatus, toggle } = useToggleHeader();

  return (
    <HeaderWrapper headerStatus={headerStatus}>
      <HeaderNav>
        <HeaderList>
          <HeaderLogo>
            <AxaIcon width="3rem" height="3rem" />
          </HeaderLogo>
          {menuItems.map((menuItem) => {
            return (
              <MenuItem
                key={menuItem.id}
                id={menuItem.id}
                url={menuItem.url}
                menuName={menuItem.name}
                icon={<menuItem.icon />}
                isSelected={menuItem.url === locationHistory.pathname}
                headerStatus={headerStatus}
              />
            );
          })}
          <HeaderMenuCollapse headerStatus={headerStatus}>
            <ButtonIcon icon={<ChevronIcon width="14" height="14" />} backgroundColor={colors.grayLight} onClick={toggle} />
          </HeaderMenuCollapse>
        </HeaderList>
      </HeaderNav>
    </HeaderWrapper>
  );
};

export default Header;
