import styled from 'styled-components';
import colors from '../../styles/colors';

export const InputCustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputCustomLabel = styled.label`
  font-size: 0.9rem;
  color: ${colors.gray};
  margin-bottom: 0rem;
`;

export const InputCustomInput = styled.input`
  width: 15rem;
  border: none;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 15px 5px;
  font-size: 1rem;
  color: ${colors.grayDark};
  font-weight: 600;

  :focus {
    outline: none;
    border-bottom: 1px solid ${colors.axaBlue};
    box-shadow: none;
  }

  :disabled {
    background-color: ${colors.white};
    color: ${colors.grayLight};
    font-weight: 400;
  }

  :invalid {
    border-bottom: 1px solid ${colors.axaRed};
  }

  :valid {
    border-bottom: 1px solid ${colors.aquaGreen};
  }
`;
