import React, { useEffect, useState } from 'react';
import LoaderWrapper from './AudienceSelection.styles';
import Loader from '../../../../components/Loader/Loader';
import SelectBox, { OptionType } from '../../../../components/SelectBox/SelectBox';
import { useMessageContext } from '../../../../context/MessageContext/context';
import useApi from '../../../../hooks/useApi';
import { AudienceResponseType } from '../../../../types/Audience.type';

const PAGE_NUMBER = 1;
const PAGE_SIZE = 50;

export enum TargetUserRadioEnum {
  'Audience' = 'audience',
}

type AudienceSelectionProps = {
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
  targetUserInput: TargetUserRadioEnum;
};

const AudienceSelection: React.FC<AudienceSelectionProps> = ({ onChange, targetUserInput }) => {
  const [{ responseData, isLoading }] = useApi<AudienceResponseType[]>({
    url: `/msg-box-api/audiences?pageSize=${PAGE_SIZE}&pageNumber=${PAGE_NUMBER}&withCount=false&withAdditionalData=true`,
  });
  const [audienceOption, setAudienceOption] = useState([] as OptionType[]);
  const { state } = useMessageContext();

  useEffect(() => {
    if (responseData) {
      const listOption = responseData
        .filter((audience) => audience.additionalData?.status === 'ready')
        .map((audience): OptionType => {
          return {
            id: audience.id,
            value: audience.id,
            text: audience.title,
          };
        });
      setAudienceOption(listOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  return (
    <>
      {!isLoading ? (
        <SelectBox
          label="送信先リスト"
          disabled={targetUserInput !== TargetUserRadioEnum.Audience}
          options={audienceOption}
          value={state.message.audienceId ?? ''}
          onChange={onChange}
        />
      ) : (
        <LoaderWrapper>
          <Loader height={0.7} width={0.5} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default AudienceSelection;
