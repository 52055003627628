import { Dispatch, SetStateAction, useEffect, useReducer, useRef, useState } from 'react';
import { AxiosResponse, AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import apiReducer from '../context/ApiContext/reducer';
import { ApiActionsType } from '../context/ApiContext/actions';
import { ApiContextState } from '../context/ApiContext/state';
import axiosInstance from '../utils/axios';

type ErrorResponseType = {
  errors: string[];
};

type HeaderResponseType = AxiosResponseHeaders & {
  'pagination-total-count': string;
  'pagination-current-page': string;
  'pagination-page-size': string;
};

export const errorMessageFormatting = (error: any): string[] => {
  if (error && error.response) {
    if (error.response.status === 400) {
      return (error.response.data as ErrorResponseType).errors.map((data) => `エラーが発生しました: [${error.response.status}] ${data}`);
    }
    if ((error.response.status === 404 || error.response.status === 500) && error.response.data) {
      return [`エラーが発生しました: [${error.response.status}] ${error.response.data.error}`];
    }
  }
  return [`エラーが発生しました`];
};

function useApi<Response = Record<string, unknown>, Request = Record<string, unknown>>(
  axiosRequestConfig: AxiosRequestConfig = { url: '' },
): [ApiContextState<Response>, Dispatch<SetStateAction<AxiosRequestConfig<Request>>>] {
  const didCancel = useRef(false);
  const [axiosOptions, setAxiosOptions] = useState<AxiosRequestConfig<Request>>(axiosRequestConfig);
  const dataFetchReducer = apiReducer<Response>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    hasError: false,
  });
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    return () => {
      didCancel.current = true;
    };
  }, []);

  useEffect(() => {
    const makeRequest = async () => {
      if (!axiosOptions.url) {
        return undefined;
      }
      dispatch({ type: ApiActionsType.REQUEST_INIT });
      try {
        const response: AxiosResponse<Response> = await axiosInstance.request({
          ...axiosOptions,
          headers: {
            ...axiosOptions.headers,
            Authorization: `Bearer ${oidcUser.access_token}`,
          },
        });
        if (didCancel.current) {
          return undefined;
        }
        dispatch({
          type: ApiActionsType.REQUEST_SUCCESS,
          payload: {
            responseData: response.data,
            responseMethod: response.config.method,
            responseStatus: response.status,
            responseHeaders: response.headers as HeaderResponseType,
          },
        });
      } catch (error: any) {
        if (didCancel.current) {
          return undefined;
        }
        dispatch({
          type: ApiActionsType.REQUEST_FAILURE,
          payload: {
            errorMessage: error,
            responseMethod: error.config?.method,
            errorResponseData: errorMessageFormatting(error),
            responseStatus: error.response?.status,
          },
        });
      }
      return null;
    };

    makeRequest();
  }, [axiosOptions]);

  return [state, setAxiosOptions];
}

export default useApi;
