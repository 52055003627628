/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import BaseContentModal from '../../../components/BaseContentModal/BaseContentModal';
import {
  CreateAudienceForm,
  Separator,
  UploadCSVWrapper,
  Footer,
  UploadLabel,
  UploadSubInfoSpan,
  UploadInfoSpan,
  SuccessUploadWrapper,
  FileNameSpan,
  DeleteButton,
} from './CreateAudience.styles';
import TitleDate from '../../../components/FormHelper/TitleDateHelper/TitleDateHelper';
import Description from '../../../components/FormHelper/DescriptionHelper/DescriptionHelper';
import Button from '../../../components/Button/Button';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import { useAudienceContext } from '../../../context/AudienceContext/context';
import colors from '../../../styles/colors';
import CloudUploadIcon from '../../../svgs/CloudUploadIcon';
import CsvFileIcon from '../../../svgs/CsvFileIcon';
import CrossIcon from '../../../svgs/CrossIcon';
import useApi, { errorMessageFormatting } from '../../../hooks/useApi';
import { AudienceCreationResponseType, BigAudienceCreationResponseType, CreateLargeAudienceType } from '../../../types/Audience.type';
import axiosInstance from '../../../utils/axios';

enum FileError {
  InternalError = 'Internal error',
  FileSize = 'Maximum file upload size is 500ko',
  FileType = 'File is not a CSV or txt',
}

type CreateAudienceProps = {
  closeModal(withRefresh: boolean): void;
};

const CreateAudience: React.FC<CreateAudienceProps> = ({ closeModal }) => {
  const CSV_FILE_SIZE = parseInt(process.env.REACT_APP_CSV_MAX_SIZE as string, 10);
  const [apiState, makeRequest] = useApi<AudienceCreationResponseType | BigAudienceCreationResponseType, FormData | CreateLargeAudienceType>();
  const { updateTitle, updateDescription, updateFile, validationFormCreation, resetForm, state } = useAudienceContext();
  const [loadingAudienceUploadS3, setLoadingAudienceUploadS3] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [errorAudienceUploadS3Request, setErrorAudienceUploadS3Request] = useState<string[]>([]);

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      if ((state.audience.audienceFile as File)?.size > 3000000) {
        makeRequest({
          url: '/msg-box-api/large-audiences',
          method: 'POST',
          data: {
            title: state.audience.title,
            description: state.audience.description,
            method: state.audience.method,
          } as CreateLargeAudienceType,
        });
      } else {
        const bodyFormatData = new FormData();
        bodyFormatData.append('audienceFile', state.audience.audienceFile as File);
        bodyFormatData.append('title', state.audience.title);
        bodyFormatData.append('method', state.audience.method);
        bodyFormatData.append('description', state.audience.description);
        makeRequest({
          url: '/msg-box-api/audiences',
          method: 'POST',
          data: bodyFormatData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  const uploadAudienceFileToS3 = async () => {
    try {
      setLoadingAudienceUploadS3((prevValue) => !prevValue);
      await axiosInstance.request({
        url: (apiState.responseData as BigAudienceCreationResponseType).s3PreSignedUrl,
        method: 'PUT',
        data: state.audience.audienceFile,
        headers: {
          'Content-Type': 'txt/csv',
        },
      });
      closeModal(true);
    } catch (error) {
      const listError = errorMessageFormatting(error);
      setErrorAudienceUploadS3Request(listError);
    } finally {
      setLoadingAudienceUploadS3((prevValue) => !prevValue);
    }
  };

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 201 && !apiState.hasError && apiState.responseData) {
      if ((apiState.responseData as BigAudienceCreationResponseType).s3PreSignedUrl) {
        uploadAudienceFileToS3();
      } else {
        closeModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const onDropAccepted = useCallback((files) => {
    setFileErrorMessage('');
    updateFile(files[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDropRejected = useCallback((files: FileRejection[]) => {
    if (files[0].file.size > CSV_FILE_SIZE) {
      setFileErrorMessage(FileError.FileSize);
    }
    if (files[0].file.type !== 'text/csv' && files[0].file.type !== 'application/vnd.ms-excel' && files[0].file.type !== 'text/plain') {
      setFileErrorMessage(FileError.FileType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    onDropRejected,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accept: { 'text/csv': ['.csv'], 'application/vnd.ms-excel': ['.xls'], 'text/plain': ['.txt'] },
    maxSize: CSV_FILE_SIZE,
  });

  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTitle(event.target.value);
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateDescription(event.target.value);
  };

  const validationForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validationFormCreation();
  };

  return (
    <BaseContentModal title="送信先リストを作成する" closeModal={() => closeModal(false)}>
      <CreateAudienceForm onSubmit={validationForm}>
        <TitleDate
          titleValue={state.audience.title}
          titlePlaceholder="新しいリスト名を入力してください"
          date={new Date()}
          isReadOnly={false}
          titleChange={titleChange}
        />
        <Separator />
        <UploadCSVWrapper {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
          {!state.audience.audienceFile ? (
            <UploadLabel htmlFor="upload_file">
              <CloudUploadIcon height={180} width={190} color={colors.grayLight} />
              <UploadInfoSpan>ここにファイルをドラッグしてアップロード (.csv)</UploadInfoSpan>
              <UploadSubInfoSpan>またはクリックして参照</UploadSubInfoSpan>
              <input type="file" data-testid="drop-input" {...getInputProps()} />
            </UploadLabel>
          ) : (
            <SuccessUploadWrapper>
              <CsvFileIcon height={22} width={22} fill={colors.green} />
              <FileNameSpan>{state.audience.audienceFile.name}</FileNameSpan>
              <DeleteButton type="button" onClick={() => updateFile(null)}>
                <CrossIcon height={22} width={22} fill={colors.red} />
              </DeleteButton>
            </SuccessUploadWrapper>
          )}
        </UploadCSVWrapper>
        <Separator />
        <Description description={state.audience.description} updateDescription={descriptionChange} isReadOnly={false} />
        <ErrorMessages
          messages={[
            ...errorAudienceUploadS3Request,
            fileErrorMessage,
            state.isSubmitted ? state.validationMessage : '',
            ...state.verificationFile,
            ...(apiState.errorResponseData ?? []),
          ]}
        />
        <Footer>
          <Button backgroundColor={colors.grayLight} textColor={colors.gray} hoverBackgroundColor={colors.white} onClick={() => closeModal(false)}>
            キャンセル
          </Button>
          <Button type="submit" backgroundColor={colors.axaBlue} textColor={colors.white} isLoading={apiState.isLoading || loadingAudienceUploadS3}>
            作成する
          </Button>
        </Footer>
      </CreateAudienceForm>
    </BaseContentModal>
  );
};

export default CreateAudience;
