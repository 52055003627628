/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ButtonCustom from './ButtonIcon.styles';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: React.ReactNode;
  backgroundColor: string;
};

const ButtonIcon: React.FC<ButtonProps> = ({ icon, backgroundColor, ...rest }) => {
  return (
    <>
      <ButtonCustom type="button" backgroundColor={backgroundColor} {...rest}>
        {icon}
      </ButtonCustom>
    </>
  );
};

export default ButtonIcon;
