import styled from 'styled-components';
import colors from '../../styles/colors';

export const ErrorMessagesWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ErrorMessagesItem = styled.li`
  background-color: ${colors.redLight};
  border-left: 2px solid ${colors.red};
  padding: 0.5rem 0 0.5rem 1rem;
  margin: 0;
  margin-top: 0.8rem;
  :first-child {
    margin-top: 0;
  }
`;

export const ErrorMessagesText = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.red};
  margin: 0;
`;
