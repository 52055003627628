import styled from 'styled-components';
import colors from '../../styles/colors';
import { modal } from '../../styles/zindex';

const ModalWrapper = styled.div<{ isDisplay: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${colors.grayDark};
  opacity: 40%;
  display: ${(props) => (props.isDisplay ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  z-index: ${modal};
`;

export default ModalWrapper;
