import styled from 'styled-components';
import colors from '../../../styles/colors';

export const ModalMenuItemWrapper = styled.li`
  border-bottom: 1px solid ${colors.grayLight};
  :last-child {
    border: none;
  }
`;

export const ContentWrapper = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  padding: 1rem 1.5rem;
  margin: 0;
  border: none;
  border-radius: 8px;
  background-color: ${colors.white};
`;

export const ItemIcon = styled.li`
  position: relative;
  background-color: ${colors.blueLight};
  margin: 0;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 6px;

  svg {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ItemTitle = styled.span`
  padding-left: 1rem;
  line-height: 2.3rem;
  color: ${colors.grayDark};
  font-size: 0.8rem;
  font-weight: 600;
`;
