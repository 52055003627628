import React from 'react';
import Layout from '@alj-react/ui-components/Layout';
import Text from '@alj-react/ui-components/Text';

const CustomCallback: React.FC = () => (
  <Layout>
    <Text size="36">Redirecting ...</Text>
  </Layout>
);

export default CustomCallback;
