import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const AudienceInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  background-color: ${colors.white};
  padding: 0;
  margin: 0;
  min-height: 21px;
`;

export const AudienceInfoTitle = styled.span`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: bold;
`;

export const AudienceInfoContent = styled.span`
  font-size: 1.1rem;
  color: ${colors.grayDark};
`;
