import styled from 'styled-components';
import colors from '../../styles/colors';
import { baseModalContent } from '../../styles/zindex';

export const BaseContentModalWrapper = styled.div`
  position: fixed;
  padding: 1.1rem 1.4rem;
  background-color: ${colors.white};
  height: auto;
  max-height: 35rem;
  width: 30rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  z-index: ${baseModalContent};
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colors.gray};
  font-size: 1rem;
  line-height: 1rem;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: relative;
  height: 2.1rem;
  width: 2.1rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.grayLight};
  color: ${colors.gray};
  border-radius: 5px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Content = styled.div`
  margin: 1.8rem 0 0 0;
`;
