import React from 'react';
import BaseContentModal from '../BaseContentModal/BaseContentModal';
import Button from '../Button/Button';
import { DialogueBoxContent, DialogueBoxFooter } from './DialogueBox.styles';
import colors from '../../styles/colors';

type DialogueBoxProps = {
  title: string;
  confirmationButtonText: string;
  cancelButtonText?: string;
  isLoading?: boolean;
  closeModal(): void;
  confirmation(): void;
  cancel?(): void;
};

const DialogueBox: React.FC<DialogueBoxProps> = ({
  title,
  isLoading,
  confirmationButtonText,
  cancelButtonText = 'キャンセル',
  closeModal,
  confirmation,
  cancel,
  children,
}) => {
  return (
    <BaseContentModal title={title} closeModal={closeModal}>
      <DialogueBoxContent>{children}</DialogueBoxContent>
      <DialogueBoxFooter>
        <Button backgroundColor={colors.grayLight} textColor={colors.gray} hoverBackgroundColor={colors.white} onClick={cancel ?? closeModal}>
          {cancelButtonText}
        </Button>
        <Button
          type="button"
          data-testid="dialogue-confirmation-button"
          backgroundColor={colors.axaBlue}
          textColor={colors.white}
          isLoading={isLoading}
          onClick={confirmation}
        >
          {confirmationButtonText}
        </Button>
      </DialogueBoxFooter>
    </BaseContentModal>
  );
};

export default DialogueBox;
