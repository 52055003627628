import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { CreateMessageWrapper, Header, HeaderTitle, ContentStepWrapper, HeaderTitleSpan, LoaderWrapper } from './CreateMessage.styles';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import AxaIcon from '../../svgs/AxaIcon';
import HtmlCodeStep from './HtmlCodeStep/HtmlCodeStep';
import AudienceStep from './AudienceStep/AudienceStep';
import SchedulerStep from './SchedulerStep/SchedulerStep';
import ConfirmationStep from './ConfirmationStep/ConfirmationStep';
import DialogueBoxHtmlStep from './DialogueBoxHtmlStep/DialogueBoxHtmlStep';
import { useMessageContext } from '../../context/MessageContext/context';
import useApi from '../../hooks/useApi';
import useToggleHeader from '../../hooks/useToggleHeader';
import { MessageResponseType } from '../../types/Message.type';
import { CreateMessageParams } from '../../types/CreateMessage.type';
import colors from '../../styles/colors';
import NotFound from '../not-found/NotFound';
import isReadOnly from '../../utils/message';
import DialogueBox from '../../components/DialogueBox/DialogueBox';

const CreateMessage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { path } = useRouteMatch();
  const location = useLocation();
  const { messageId } = useParams<CreateMessageParams>();
  const [{ responseData, isLoading }] = useApi<MessageResponseType>({
    url: `/msg-box-api/messages/${messageId}`,
  });
  const history = useHistory();
  const { update } = useMessageContext();
  const { hide } = useToggleHeader();

  const modalBackListMessage = () => {
    setShowModal((currentState) => !currentState);
  };

  const redirectToListMessage = () => {
    history.push('/messages');
  };

  useEffect(() => {
    hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseData) {
      update(responseData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  return (
    <>
      {!isLoading ? (
        <CreateMessageWrapper>
          <Header>
            <AxaIcon height={40} width={40} />
            <HeaderTitle>
              <HeaderTitleSpan isSelected={location.pathname.endsWith('htmlcode')}>メッセージを作成</HeaderTitleSpan>
              <HeaderTitleSpan isSelected={location.pathname.endsWith('audience')}>オーディエンスを選択</HeaderTitleSpan>
              <HeaderTitleSpan isSelected={location.pathname.endsWith('scheduler')}>スケジュール</HeaderTitleSpan>
              <HeaderTitleSpan isSelected={location.pathname.endsWith('confirmation')}>確認</HeaderTitleSpan>
            </HeaderTitle>
            {responseData && isReadOnly(responseData.sentOn) ? (
              <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={redirectToListMessage}>
                メッセージに戻る
              </Button>
            ) : (
              <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={modalBackListMessage}>
                終了する
              </Button>
            )}
          </Header>
          <ContentStepWrapper>
            <Switch>
              <Route path={`${path}/htmlcode`} component={HtmlCodeStep} />
              <Route path={`${path}/audience`} component={AudienceStep} />
              <Route path={`${path}/scheduler`} component={SchedulerStep} />
              <Route path={`${path}/confirmation`} component={ConfirmationStep} />
              <Route path="*" component={NotFound} />
            </Switch>
          </ContentStepWrapper>
          {!location.pathname.endsWith('htmlcode') ? (
            <Modal isDisplay={showModal} close={modalBackListMessage}>
              <DialogueBox title="" confirmationButtonText="終了する" confirmation={redirectToListMessage} closeModal={modalBackListMessage}>
                作成中のメッセージを破棄して終了しますか？
              </DialogueBox>
            </Modal>
          ) : (
            <Modal isDisplay={showModal} close={modalBackListMessage}>
              <DialogueBoxHtmlStep closeModal={modalBackListMessage} />
            </Modal>
          )}
        </CreateMessageWrapper>
      ) : (
        <LoaderWrapper>
          <Loader height={2} width={1} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default CreateMessage;
