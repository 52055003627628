import colors from '@alj-react/ui-components/styles/colors';

const colorsCustom = {
  ...colors,
  blueLight: '#EFF3FE',
  blue: '#5E81F4',
  blueDark: '#00008f',
  grayDark: '#1C1D21',
  gray: '#8181A5',
  grayLight: '#e9e9f2',
  grayLighter: '#fbfbfd',
  background: '#F5F5FA',
  orange: '#F07662',
  black: '#000000',
  redLight: '#E020200D',
  red: '#E02020',
  green: '#008000',
  greenLight: '#0080001A',
  greenLighter: '#0080000D',
};

export default colorsCustom;
