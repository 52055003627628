import React from 'react';
import Layout from '@alj-react/ui-components/Layout';
import Text from '@alj-react/ui-components/Text';
import Button from '@alj-react/ui-components/Button';

const goBack = () => {
  window.location.pathname = '/';
};

const NotAuthenticatedPage: React.FC = () => (
  <Layout>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text size="36">You are not authenticated</Text>
      <code style={{ paddingBottom: '50px' }}>{decodeURIComponent(window.location.search.split('?')[1])}</code>
      <Button onClick={goBack}>Back</Button>
    </div>
  </Layout>
);

export default NotAuthenticatedPage;
