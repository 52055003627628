import styled from 'styled-components';
import colorsCustom from '../../../styles/colors';
import { calendar, backgroundCalendar } from '../../../styles/zindex';

const CALENDAR_WIDTH = 15;
const ITEM_PER_ROW = 7;

const dayItemSize = CALENDAR_WIDTH / ITEM_PER_ROW;

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: ${backgroundCalendar};
`;

export const CalendarWrapper = styled.div`
  float: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${CALENDAR_WIDTH}rem;
  min-height: 18rem;
  background: ${colorsCustom.white};
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 0.8rem 1.2rem;
  z-index: ${calendar};
`;

export const CalendarNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
`;

export const CalendarNavigationButton = styled.button`
  width: 2.1rem;
  height: 2.1rem;
  border: none;
  border-radius: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: ${colorsCustom.white};
  color: ${colorsCustom.darkGray};
  cursor: pointer;

  :hover {
    background-color: ${colorsCustom.grayLighter};
  }
`;

export const CalendarNavigationCurrentDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CalendarNavigationCurrentYear = styled.div`
  color: ${colorsCustom.darkGray};
  font-weight: 400;
  font-size: 1.6rem;
`;

export const CalendarNavigationCurrentMonth = styled.div`
  color: ${colorsCustom.darkGray};
  font-weight: 400;
  font-size: 0.7rem;
`;

export const CalendarBody = styled.div`
  float: left;
  width: 100%;
  margin-top: 20px;
`;

export const CalendarHeaderDays = styled.header``;

export const CalendarHeaderDaysList = styled.ul`
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const CalendarHeaderDaysItem = styled.li`
  position: relative;
  display: inline-block;
  width: ${dayItemSize}rem;
  height: ${dayItemSize}rem;
  line-height: 30px;
  font-weight: 700;
  color: ${colorsCustom.darkGray};
  font-size: 0.7rem;
  text-align: center;
`;

export const CalendarDaysList = styled.ul`
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
`;

export const CalendarDayItem = styled.li`
  position: relative;
  display: inline-block;
  width: ${dayItemSize}rem;
  line-height: ${dayItemSize}rem;
  font-size: 0.8rem;
  font-weight: 400;
`;

export const CalendarDayItemButton = styled.button<{ isCurrentDay: boolean; isSelectedDay: boolean }>`
  border: none;
  border-radius: 100%;
  width: ${dayItemSize}rem;
  line-height: ${dayItemSize}rem;
  height: ${dayItemSize}rem;
  color: ${(props) => (props.isSelectedDay ? colorsCustom.white : colorsCustom.darkGray)};
  background: ${(props) => {
    if (props.isSelectedDay) {
      return colorsCustom.axaBlue;
    }
    if (props.isCurrentDay) {
      return colorsCustom.blueLight;
    }
    return colorsCustom.white;
  }};
  font-weight: ${(props) => (props.isCurrentDay || props.isSelectedDay ? '800' : 400)};

  :hover {
    cursor: ${(props) => (props.isSelectedDay ? 'default' : 'pointer')};
    font-weight: 800;
  }

  :disabled {
    cursor: default;
    color: ${colorsCustom.grayLight};
    background: ${colorsCustom.white} !important;
  }
`;
