import React from 'react';
import MainWrapper from './Main.styles';
import useToggleHeader from '../../hooks/useToggleHeader';

const Main: React.FC = ({ children }) => {
  const { headerStatus } = useToggleHeader();
  return <MainWrapper headerStatus={headerStatus}>{children}</MainWrapper>;
};

export default Main;
