const isReadOnly = (sentOn: string | null): boolean => {
  if (!sentOn) {
    return false;
  }
  if (new Date(sentOn) > new Date()) {
    return false;
  }
  return true;
};

export default isReadOnly;
