import React from 'react';
import { CardWrapper, CardButton, CardContent } from './Card.styles';

type CardProps = {
  id: string;
  title: string;
  onClickItem?(id: string): void;
};

const Card: React.FC<CardProps> = ({ id, title, onClickItem, children }) => {
  return (
    <CardWrapper>
      {onClickItem ? (
        <CardButton onClick={() => onClickItem(id)} data-testid={`${title}${id}`}>
          {children}
        </CardButton>
      ) : (
        <CardContent>{children}</CardContent>
      )}
    </CardWrapper>
  );
};

export default Card;
