import React from 'react';
import { Bloc, Button, Content, Chevron, SelectedLine } from './PaginationBloc.styles';
import ChevronIcon from '../../../svgs/ChevronIcon';

export type PaginationBlocProps = {
  isCurrentPage: boolean;
  currentPage: number;
  content: string;
  targetPage: number;
  totalPage: number;
  changePage(pageNumber: number): void;
};

const PaginationBloc: React.FC<PaginationBlocProps> = ({ isCurrentPage, currentPage, content, targetPage, totalPage, changePage }) => {
  const changePageVerification = () => {
    if (targetPage >= 1 && targetPage <= totalPage && currentPage !== targetPage) {
      changePage(targetPage);
    }
  };

  const isNumber = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  return (
    <Bloc isCurrentPage={isCurrentPage} isChevron={!isNumber(content)}>
      <Button isCurrentPage={isCurrentPage} contentValue={content} onClick={() => changePageVerification()}>
        {(content === 'NEXT' || content === 'PREV') && (
          <Chevron contentValue={content}>
            <ChevronIcon height="15" width="15" />
          </Chevron>
        )}
        <Content data-testid={isCurrentPage ? 'is_current_page' : ''} contentValue={content}>
          {content}
        </Content>
        {isCurrentPage && <SelectedLine />}
      </Button>
    </Bloc>
  );
};

export default PaginationBloc;
