import styled from 'styled-components';
import colors from '../../../styles/colors';
import { background, base } from '../../../styles/zindex';

export const AudienceStepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: ${base};

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-30%, -55%);
    z-index: ${background};
  }
`;

export const AudienceContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10rem;
`;

export const AudienceForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TitleForm = styled.h2`
  font-size: 2rem;
  color: ${colors.darkGray};
  margin: 0 0 1.8rem 0;
  padding: 0;
`;

export const RadioInputWrapper = styled.div<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${(props) => (props.checked ? colors.axaBlue : colors.grayLight)};
  height: 7.5rem;
  padding: 0 1.5rem;
  background-color: ${colors.white};
  margin-bottom: 1rem;
`;

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  background-color: ${colors.background};
`;

export const ErrorMessage = styled.div`
  color: ${colors.axaRed};
  font-size: 1rem;
  line-height: 1rem;
`;
