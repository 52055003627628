import React from 'react';
import CeilInfoSubInfo from '../../../components/CeilInfoSubInfo/CeilInfoSubInfo';
import ListIcon from '../../../svgs/ListIcon';
import { TitleWrapper, MessageStatus, DateWrapper, DateInfo } from './ItemAudience.styles';
import CheckBoxCustom from '../../../components/CheckBox/CheckBox';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import { StatusAudience } from '../../../types/Audience.type';
import Card from '../../../components/Card/Card';

const dateInfoFormat = (date: Date, status: StatusAudience): string => {
  if (status === 'ready') {
    return fromDateToJapaneseStringDate(date);
  }
  if (status === 'creating') {
    return 'Processing...';
  }
  return 'Failed';
};

type ItemAudienceProps = {
  id: string;
  title: string;
  status: StatusAudience;
  isChecked: boolean;
  count: number;
  isAttached: boolean;
  date: string;
  onClickCheckBox(id: string, event: React.MouseEvent): void;
};

const ItemAudience: React.FC<ItemAudienceProps> = ({ id, title, status, count, isAttached, date, isChecked, onClickCheckBox }) => {
  const sentOnDate = new Date(date);

  return (
    <Card id={id} title={title}>
      <TitleWrapper>
        <CheckBoxCustom id={id} value={id} checked={isChecked} onClick={(event) => onClickCheckBox(id, event)} disabled={isAttached} />
        <MessageStatus>
          <ListIcon height="25" width="25" />
        </MessageStatus>
        {title}
      </TitleWrapper>
      <CeilInfoSubInfo info={count.toString()} subInfo="送信先" flexGrow={20} />
      <DateWrapper>
        <DateInfo>{dateInfoFormat(sentOnDate, status)}</DateInfo>
      </DateWrapper>
    </Card>
  );
};

export default ItemAudience;
