import React from 'react';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import { DateCreationInfo, TitleInput, TitleLabel } from './TitleDateHelper.styles';

const formatDate = (date: Date) => {
  return `${fromDateToJapaneseStringDate(date)}に作成`;
};

type TitleDateHelperProps = {
  titleValue: string;
  titlePlaceholder: string;
  date: Date;
  isReadOnly: boolean;
  titleChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

const TitleDateHelper: React.FC<TitleDateHelperProps> = ({ titleValue, titlePlaceholder, isReadOnly, date, titleChange }) => {
  return (
    <>
      <TitleLabel htmlFor="title">Title</TitleLabel>
      <TitleInput
        type="text"
        id="title"
        name="title"
        placeholder={titlePlaceholder}
        value={titleValue}
        onChange={titleChange}
        maxLength={255}
        required
        readOnly={isReadOnly}
      />
      <DateCreationInfo>{formatDate(date)}</DateCreationInfo>
    </>
  );
};

export default TitleDateHelper;
