export enum HeaderActionEnum {
  Toggle,
  Hide,
  Show,
}

export type Toggle = {
  type: HeaderActionEnum.Toggle;
};

export type Hide = {
  type: HeaderActionEnum.Hide;
};

export type Show = {
  type: HeaderActionEnum.Show;
};

export type HeaderActions = Hide | Toggle | Show;
