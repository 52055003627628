import styled from 'styled-components';
import colors from '../../styles/colors';
import { modalContent } from '../../styles/zindex';

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1.2rem 1.8rem;
`;

export const PageHeaderLeftSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
`;

export const PageHeaderRightSection = styled.div`
  button {
    :nth-child(1) {
      margin-right: 0.5rem;
    }
  }
`;

export const PageHeaderTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.3rem;
`;

export const QuickActionModal = styled.ul<{ isDisplay: boolean }>`
  position: absolute;
  top: 5rem;
  right: 2rem;
  height: auto;
  width: 15rem;
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  z-index: ${modalContent};
  margin: 0px;
  padding: 0px;
`;
