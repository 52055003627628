import { MessageResponseType } from '../../types/Message.type';

// TODO: replace with japanese text
export enum ValidationMessageEnum {
  Title = 'Title is missing',
  Content = 'Content is missing',
  Audience = 'Audience is missing',
  PreviousStepContent = 'Step メッセージを作成: content is missing',
  PreviousStepAudience = 'Step オーディエンスを選択: audience is missing',
  Scheduler = 'Date is in the past or missing',
}

const messageContextInitialState: MessageContextState = {
  isSubmitted: false,
  isValid: false,
  validationMessage: '',
  message: {
    id: '',
    updatedAt: null,
    createdAt: null,
    adminArchived: false,
    audienceId: null,
    sentOn: null,
    lastModifiedBy: 'messageBoxAdmin',
    createdFrom: process.env.REACT_APP_AUDIENCE_CREATED_FROM as string,
    title: null,
    content: null,
    importantFlag: false,
    description: null,
  },
};

export type MessageContextState = {
  message: MessageResponseType;
  validationMessage: ValidationMessageEnum | '';
  isSubmitted: boolean;
  isValid: boolean;
};

export default messageContextInitialState;
