import styled from 'styled-components';
import media from '../../styles/breakpoints';

const HomePageWrapper = styled.div`
  margin-left: 0;
  ${media.mediumDown} {
    margin-left: 0;
  }
`;

export default HomePageWrapper;
