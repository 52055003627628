import styled from 'styled-components';
import colors from '../../styles/colors';

export const DatePickerWrapper = styled.div`
  float: left;
  position: relative;
  user-select: none;
`;

export const DatePickerLabelInput = styled.label`
  position: relative;
  float: left;
  overflow: hidden;
  font-size: 0.9rem;
  color: ${colors.gray};
  margin-bottom: 0rem;
`;

export const DatePickerLabelSpan = styled.span`
  display: block;
`;

export const DatePickerInputWrapper = styled.div`
  position: relative;
`;

export const DatePickerInput = styled.input`
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 15rem;
  border: none;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 15px 5px;
  font-size: 1rem;
  color: ${colors.grayDark};
  font-weight: 600;

  :focus {
    outline: none;
    border-bottom: 1px solid ${colors.axaBlue};
    box-shadow: none;
  }

  :disabled {
    background-color: ${colors.white};
    color: ${colors.grayLight};
    font-weight: 400;
  }

  :invalid {
    border-bottom: 1px solid ${colors.axaRed};
  }

  :valid {
    border-bottom: 1px solid ${colors.aquaGreen};
  }
`;

export const DatePickerInputIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, 0);
`;
