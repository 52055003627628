import styled from 'styled-components';
import colors from '../../styles/colors';

export const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SelectBoxLabel = styled.label`
  font-size: 0.9rem;
  color: ${colors.gray};
  margin-bottom: 0rem;
`;

export const SelectBoxSelect = styled.select`
  min-width: 18rem;
  border: none;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 15px 0px;
  font-size: 1rem;
  color: ${colors.grayDark};
  font-weight: 600;

  :focus {
    outline: none;
    border-bottom: 1px solid ${colors.aquaGreen};
    box-shadow: none;
  }

  :disabled {
    background-color: ${colors.white};
    color: ${colors.grayLight};
    font-weight: 400;
  }
`;
