import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const UploadImageWrapper = styled.div`
  position: relative;
  display: block;
  height: 20rem;
  margin-bottom: 1.5rem;
  border: dashed 1px ${colors.grayLight};
  padding: 1rem;
`;

export const UploadLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const UploadInfoSpan = styled.span`
  color: ${colors.grayDark};
  font-size: 1rem;
`;

export const UploadSubInfoSpan = styled.span`
  color: ${colors.gray};
  font-size: 0.8rem;
`;

export const ImageBase64Content = styled.div`
  display: inline-block;
  width: 100%;
  word-break: break-all;
  overflow-wrap: anywhere;
  overflow-y: auto;
  height: 100%;
`;

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
