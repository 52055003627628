import styled from 'styled-components';
import colors from '../../../styles/colors';

export const CreateAudienceForm = styled.form`
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: start;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin: 2rem 0;
  border-bottom: solid 1px ${colors.grayLight};
`;

export const UploadCSVWrapper = styled.div`
  position: relative;
  display: block;
  height: 10rem;
  border: dashed 1px ${colors.grayLight};
  padding: 1rem;
`;

export const UploadLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const UploadInfoSpan = styled.span`
  color: ${colors.grayDark};
  font-size: 1rem;
`;

export const UploadSubInfoSpan = styled.span`
  color: ${colors.gray};
  font-size: 0.8rem;
`;

export const SuccessUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const FileNameSpan = styled.span`
  color: ${colors.grayDark};
  font-size: 1rem;
  margin: 0 1rem 0 0.5rem;
`;

export const DeleteButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
  height: 22px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;
