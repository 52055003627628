/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { HtmlMessageButton, HtmlMessageWrapper } from './HtmlMessage.styles';
import EditIcon from '../../svgs/EditIcon';
import EyeIcon from '../../svgs/EyeIcon';

type HtmlMessageProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isReadOnly?: boolean;
};

const HtmlMessage: React.FC<HtmlMessageProps> = ({ isReadOnly = false, ...rest }) => {
  return (
    <HtmlMessageWrapper>
      <HtmlMessageButton type="button" {...rest}>
        HTML Message
        {isReadOnly ? <EyeIcon height="20" width="20" /> : <EditIcon height="20" width="20" />}
      </HtmlMessageButton>
    </HtmlMessageWrapper>
  );
};

export default HtmlMessage;
