import styled from 'styled-components';
import colors from '../../../styles/colors';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 50%;
  color: ${colors.grayDark};
  font-weight: 600;
  font-size: 0.9rem;
`;

export const MessageStatus = styled.div`
  position: relative;
  margin: 0rem 1rem;
  background-color: ${colors.background};
  height: 3rem;
  width: 3rem;
  border-radius: 6px;
  border: none;
  color: ${colors.gray};
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const DateWrapper = styled.div`
  flex: 0 1 40%;
  text-align: left;
`;

export const DateInfo = styled.div`
  display: inline-block;
  background-color: ${colors.background};
  color: ${colors.gray};
  border-radius: 5px;
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
`;

export const FlagWrapper = styled.div`
  flex: 0 1 10%;
  text-align: left;
  margin-right: 2rem;
`;

export const Flag = styled.div`
  display: inline-block;
  background-color: ${colors.redLight};
  color: ${colors.red};
  border-radius: 5px;
  padding: 0.6rem 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
`;
