/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ButtonCustom from './Button.styles';
import Loader from '../Loader/Loader';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  backgroundColor: string;
  textColor: string;
  hoverBackgroundColor?: string;
  icon?: React.ReactNode;
  isLoading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ icon, backgroundColor, textColor, hoverBackgroundColor, isLoading, children, ...rest }) => {
  return (
    <>
      <ButtonCustom
        type="button"
        backgroundColor={backgroundColor}
        textColor={textColor}
        hasIcon={icon != null}
        hoverBackgroundColor={hoverBackgroundColor}
        isLoading={isLoading}
        {...rest}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader height={0.5} width={0.5} />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </ButtonCustom>
    </>
  );
};

export default Button;
