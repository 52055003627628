import React from 'react';
import ModalWrapper from './Modal.styles';

type ModalProps = {
  isDisplay: boolean;
  close(): void;
};

const Modal: React.FC<ModalProps> = ({ children, isDisplay, close }) => {
  return (
    <>
      <ModalWrapper isDisplay={isDisplay} onClick={close} />
      {isDisplay && children}
    </>
  );
};

export default Modal;
