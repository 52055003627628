import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import HomePageWrapper from './Home.styles';

const Home: React.FC = () => {
  return (
    <HomePageWrapper>
      <PageHeader pageTitle="ダッシュボード" />
    </HomePageWrapper>
  );
};

export default Home;
