/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SelectBoxWrapper, SelectBoxLabel, SelectBoxSelect } from './SelectBox.styles';

export type OptionType = {
  id: string;
  value: string;
  text: string;
};

type SelectBoxProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  options: OptionType[];
};

const SelectBox: React.FC<SelectBoxProps> = ({ label, options, ...rest }) => {
  return (
    <SelectBoxWrapper>
      <SelectBoxLabel htmlFor={label}>{label}</SelectBoxLabel>
      <SelectBoxSelect name={label} {...rest}>
        <option value="" disabled hidden>
          送信先リスト名
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.text}
          </option>
        ))}
      </SelectBoxSelect>
    </SelectBoxWrapper>
  );
};

export default SelectBox;
