/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const UserListIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 12.6363C19.6 14.5567 17.9882 16.1135 16 16.1135C14.0118 16.1135 12.4 14.5567 12.4 12.6363C12.4 10.7158 14.0118 9.159 16 9.159C17.9882 9.159 19.6 10.7158 19.6 12.6363ZM18.1507 16.7823C19.7218 16.0205 20.8 14.45 20.8 12.6363C20.8 10.0757 18.651 7.99991 16 7.99991C13.349 7.99991 11.2 10.0757 11.2 12.6363C11.2 14.45 12.2782 16.0205 13.8493 16.7823C10.4756 17.6897 8 20.6792 8 24.2272C8 24.3121 8.00142 24.3967 8.00423 24.4809C8.01409 24.776 8.27239 24.9999 8.57809 24.9999C8.93397 24.9999 9.20812 24.6996 9.20128 24.356C9.20043 24.3131 9.2 24.2702 9.2 24.2272C9.2 20.5997 12.2445 17.659 16 17.659C19.7555 17.659 22.8 20.5997 22.8 24.2272C22.8 24.2702 22.7996 24.3131 22.7987 24.356C22.7919 24.6996 23.066 24.9999 23.4219 24.9999C23.7276 24.9999 23.9859 24.776 23.9958 24.4809C23.9986 24.3967 24 24.3121 24 24.2272C24 20.6792 21.5244 17.6897 18.1507 16.7823Z"
        fill="#5F5F5F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3499 9.6C28.3499 10.8979 27.2978 11.95 25.9999 11.95C24.7021 11.95 23.6499 10.8979 23.6499 9.6C23.6499 8.30213 24.7021 7.25 25.9999 7.25C27.2978 7.25 28.3499 8.30213 28.3499 9.6ZM27.6132 12.8192C28.7914 12.2276 29.5999 11.0082 29.5999 9.6C29.5999 7.61177 27.9882 6 25.9999 6C24.0117 6 22.3999 7.61177 22.3999 9.6C22.3999 11.0082 23.2085 12.2276 24.3867 12.8192C21.8565 13.5238 20 15.845 20 18.5998C20 18.6261 20.0002 18.6523 20.0005 18.6785C20.0043 18.9749 20.2554 19.1998 20.5518 19.1998H20.5986C20.9188 19.1998 21.1651 18.9199 21.1651 18.5998C21.1651 15.7831 23.1833 13.7998 26 13.7998C28.8167 13.7998 30.8 15.7831 30.8 18.5998C30.8 18.9199 31.0463 19.1998 31.3664 19.1998H31.4326C31.7378 19.1998 31.9965 18.9682 31.9997 18.663C31.9999 18.6419 32 18.6209 32 18.5998C32 15.845 30.1434 13.5238 27.6132 12.8192Z"
        fill="#5F5F5F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34995 9.6C8.34995 10.8979 7.29782 11.95 5.99995 11.95C4.70208 11.95 3.64995 10.8979 3.64995 9.6C3.64995 8.30213 4.70208 7.25 5.99995 7.25C7.29782 7.25 8.34995 8.30213 8.34995 9.6ZM7.61324 12.8192C8.79141 12.2276 9.59995 11.0082 9.59995 9.6C9.59995 7.61177 7.98817 6 5.99995 6C4.01172 6 2.39995 7.61177 2.39995 9.6C2.39995 11.0082 3.2085 12.2276 4.38669 12.8192C1.85654 13.5238 0 15.845 0 18.5998C0 18.6261 0.000169114 18.6523 0.000506315 18.6785C0.00431899 18.9749 0.25544 19.1998 0.551843 19.1998H0.598644C0.918794 19.1998 1.16508 18.9199 1.16508 18.5998C1.16508 15.7831 3.18335 13.7998 6 13.7998C8.81665 13.7998 10.8 15.7831 10.8 18.5998C10.8 18.9199 11.0463 19.1998 11.3664 19.1998H11.4326C11.7378 19.1998 11.9965 18.9682 11.9997 18.663C11.9999 18.6419 12 18.6209 12 18.5998C12 15.845 10.1434 13.5238 7.61324 12.8192Z"
        fill="#5F5F5F"
      />
    </svg>
  );
};

export default UserListIcon;
