/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const GroupIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  width = '100%',
  height = '100%',
  ...rest
}) => {
  return (
    <svg viewBox="0 0 32 32" enableBackground="new 0 0 32 32" width={width} height={height} {...rest}>
      <circle fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" cx="23" cy="13" r="5" />
      <path fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" d="M16,25c0-3.866,3.134-7,7-7s7,3.134,7,7" />
      <circle fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" cx="9" cy="13" r="5" />
      <path fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" d="M2,25c0-3.866,3.134-7,7-7s7,3.134,7,7" />
    </svg>
  );
};

export default GroupIcon;
