/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const CsvFileIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg viewBox="0 0 32 32" width={width} height={height} {...rest}>
      <path
        fill={fill}
        d="M 6 3 L 6 10 L 8 10 L 8 5 L 24 5 L 24 10 L 26 10 L 26 3 Z M 9.5 12 C 8.117188 12 7 13.117188 7 14.5 L 7 17.5 C 7 18.882813 8.117188 20 9.5 20 C 10.882813 20 12 18.882813 12 17.5 L 12 17 L 10 17 L 10 17.5 C 10 17.714844 9.714844 18 9.5 18 C 9.285156 18 9 17.714844 9 17.5 L 9 14.5 C 9 14.285156 9.285156 14 9.5 14 C 9.714844 14 10 14.285156 10 14.5 L 10 15 L 12 15 L 12 14.5 C 12 13.117188 10.882813 12 9.5 12 Z M 15.5 12 C 14.117188 12 13 13.117188 13 14.5 C 13 15.882813 14.117188 17 15.5 17 C 15.714844 17 16 17.285156 16 17.5 C 16 17.800781 15.882813 18 15.5 18 C 15.132813 18 15.074219 17.921875 15.0625 17.90625 C 15.050781 17.890625 15 17.828125 15 17.59375 L 13 17.59375 C 13 18.160156 13.164063 18.796875 13.625 19.28125 C 14.085938 19.765625 14.769531 20 15.5 20 C 16.917969 20 18 18.800781 18 17.5 C 18 16.117188 16.882813 15 15.5 15 C 15.285156 15 15 14.714844 15 14.5 C 15 14.285156 15.285156 14 15.5 14 C 15.765625 14 15.847656 14.0625 15.90625 14.125 C 15.964844 14.1875 16 14.292969 16 14.40625 L 18 14.40625 C 18 13.816406 17.785156 13.214844 17.34375 12.75 C 16.902344 12.285156 16.234375 12 15.5 12 Z M 19 12 L 19 13.15625 L 19.0625 13.3125 L 21.0625 19.3125 L 22 22.15625 L 22.9375 19.3125 L 24.9375 13.3125 L 25 13.15625 L 25 12 L 23 12 L 23 12.875 L 22 15.875 L 21 12.875 L 21 12 Z M 6 22 L 6 29 L 26 29 L 26 22 L 24 22 L 24 27 L 8 27 L 8 22 Z"
      />
    </svg>
  );
};

export default CsvFileIcon;
