/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CheckboxContainer, StyledCheckbox, HiddenCheckbox, Icon, Label } from './CheckBox.styles';

type CheckBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  value: string;
  checked: boolean;
  label?: string;
};

const CheckBox: React.FC<CheckBoxProps> = ({ checked, value, id, label, ...rest }) => {
  const onClickStyledCheckbox = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox type="checkbox" id={id} value={value} {...rest} />
      <StyledCheckbox
        htmlFor={id}
        data-testid={`checkbox-${id}`}
        checked={checked}
        onClick={onClickStyledCheckbox}
        disabled={rest.disabled ? rest.disabled : false}
      >
        <Icon data-testid={`checkboxCheckmark-${id}`} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {label && <Label checked={checked}>{label}</Label>}
    </CheckboxContainer>
  );
};

export default CheckBox;
