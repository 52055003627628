import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import Button from '@alj-react/ui-components/Button';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import CheckBox from '../../../components/CheckBox/CheckBox';
import {
  DateFilterWrapper,
  ButtonWrapper,
  FilterBoxWrapper,
  FilterBoxTitle,
  DateFilterTitle,
  EfoDateSelectCustom,
  InputFilterWrapper,
  EfoTextFieldCustom,
} from './FilterBox.style';
import { FilterForm } from './FilterForm';
import { MessageItem, MessageItemForSearch } from '../../../types/Message.type';
import axiosInstance from '../../../utils/axios';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import Loader from '../../../components/Loader/Loader';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import { errorMessageFormatting } from '../../../hooks/useApi';

const PAGE_SIZE = 500;
const FIELDS_FOR_MESSAGE_LIST = 'id,sentOn,title,createdFrom,content';

const fetchMessages = async (accessToken: string, pageNumber: number) => {
  return axiosInstance.request<MessageItemForSearch[]>({
    url: `/msg-box-api/messages?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}&fields=${FIELDS_FOR_MESSAGE_LIST}&withAdditionalData=true&createdFrom=messageBoxAdmin`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getAllMessages = async (accessToken: string, totalMessages: React.MutableRefObject<MessageItemForSearch[]>) => {
  let response: AxiosResponse<MessageItemForSearch[], unknown>;
  let page = 1;
  if (!totalMessages.current.length) {
    do {
      // eslint-disable-next-line no-await-in-loop
      response = await fetchMessages(accessToken, page);
      if (page !== Number(response.headers['pagination-current-page'])) {
        break;
      }
      page += 1;
      totalMessages.current.push(...response.data);
    } while (
      Number(response.headers['pagination-total-count']) / Number(response.headers['pagination-page-size']) >
      Number(response.headers['pagination-current-page'])
    );
  }
};

type FilterBoxProps = {
  filterForm: FilterForm;
  totalMessages: React.MutableRefObject<MessageItemForSearch[]>;
  filterMessages(messagesFiltered: MessageItem[], key: string): void;
};

const FilterBox: React.FC<FilterBoxProps> = ({ filterMessages, filterForm, totalMessages }) => {
  const { fields, values, isValid, validate } = filterForm;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const { oidcUser } = useReactOidc();

  const filter = useCallback(() => {
    let resultMessageFiltered: MessageItemForSearch[] = totalMessages.current;
    let filteringKey = '';

    if (values.isFilterByTitle && values.title) {
      resultMessageFiltered = resultMessageFiltered.filter((message) => message.title?.includes(values.title));
      filteringKey = values.title;
    }
    if (values.isFilterByKeyword && values.word) {
      resultMessageFiltered = resultMessageFiltered.filter((message) => message.content?.includes(values.word));
      filteringKey = `${values.word}, ${filteringKey}`;
    }
    if (values.isFilterByDate && values.dateToDD && values.dateToMM && values.dateTo4Y) {
      const dateTo = new Date(Number(values.dateTo4Y), Number(values.dateToMM) - 1, Number(values.dateToDD));
      dateTo.setHours(23, 59, 59);
      resultMessageFiltered = resultMessageFiltered.filter((message) => message.sentOn && new Date(message.sentOn).getTime() < dateTo.getTime());
      filteringKey = `To ${fromDateToJapaneseStringDate(dateTo)}, ${filteringKey}`;
    }
    if (values.isFilterByDate && values.dateFromDD && values.dateFromMM && values.dateFrom4Y) {
      const dateFrom = new Date(Number(values.dateFrom4Y), Number(values.dateFromMM) - 1, Number(values.dateFromDD));
      resultMessageFiltered = resultMessageFiltered.filter((message) => message.sentOn && new Date(message.sentOn).getTime() > dateFrom.getTime());
      filteringKey = `From ${fromDateToJapaneseStringDate(dateFrom)}, ${filteringKey}`;
    }
    setIsLoading(false);
    return { resultMessageFiltered, filteringKey };
  }, [values]);

  const onClickDateFormFilter = () => {
    const newValue = !values.isFilterByDate;
    fields.isFilterByDate.setValue(newValue);
    if (!newValue) {
      fields.dateFromDD.setValue('');
      fields.dateFromMM.setValue('');
      fields.dateFrom4Y.setValue('');
      fields.dateToDD.setValue('');
      fields.dateToMM.setValue('');
      fields.dateTo4Y.setValue('');
    }
  };

  const search = async () => {
    if (isValid) {
      setErrorMessage(null);
      setIsLoading(true);

      try {
        await getAllMessages(oidcUser.access_token, totalMessages);
        const { resultMessageFiltered, filteringKey } = filter();
        return filterMessages(resultMessageFiltered, filteringKey);
      } catch (error) {
        setErrorMessage(error);
      }
    }
    return validate();
  };

  return (
    <FilterBoxWrapper
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <FilterBoxTitle>Search by</FilterBoxTitle>
      <CheckBox
        id="dateFormFilter"
        value="messageFromAdmin"
        checked={fields.isFilterByDate.value}
        label="送信日"
        onClick={onClickDateFormFilter}
        data-testid="checkbox-date"
      />
      <DateFilterWrapper>
        <DateFilterTitle>From</DateFilterTitle>
        <EfoDateSelectCustom
          day={{ field: fields.dateFromDD, 'data-testid': 'filter-date-from-day' }}
          month={{ field: fields.dateFromMM, 'data-testid': 'filter-date-from-month' }}
          year={{ field: fields.dateFrom4Y, 'data-testid': 'filter-date-from-year' }}
          disabled={!fields.isFilterByDate.value}
        />
      </DateFilterWrapper>
      <DateFilterWrapper>
        <DateFilterTitle>To</DateFilterTitle>
        <EfoDateSelectCustom
          day={{ field: fields.dateToDD, 'data-testid': 'filter-date-to-day' }}
          month={{ field: fields.dateToMM, 'data-testid': 'filter-date-to-month' }}
          year={{ field: fields.dateTo4Y, 'data-testid': 'filter-date-to-year' }}
          disabled={!fields.isFilterByDate.value}
        />
      </DateFilterWrapper>
      <InputFilterWrapper>
        <CheckBox
          id="titleFormFilter"
          value=""
          checked={fields.isFilterByTitle.value}
          label="タイトル"
          onClick={() => fields.isFilterByTitle.setValue(!values.isFilterByTitle)}
          data-testid="checkbox-title"
        />
        <EfoTextFieldCustom label="title" field={fields.title} disabled={!fields.isFilterByTitle.value} />
      </InputFilterWrapper>
      <InputFilterWrapper>
        <CheckBox
          id="keywordFormFilter"
          value=""
          checked={fields.isFilterByKeyword.value}
          label="キーワード"
          onClick={() => fields.isFilterByKeyword.setValue(!values.isFilterByKeyword)}
          data-testid="checkbox-keyword"
        />
        <EfoTextFieldCustom label="keyword" field={fields.word} disabled={!fields.isFilterByKeyword.value} />
      </InputFilterWrapper>
      <ButtonWrapper>
        <Button size="small" color="white" onClick={search} disabled={isLoading}>
          {isLoading ? <Loader height={0.4} width={0.3} /> : 'Search'}
        </Button>
      </ButtonWrapper>
      {errorMessage && <ErrorMessages messages={errorMessageFormatting(errorMessage)} />}
    </FilterBoxWrapper>
  );
};

export default FilterBox;
