import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AudienceStepWrapper, AudienceContent, Footer, AudienceForm, TitleForm, RadioInputWrapper } from './AudienceStep.styles';
import AudienceSelection, { TargetUserRadioEnum } from './AudienceSelection/AudienceSelection';
import Button from '../../../components/Button/Button';
import RadioInput from '../../../components/RadioInput/RadioInput';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import { useMessageContext } from '../../../context/MessageContext/context';
import colors from '../../../styles/colors';
import { CreateMessageParams } from '../../../types/CreateMessage.type';
import IllustrationAudience from '../../../svgs/IllustrationAudience';
import { MessageResponseType, PatchMessageType } from '../../../types/Message.type';
import useApi from '../../../hooks/useApi';

const AudienceStep: React.FC = () => {
  const [targetUserInput, setTargetUserInput] = useState(TargetUserRadioEnum.Audience);
  const history = useHistory();
  const { messageId } = useParams<CreateMessageParams>();
  const { updateAudience, validationTargetUser, resetForm, state } = useMessageContext();
  const [apiState, makeRequest] = useApi<MessageResponseType, PatchMessageType>();

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 200 && !apiState.hasError) {
      history.push(`/create-message/${messageId}/scheduler`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      makeRequest({
        url: `/msg-box-api/messages/${messageId}`,
        method: 'PATCH',
        data: {
          audienceId: state.message.audienceId,
          lastModifiedBy: state.message.lastModifiedBy,
          title: state.message.title,
          content: state.message.content,
          importantFlag: state.message.importantFlag,
          description: state.message.description,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  return (
    <AudienceStepWrapper>
      <IllustrationAudience height="500" width="500" />
      <AudienceContent>
        <AudienceForm>
          <TitleForm>送信先を選択</TitleForm>
          <RadioInputWrapper checked={targetUserInput === TargetUserRadioEnum.Audience}>
            <RadioInput
              name={TargetUserRadioEnum.Audience}
              value={TargetUserRadioEnum.Audience}
              checked={targetUserInput === TargetUserRadioEnum.Audience}
              onChange={(event) => setTargetUserInput(event.currentTarget.value as TargetUserRadioEnum)}
            >
              <AudienceSelection targetUserInput={targetUserInput} onChange={(event) => updateAudience(event.currentTarget.value)} />
            </RadioInput>
          </RadioInputWrapper>
          <ErrorMessages messages={[state.isSubmitted ? state.validationMessage : '', ...(apiState.errorResponseData ?? [])]} />
        </AudienceForm>
      </AudienceContent>
      <Footer>
        <Button
          backgroundColor={colors.axaBlue}
          textColor={colors.white}
          onClick={() => history.push(`/create-message/${messageId}/htmlcode`)}
          isLoading={apiState.isLoading}
        >
          戻る
        </Button>
        <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={validationTargetUser} isLoading={apiState.isLoading}>
          続行
        </Button>
      </Footer>
    </AudienceStepWrapper>
  );
};

export default AudienceStep;
