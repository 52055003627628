/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import { AudienceInfoButton, AudienceInfoContent, AudienceInfoTitle, AudienceInfoWrapper } from './AudienceInfo.styles';
import EditIcon from '../../../../svgs/EditIcon';
import useApi from '../../../../hooks/useApi';
import { AudienceResponseType } from '../../../../types/Audience.type';

type AudienceInfoProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  audienceId: string;
  isReadOnly: boolean;
};

const AudienceInfo: React.FC<AudienceInfoProps> = ({ audienceId, isReadOnly, ...rest }) => {
  const [{ responseData, isLoading }, makeRequest] = useApi<AudienceResponseType>();

  useEffect(() => {
    if (audienceId) {
      makeRequest({
        url: `/msg-box-api/audiences/${audienceId}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId]);

  return (
    <>
      {isReadOnly ? (
        <AudienceInfoWrapper>
          {isLoading && !responseData ? (
            <Loader height={0.8} width={0.3} />
          ) : (
            <>
              <AudienceInfoTitle>送信先</AudienceInfoTitle>
              <AudienceInfoContent>{responseData?.title}</AudienceInfoContent>
            </>
          )}
        </AudienceInfoWrapper>
      ) : (
        <AudienceInfoButton type="button" {...rest}>
          送信先
          <EditIcon height="20" width="20" />
        </AudienceInfoButton>
      )}
    </>
  );
};

export default AudienceInfo;
