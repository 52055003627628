import styled from 'styled-components';
import colors from '../../styles/colors';

export const RadioInputLabel = styled.label``;

export const StyledRadio = styled.input<{ checked: boolean }>`
  appearance: none;
  width: 20px;
  height: 20px;
  border: ${(props) => (props.checked ? `6px solid ${colors.axaBlue}` : 'none')};
  border-radius: 50%;
  background-color: ${(props) => (props.checked ? colors.white : colors.grayLight)};
  padding: 0px;
  margin-right: 2rem;
`;
