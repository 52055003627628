import { useContext } from 'react';
import { HeaderActionEnum } from '../context/HeaderContext/actions';
import { HeaderContext } from '../context/HeaderContext/context';
import { HeaderStatus } from '../types/HeaderStatus.type';

type ToggleHeaderType = {
  toggle(): void;
  hide(): void;
  show(): void;
  headerStatus: HeaderStatus;
};

const useToggleHeader = (): ToggleHeaderType => {
  const { state, dispatch } = useContext(HeaderContext);
  const toggle = () => dispatch({ type: HeaderActionEnum.Toggle });
  const hide = () => dispatch({ type: HeaderActionEnum.Hide });
  const show = () => dispatch({ type: HeaderActionEnum.Show });
  return { headerStatus: state.headerStatus, toggle, hide, show };
};

export default useToggleHeader;
