import { ApiActions, ApiActionsType } from './actions';
import { ApiContextState } from './state';

const apiReducer =
  <T>() =>
  (state: ApiContextState<T>, action: ApiActions<T>) => {
    switch (action.type) {
      case ApiActionsType.REQUEST_INIT:
        return {
          ...state,
          errorMessage: '',
          hasError: false,
          isLoading: true,
        };
      case ApiActionsType.REQUEST_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          hasError: false,
          isLoading: false,
          responseData: action.payload.responseData,
          responseStatus: action.payload.responseStatus,
          responseHeaders: action.payload.responseHeaders,
          responseMethod: action.payload.responseMethod,
        };
      case ApiActionsType.REQUEST_FAILURE:
        return {
          ...state,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload.errorMessage,
          errorResponseData: action.payload.errorResponseData,
          responseStatus: action.payload.responseStatus,
          responseMethod: action.payload.responseMethod,
        };
      default:
        return state;
    }
  };

export default apiReducer;
