/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RadioInputLabel, StyledRadio } from './RadioInput.styles';

type RadioInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean;
};

const RadioInput: React.FC<RadioInputProps> = ({ children, checked, ...rest }) => {
  return (
    <>
      <StyledRadio type="radio" checked={checked} {...rest} />
      <RadioInputLabel>{children}</RadioInputLabel>
    </>
  );
};

export default RadioInput;
