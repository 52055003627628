import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateMessageForm, Footer, Separator } from './CreateMessage.styles';
import BaseContentModal from '../../../components/BaseContentModal/BaseContentModal';
import Button from '../../../components/Button/Button';
import TitleDate from '../../../components/FormHelper/TitleDateHelper/TitleDateHelper';
import Flag from '../../../components/FormHelper/FlagHelper/FlagHelper';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import Description from '../../../components/FormHelper/DescriptionHelper/DescriptionHelper';
import useApi from '../../../hooks/useApi';
import { useMessageContext } from '../../../context/MessageContext/context';
import { CreateMessageType, MessageResponseType } from '../../../types/Message.type';
import colors from '../../../styles/colors';

type CreateMessageProps = {
  closeModal(): void;
};

const CreateMessage: React.FC<CreateMessageProps> = ({ closeModal }) => {
  const [apiState, makeRequest] = useApi<MessageResponseType, CreateMessageType>();
  const history = useHistory();
  const { updateTitle, updateDescription, updateImportant, validationFormTitle, resetForm, state } = useMessageContext();

  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTitle(event.target.value);
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateDescription(event.target.value);
  };

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      makeRequest({
        url: '/msg-box-api/messages',
        method: 'POST',
        data: {
          audienceId: state.message.audienceId,
          sentOn: state.message.sentOn,
          lastModifiedBy: state.message.lastModifiedBy,
          createdFrom: state.message.createdFrom,
          title: state.message.title,
          content: state.message.content,
          importantFlag: state.message.importantFlag,
          description: state.message.description,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 201 && !apiState.hasError) {
      history.push(`/create-message/${apiState.responseData?.id}/htmlcode`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const validationForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validationFormTitle();
  };

  return (
    <BaseContentModal title="新しいメッセージを作成" closeModal={closeModal}>
      <CreateMessageForm onSubmit={validationForm}>
        <TitleDate
          titleValue={state.message.title ?? ''}
          titlePlaceholder="新しいメッセージタイトルを入力"
          date={new Date()}
          isReadOnly={false}
          titleChange={titleChange}
        />
        <Flag isSelected={state.message.importantFlag} updateFlag={updateImportant} />
        <Separator />
        <Description description={state.message.description ?? ''} updateDescription={descriptionChange} isReadOnly={false} />
        <ErrorMessages messages={[state.isSubmitted ? state.validationMessage : '', ...(apiState.errorResponseData ?? [])]} />
        <Footer>
          <Button backgroundColor={colors.grayLight} textColor={colors.gray} hoverBackgroundColor={colors.white} onClick={closeModal}>
            キャンセル
          </Button>
          <Button type="submit" backgroundColor={colors.axaBlue} textColor={colors.white} isLoading={apiState.isLoading}>
            作成する
          </Button>
        </Footer>
      </CreateMessageForm>
    </BaseContentModal>
  );
};

export default CreateMessage;
