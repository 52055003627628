import styled from 'styled-components';
import colors from '../../styles/colors';

export const MessagesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
`;

export const MessageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 36rem;
  margin: 0 1.8rem 1.8rem 1.8rem;
  border-radius: 0.8rem;
  background-color: ${colors.white};
`;

export const FilterAdminContainer = styled.div<{ isChecked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.isChecked ? colors.blue : colors.blueLight)};
  border-radius: 6px;
  padding: 0 0.8rem;
  height: 2.7rem;
  min-width: 5rem;
  margin-right: 1rem;
`;

export const FilterContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.blue};
  border-radius: 6px;
  padding: 0 0.8rem;
  height: 2.7rem;
  width: 230px;
`;

export const FilteringKey = styled.p`
  flex-grow: 2;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ErrorWrapper = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;
`;

export const ContentListMessage = styled.ul`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 1.5rem 1.5rem 1.5rem;
  padding: 0;
`;

export const NoMessageText = styled.p`
  display: block;
  margin: 0 1.5rem 1.5rem 1.5rem;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.black};
`;

export const PaginationWrapper = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;
`;
