import React from 'react';
import { Header, BaseContentModalWrapper, Content, Title, CloseButton } from './BaseContentModal.styles';
import CrossIcon from '../../svgs/CrossIcon';

type BaseContentModalProps = {
  title: string;
  closeModal(): void;
};

const BaseContentModal: React.FC<BaseContentModalProps> = ({ title, closeModal, children }) => {
  return (
    <BaseContentModalWrapper>
      <Header>
        <Title>{title}</Title>
        <CloseButton data-testid="close-modal-button" onClick={closeModal}>
          <CrossIcon height="15" />
        </CloseButton>
      </Header>
      <Content>{children}</Content>
    </BaseContentModalWrapper>
  );
};

export default BaseContentModal;
