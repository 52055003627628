/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg viewBox="0 0 32 32" width={width} height={height} {...rest}>
      <path
        fill={fill}
        d="M 16 5 C 12.144531 5 9 8.144531 9 12 C 9 14.410156 10.230469 16.550781 12.09375 17.8125 C 8.527344 19.34375 6 22.882813 6 27 L 8 27 C 8 22.570313 11.570313 19 16 19 C 20.429688 19 24 22.570313 24 27 L 26 27 C 26 22.882813 23.472656 19.34375 19.90625 17.8125 C 21.769531 16.550781 23 14.410156 23 12 C 23 8.144531 19.855469 5 16 5 Z M 16 7 C 18.773438 7 21 9.226563 21 12 C 21 14.773438 18.773438 17 16 17 C 13.226563 17 11 14.773438 11 12 C 11 9.226563 13.226563 7 16 7 Z"
      />
    </svg>
  );
};

export default UserIcon;
