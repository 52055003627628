import React from 'react';
import CeilInfoSubInfo from '../../../components/CeilInfoSubInfo/CeilInfoSubInfo';
import FileIcon from '../../../svgs/FileIcon';
import ClockIcon from '../../../svgs/ClockIcon';
import PaperPlaneIcon from '../../../svgs/PaperPlaneIcon';
import { TitleWrapper, MessageStatus, FlagWrapper, DateWrapper, DateInfo, Flag } from './ItemMessage.styles';
import CheckBoxCustom from '../../../components/CheckBox/CheckBox';
import { fromDateToJapaneseStringDate } from '../../../utils/date';
import Card from '../../../components/Card/Card';

enum DateStatusEnum {
  Sent = 'に送信',
  Draft = 'に作成',
  Scheduled = '予定',
}

enum TextStatusEnum {
  Sent = '送信済み',
  Draft = 'ドラフト',
  Scheduled = '予定済み',
}

const infoStatusIconRender = (sentOn: string, sentOnDate: Date): React.SVGProps<SVGSVGElement> => {
  if (!sentOn) {
    return <FileIcon height="22" width="22" />;
  }
  if (sentOnDate > new Date()) {
    return <ClockIcon height="22" width="22" />;
  }
  return <PaperPlaneIcon height="22" width="22" />;
};

const dateInfoFormat = (sentOn: string, sentOnDate: Date): string => {
  let statusText = DateStatusEnum.Sent;
  if (!sentOn) {
    statusText = DateStatusEnum.Draft;
  }
  if (sentOnDate > new Date()) {
    statusText = DateStatusEnum.Scheduled;
  }
  return `${fromDateToJapaneseStringDate(sentOnDate)}${statusText}`;
};

const status = (sentOn: string, sentOnDate: Date): string => {
  if (!sentOn) {
    return TextStatusEnum.Draft;
  }
  if (sentOnDate > new Date()) {
    return TextStatusEnum.Scheduled;
  }
  return TextStatusEnum.Sent;
};

type ItemMessageProps = {
  id: string;
  sentOn: string;
  importantFlag: boolean;
  title: string;
  isChecked: boolean;
  audienceSize: number;
  totalRead: number;
  onClickCheckBox(id: string, event: React.MouseEvent): void;
  onClickItem(id: string): void;
};

const ItemMessage: React.FC<ItemMessageProps> = ({
  id,
  title,
  sentOn,
  importantFlag,
  isChecked,
  audienceSize,
  totalRead,
  onClickCheckBox,
  onClickItem,
}) => {
  const sentOnDate = new Date(sentOn);

  return (
    <>
      <Card id={id} title={title} onClickItem={onClickItem}>
        <TitleWrapper>
          <CheckBoxCustom
            id={id}
            value={id}
            checked={isChecked}
            onClick={(event) => onClickCheckBox(id, event)}
            disabled={status(sentOn, sentOnDate) === TextStatusEnum.Sent}
          />
          <MessageStatus>{infoStatusIconRender(sentOn, sentOnDate)}</MessageStatus>
          {title}
        </TitleWrapper>
        <CeilInfoSubInfo info={status(sentOn, sentOnDate)} subInfo="状態" flexGrow={10} />
        <CeilInfoSubInfo info={audienceSize.toString()} subInfo="連絡先" flexGrow={10} />
        <DateWrapper>{sentOn && <DateInfo>{dateInfoFormat(sentOn, sentOnDate)}</DateInfo>}</DateWrapper>
        <CeilInfoSubInfo info={totalRead.toString()} subInfo="連絡先" flexGrow={10} />
        <FlagWrapper>{importantFlag && <Flag>重要</Flag>}</FlagWrapper>
      </Card>
    </>
  );
};

export default ItemMessage;
