import React from 'react';
import LoaderWrapper from './loader.styles';

type LoaderProps = {
  height: number;
  width: number;
};

const Loader: React.FC<LoaderProps> = ({ height, width }) => {
  return <LoaderWrapper height={height} width={width} />;
};

export default Loader;
