/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg viewBox="0 0 32 32" width={width} height={height} {...rest}>
      <path
        fill={fill}
        d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"
      />
    </svg>
  );
};

export default CheckIcon;
