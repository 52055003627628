import styled from 'styled-components';
import { HeaderStatus } from '../../types/HeaderStatus.type';

const MainWrapper = styled.div<{ headerStatus: HeaderStatus }>`
  margin-left: ${(props) => {
    if (props.headerStatus === 'extended') return '16rem';
    if (props.headerStatus === 'normal') return '5rem';
    return '0rem';
  }};
  height: 100%;
`;

export default MainWrapper;
