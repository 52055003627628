import styled from 'styled-components';
import colors from '../../styles/colors';

export const AudiencesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
`;

export const AudiencesContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 36rem;
  margin: 0 1.8rem 1.8rem 1.8rem;
  border-radius: 0.8rem;
  background-color: ${colors.white};
`;

export const ErrorWrapper = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;
`;

export const ContentListAudience = styled.ul`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 1.5rem 1.5rem 1.5rem;
  padding: 0;
`;

export const PaginationWrapper = styled.div`
  margin: 1.5rem 1.5rem 0rem 1.5rem;
`;
