import React from 'react';
import { FlagWrapper, ImportantButton } from './FlagHelper.styles';

type FlagHelperProps = {
  isSelected: boolean;
  updateFlag(): void;
};

const FlagHelper: React.FC<FlagHelperProps> = ({ isSelected, updateFlag }) => {
  return (
    <FlagWrapper>
      <ImportantButton id="important" name="important" selected={isSelected} value="重要" onClick={updateFlag} />
    </FlagWrapper>
  );
};

export default FlagHelper;
