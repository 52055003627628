import styled from 'styled-components';
import EfoDateSelect, { DateSelectBase } from '@alj-react/ui-components/EfoDateSelect';
import EfoTextField, { FieldLabelBase } from '@alj-react/ui-components/EfoTextField';
import colors from '../../../styles/colors';
import { boxFilter } from '../../../styles/zindex';

export const EfoDateSelectCustom = styled(EfoDateSelect)`
  width: 320px;
  ${DateSelectBase} {
    width: 80px;
  }
`;

export const EfoTextFieldCustom = styled(EfoTextField)`
  ${FieldLabelBase} {
    margin: 0;
    font-size: 0;
  }
  width: 240px;
  margin-left: 20px;
`;

export const FilterBoxWrapper = styled.div`
  z-index: ${boxFilter};
  position: absolute;
  top: 50px;
  left: 0;

  border: 1px solid ${colors.blue};
  border-radius: 6px;

  width: 400px;
  padding: 1rem 0.8rem;

  background-color: ${colors.background};
`;

export const FilterBoxTitle = styled.h3`
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const FormFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const DateFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

export const DateFilterTitle = styled.h4`
  font-size: 12px;
  color: ${colors.axaBlue};
  margin-right: 1rem;
`;

export const InputFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  margin-top: 30px;

  button:nth-child(1) {
    margin-right: 30px;
  }
`;
