import React, { useEffect, useState } from 'react';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ContentHeaderTitleSide from '../../components/ContentHeader/ContentHeaderTitleSide/ContentHeaderTitleSide';
import ErrorMessages from '../../components/ErrorMessages/ErrorMessages';
import PageHeader from '../../components/PageHeader/PageHeader';
import Pagination from '../../components/Pagination/Pagination';
import TabHeader from '../../components/TabHeader/TabHeader';
import TabHeaderItem from '../../components/TabHeaderItem/TabHeaderItem';
import useApi from '../../hooks/useApi';
import CrossIcon from '../../svgs/CrossIcon';
import SearchIcon from '../../svgs/SearchIcon';
import { UserSearchItem } from '../../types/Users.type';
import MessageCard from './MessageCard/MessageCard';
import {
  ActionSearchButton,
  ContentListUsers,
  ErrorWrapper,
  PaginationWrapper,
  SearchForm,
  SearchInput,
  UsersContentWrapper,
  UsersPageWrapper,
} from './Users.styles';
import Modal from '../../components/Modal/Modal';
import MessageModal from './MessageModal/MessageModal';
import Loader from '../../components/Loader/Loader';
import useToggleHeader from '../../hooks/useToggleHeader';

const FIELDS_FOR_MESSAGE_LIST = 'id,sentOn,title,importantFlag,createdFrom';

const Users: React.FC = () => {
  const PAGE_SIZE = process.env.REACT_APP_MESSAGE_DEFAULT_PAGE_SIZE;
  const { show } = useToggleHeader();
  const [userId, setUserId] = useState<string>('');
  const [messageId, setMessageId] = useState<string>('');
  const [shouldDisplayMessageModal, setShouldDisplayMessageModal] = useState(false);
  const [{ responseData, responseHeaders, isLoading, errorResponseData, hasError }, makeRequest] = useApi<UserSearchItem[]>();

  useEffect(() => {
    show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userId) {
      makeRequest({
        url: `/msg-box-api/users/${userId}/messages?pageSize=${PAGE_SIZE}&pageNumber=1&fields=${FIELDS_FOR_MESSAGE_LIST}`,
      });
    }
  };

  const changePage = (page: number) => {
    makeRequest({
      url: `/msg-box-api/users/${userId}/messages?pageSize=${PAGE_SIZE}&pageNumber=${page}&fields=${FIELDS_FOR_MESSAGE_LIST}`,
    });
  };

  const displayMessages = (id: string) => {
    setMessageId(id);
    setShouldDisplayMessageModal(true);
  };

  // Unexpected Error can be an user not existing
  const catchUnexpectedError = (errorMessages: string[]) => {
    return errorMessages.map((errorMessage) =>
      errorMessage === 'エラーが発生しました: [500] Unexpected Error'
        ? 'エラーが発生しました: [500] You might be getting this error because the user you are trying to search does not exist'
        : errorMessage,
    );
  };

  return (
    <>
      <UsersPageWrapper>
        <PageHeader pageTitle="送信先" />
        <UsersContentWrapper>
          {hasError && (
            <ErrorWrapper>
              <ErrorMessages messages={catchUnexpectedError(errorResponseData as string[])} />
            </ErrorWrapper>
          )}
          <ContentHeader>
            <ContentHeaderTitleSide>
              <SearchForm onSubmit={search}>
                <ActionSearchButton type="submit" data-testid="button-search-user">
                  <SearchIcon height={20} width={20} />
                </ActionSearchButton>
                <SearchInput onChange={(event) => setUserId(event.target.value)} data-testid="input-search-user" />
                <ActionSearchButton type="reset">
                  <CrossIcon height={18} width={18} />
                </ActionSearchButton>
              </SearchForm>
            </ContentHeaderTitleSide>
          </ContentHeader>
          <TabHeader>
            <TabHeaderItem title="メッセージ" flexGrow={60} hasSortItem={false} />
            <TabHeaderItem title="日付" flexGrow={40} hasSortItem={false} />
          </TabHeader>
          {responseData && (
            <ContentListUsers data-testid="messages_list">
              {responseData.length &&
                responseData.map((message) => (
                  <MessageCard
                    key={message.id}
                    id={message.id}
                    title={message.title ?? ''}
                    sentOn={message.sentOn ?? ''}
                    importantFlag={message.importantFlag}
                    onClickItem={() => displayMessages(message.id)}
                  />
                ))}
            </ContentListUsers>
          )}
          {isLoading && <Loader height={2} width={1} />}
          {responseHeaders && responseData && responseData.length > 0 && (
            <PaginationWrapper>
              <Pagination
                itemPerPage={Number(responseHeaders['pagination-page-size'])}
                currentPage={Number(responseHeaders['pagination-current-page'])}
                numberOfItems={Number(responseHeaders['pagination-total-count'])}
                changePage={changePage}
              />
            </PaginationWrapper>
          )}
        </UsersContentWrapper>
      </UsersPageWrapper>
      <Modal isDisplay={shouldDisplayMessageModal} close={() => setShouldDisplayMessageModal(false)}>
        <MessageModal
          messageId={messageId}
          closeModal={() => {
            setShouldDisplayMessageModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default Users;
