import React, { createContext, useReducer } from 'react';
import { HeaderStatus } from '../../types/HeaderStatus.type';
import { HeaderActions, HeaderActionEnum } from './actions';
import { headerContextInitialState, HeaderState } from './state';

export const HeaderContext = createContext<{
  state: HeaderState;
  dispatch: React.Dispatch<HeaderActions>;
}>({
  state: headerContextInitialState,
  dispatch: () => undefined,
});

type HeaderProviderProps = {
  headerStatus: HeaderStatus;
};

const headerReducer = (state: HeaderState, action: HeaderActions): HeaderState => {
  switch (action.type) {
    case HeaderActionEnum.Toggle: {
      const newHeaderStatus = state.headerStatus === 'extended' ? 'normal' : 'extended';
      return { ...state, headerStatus: newHeaderStatus };
    }
    case HeaderActionEnum.Hide:
      return { ...state, headerStatus: 'hidden' };
    case HeaderActionEnum.Show: {
      const newHeaderStatus = state.headerStatus === 'hidden' ? 'normal' : state.headerStatus;
      return { ...state, headerStatus: newHeaderStatus };
    }
    default:
      return state;
  }
};

export const HeaderProvider: React.FC<HeaderProviderProps> = ({ children, headerStatus }) => {
  const initialState = {
    ...headerContextInitialState,
    headerStatus,
  };
  const [state, dispatch] = useReducer(headerReducer, initialState);

  return <HeaderContext.Provider value={{ state, dispatch }}>{children}</HeaderContext.Provider>;
};
