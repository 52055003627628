import styled from 'styled-components';
import colors from '../../styles/colors';

const Button = styled.button<{
  backgroundColor: string;
  textColor: string;
  hoverBackgroundColor: string | undefined;
  hasIcon: boolean;
  isLoading: boolean | undefined;
}>`
  position: relative;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  height: 2.8rem;
  width: auto;
  min-width: 6rem;
  border-radius: 6px;
  background-clip: padding-box;
  border: ${(props) => `1px solid ${props.backgroundColor}`};
  font-size: 0.8rem;
  padding: 0.7rem 1.2rem;
  padding-left: ${(props) => (props.hasIcon ? '2.7rem' : '1.2rem')};
  font-weight: 600;
  transition: background-color 400ms, color 400ms;

  svg {
    position: absolute;
    left: 0.7rem;
    top: 50%;
    transform: translate(0%, -50%);
  }

  :hover {
    background-color: ${(props) => (props.hoverBackgroundColor ? props.hoverBackgroundColor : props.textColor)};
    color: ${(props) => (props.hoverBackgroundColor ? props.textColor : props.backgroundColor)};
  }

  :disabled {
    cursor: ${(props) => (props.isLoading ? 'progress' : 'not-allowed')};
    border: ${(props) => `1px solid ${colors.blueLight}`};
    padding-left: ${(props) => (props.isLoading ? '1.2rem' : '2.7rem')};
    background-color: ${colors.white};
  }
`;

export default Button;
