import { MessageResponseType } from '../../types/Message.type';

export enum MessageActionType {
  ResetForm,
  Update,
  UpdateTitle,
  UpdateImportantFlag,
  UpdateDescription,
  UpdateContent,
  UpdateAudience,
  UpdateScheduler,
  ValidationFormTitle,
  ValidationContent,
  ValidationTargetUser,
  LastValidation,
}

export type ResetForm = {
  type: MessageActionType.ResetForm;
};

export type Update = {
  type: MessageActionType.Update;
  payload: {
    message: MessageResponseType;
  };
};

export type UpdateTitle = {
  type: MessageActionType.UpdateTitle;
  payload: {
    title: string;
  };
};

export type UpdateImportantFlag = {
  type: MessageActionType.UpdateImportantFlag;
};

export type UpdateDescription = {
  type: MessageActionType.UpdateDescription;
  payload: {
    description: string;
  };
};

export type UpdateContent = {
  type: MessageActionType.UpdateContent;
  payload: {
    content: string;
  };
};

export type UpdateAudience = {
  type: MessageActionType.UpdateAudience;
  payload: {
    audience: string;
  };
};

export type UpdateScheduler = {
  type: MessageActionType.UpdateScheduler;
  payload: {
    scheduler: string | null;
  };
};

export type ValidationFormTitle = {
  type: MessageActionType.ValidationFormTitle;
};

export type ValidationContent = {
  type: MessageActionType.ValidationContent;
};

export type ValidationTargetUser = {
  type: MessageActionType.ValidationTargetUser;
};

export type LastValidation = {
  type: MessageActionType.LastValidation;
};

export type MessageActions =
  | ResetForm
  | Update
  | UpdateTitle
  | UpdateImportantFlag
  | UpdateDescription
  | UpdateAudience
  | UpdateScheduler
  | ValidationFormTitle
  | ValidationContent
  | UpdateContent
  | ValidationTargetUser
  | LastValidation;
