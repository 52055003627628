export enum AudienceActionType {
  ResetForm,
  Update,
  UpdateTitle,
  UpdateDescription,
  UpdateFile,
  ValidationFormCreation,
}

export type ResetForm = {
  type: AudienceActionType.ResetForm;
};

export type UpdateTitle = {
  type: AudienceActionType.UpdateTitle;
  payload: {
    title: string;
  };
};

export type UpdateDescription = {
  type: AudienceActionType.UpdateDescription;
  payload: {
    description: string;
  };
};

export type UpdateFile = {
  type: AudienceActionType.UpdateFile;
  payload: {
    file: File | null;
  };
};

export type ValidationFormCreation = {
  type: AudienceActionType.ValidationFormCreation;
  payload: {
    file: string;
  };
};

export type AudienceActions = ResetForm | UpdateTitle | UpdateDescription | UpdateFile | ValidationFormCreation;
