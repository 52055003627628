/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputCustomWrapper, InputCustomLabel, InputCustomInput } from './InputCustom.styles';

type InputCustomProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

const InputCustom: React.FC<InputCustomProps> = ({ label, ...rest }) => {
  return (
    <InputCustomWrapper>
      <InputCustomLabel htmlFor="input_custom">{label}</InputCustomLabel>
      <InputCustomInput id="input_custom" name="input_custom" {...rest} />
    </InputCustomWrapper>
  );
};

export default InputCustom;
