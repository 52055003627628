import styled from 'styled-components';

export const PaginationWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
`;

export const BlocWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0;
  margin: 0 0 1.5rem 0;
`;
