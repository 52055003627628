import styled from 'styled-components';
import { HeaderStatus } from '../../types/HeaderStatus.type';
import { header } from '../../styles/zindex';

export const HeaderWrapper = styled.header<{ headerStatus: HeaderStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.headerStatus === 'hidden' ? 'none' : 'block')};
  width: ${(props) => {
    if (props.headerStatus === 'extended') return '16rem';
    return '5rem';
  }};
  background-color: white;
  z-index: ${header};
`;

export const HeaderNav = styled.nav`
  height: 100%;
`;

export const HeaderList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  height: 100%;
`;

export const HeaderLogo = styled.li`
  list-style: none;
  margin: 1rem 0px 0.7rem 1rem;
`;

export const HeaderMenuCollapse = styled.li<{ headerStatus: HeaderStatus }>`
  position: absolute;
  list-style: none;
  bottom: 1.5rem;
  left: ${(props) => (props.headerStatus === 'extended' ? '1.4rem' : '50%')};
  transform: translate(-50%, 0);
  transform: ${(props) => (props.headerStatus === 'extended' ? 'translate(0%, 0)' : 'translate(-50%, 0)')};
  svg {
    transform: ${(props) => (props.headerStatus === 'extended' ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;
