import styled from 'styled-components';
import colors from '../../styles/colors';

const TabHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.2rem 2.8rem;
  margin-bottom: 1.5rem;
  background-color: ${colors.grayLighter};
`;

export default TabHeader;
