import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ErrorWrapper from './DialogueBoxHtmlStep.styles';
import DialogueBox from '../../../components/DialogueBox/DialogueBox';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import { useMessageContext } from '../../../context/MessageContext/context';
import useApi from '../../../hooks/useApi';
import { CreateMessageParams } from '../../../types/CreateMessage.type';
import { MessageResponseType, PatchMessageType } from '../../../types/Message.type';

type DialogueBoxHtmlStepProps = {
  closeModal(): void;
};

const DialogueBoxHtmlStep: React.FC<DialogueBoxHtmlStepProps> = ({ closeModal }) => {
  const [apiState, makeRequest] = useApi<MessageResponseType, PatchMessageType>();
  const { messageId } = useParams<CreateMessageParams>();
  const history = useHistory();
  const { resetForm, state } = useMessageContext();

  const redirectToListMessage = (withSave: boolean) => {
    if (!withSave) {
      history.push('/messages');
    } else {
      makeRequest({
        url: `/msg-box-api/messages/${messageId}`,
        method: 'PATCH',
        data: {
          audienceId: state.message.audienceId,
          sentOn: state.message.sentOn,
          lastModifiedBy: state.message.lastModifiedBy,
          title: state.message.title,
          content: state.message.content,
          importantFlag: state.message.importantFlag,
          description: state.message.description,
        },
      });
    }
  };

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 200 && !apiState.hasError) {
      history.push('/messages');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return (
    <>
      <DialogueBox
        title=""
        confirmationButtonText="保存して終了"
        cancelButtonText="保存しないで終了"
        isLoading={apiState.isLoading}
        confirmation={() => redirectToListMessage(true)}
        cancel={() => redirectToListMessage(false)}
        closeModal={closeModal}
      >
        作成中のメッセージを破棄して終了しますか？
        {[...(apiState.errorResponseData ?? [])].length > 0 && (
          <ErrorWrapper>
            <ErrorMessages messages={[...(apiState.errorResponseData ?? [])]} />
          </ErrorWrapper>
        )}
      </DialogueBox>
    </>
  );
};

export default DialogueBoxHtmlStep;
