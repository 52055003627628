export type ConfigurationType = {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  post_logout_redirect_uri: string;
  scope: string;
  authority: string;
  silent_redirect_uri: string;
  automaticSilentRenew: boolean;
  loadUserInfo: boolean;
  metadata?: MetadataType;
  client_secret?: string;
};

export type MetadataType = {
  issuer: string;
  authorization_endpoint: string;
  token_endpoint: string;
  jwks_uri: string;
  end_session_endpoint: string;
  userinfo_endpoint: string;
  revocation_endpoint: string;
  introspection_endpoint: string;
};

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL as string;
const maamBaseUrl = process.env.REACT_APP_MAAM_BASE_URL as string;

const oidcConfiguration: ConfigurationType = {
  client_id: process.env.REACT_APP_CLIENT_ID as string,
  redirect_uri: `${appBaseUrl}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: `${appBaseUrl}/`,
  scope: `${process.env.REACT_APP_TOKEN_SCOPE}`,
  authority: maamBaseUrl,
  silent_redirect_uri: `${appBaseUrl}/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export default oidcConfiguration;
