import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button/Button';
import CopyButtonWrapper from './CopyButton.styles';
import CopyIcon from '../../../../../svgs/CopyIcon';
import CheckIcon from '../../../../../svgs/CheckIcon';
import colors from '../../../../../styles/colors';

type CopyButtonProps = {
  imageBase64: string;
};

const CopyButton: React.FC<CopyButtonProps> = ({ imageBase64 }) => {
  const [isCopy, setIsCopy] = useState(false);

  const copyImageBase64 = () => {
    navigator.clipboard.writeText(imageBase64);
    setIsCopy((currentStatus) => !currentStatus);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopy) {
      timeout = setTimeout(() => {
        setIsCopy((currentStatus) => !currentStatus);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [isCopy]);

  return (
    <CopyButtonWrapper>
      <Button
        backgroundColor={isCopy ? colors.aquaGreen : colors.grayLighter}
        textColor={isCopy ? colors.viridianGreen : colors.gray}
        icon={isCopy ? <CheckIcon height={20} width={20} /> : <CopyIcon height={20} width={20} />}
        disabled={isCopy}
        onClick={copyImageBase64}
      >
        コピー
      </Button>
    </CopyButtonWrapper>
  );
};

export default CopyButton;
