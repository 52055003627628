import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BaseContentModal from '../../../components/BaseContentModal/BaseContentModal';
import { useMessageContext } from '../../../context/MessageContext/context';
import {
  LoaderWrapper,
  SchedulerInput,
  SchedulerLabel,
  SchedulerResetButton,
  SchedulerTitle,
  SchedulerWrapper,
  Separator,
  UpdateMessageForm,
} from './UpdateMessage.styles';
import Calendar from '../../../components/DatePicker/Calendar/Calendar';
import AudienceInfo from './AudienceInfo/AudienceInfo';
import Loader from '../../../components/Loader/Loader';
import TitleDate from '../../../components/FormHelper/TitleDateHelper/TitleDateHelper';
import Flag from '../../../components/FormHelper/FlagHelper/FlagHelper';
import Description from '../../../components/FormHelper/DescriptionHelper/DescriptionHelper';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import useApi from '../../../hooks/useApi';
import { CreateMessageType, MessageResponseType, PatchMessageType } from '../../../types/Message.type';
import CalendarIcon from '../../../svgs/CalendarIcon';
import HourglassIcon from '../../../svgs/HourglassIcon';
import { getTimestampOfTheCurrentDay, transformTimestampToJapaneseStringDate } from '../../../utils/date';
import isReadOnly from '../../../utils/message';
import FormFooter from './FormFooter/FormFooter';
import HtmlMessage from '../../../components/HtmlMessage/HtmlMessage';

enum DateError {
  TimeErrorRegex = 'Time is not valid',
  DateIsMissing = 'Date is missing',
  DateIsPast = 'Date is past',
}

type UpdateMessageProps = {
  messageId: string;
  closeModal(withRefresh: boolean): void;
};

const UpdateMessage: React.FC<UpdateMessageProps> = ({ messageId, closeModal }) => {
  const history = useHistory();
  const [showDatePickerContent, setShowDatePickerContent] = useState(false);
  const [inputDateValue, setInputDateValue] = useState<Date | null>();
  const [inputTimeValue, setInputTimeValue] = useState('');
  const [timeErrorMessage, setTimeErrorMessage] = useState('');
  const [isForClone, setIsForClone] = useState(false);
  const [isRequestGetMessageDone, setIsRequestGetMessageDone] = useState<boolean>(false);
  const { updateTitle, updateDescription, updateImportant, updateScheduler, update, validationFormTitle, LastValidation, resetForm, state } =
    useMessageContext();
  const [apiState, makeRequest] = useApi<MessageResponseType, CreateMessageType | PatchMessageType>({
    url: `/msg-box-api/messages/${messageId}`,
  });

  useEffect(() => {
    if (apiState.responseData) {
      update(apiState.responseData);
      const timestamp = Date.parse(apiState.responseData.sentOn ?? '');
      if (!Number.isNaN(timestamp)) {
        const date = new Date(timestamp);
        setInputTimeValue(`${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`);
        setInputDateValue(date);
      } else {
        setInputTimeValue('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState.responseData]);

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      if (isForClone) {
        makeRequest({
          url: `/msg-box-api/messages`,
          method: 'POST',
          data: {
            audienceId: null,
            lastModifiedBy: null,
            content: state.message.content,
            importantFlag: state.message.importantFlag,
            description: state.message.description,
            createdFrom: process.env.REACT_APP_AUDIENCE_CREATED_FROM as string,
            sentOn: null,
            title: `[クローン] ${state.message.title}`,
          },
        });
      } else {
        makeRequest({
          url: `/msg-box-api/messages/${messageId}`,
          method: 'PATCH',
          data: {
            audienceId: state.message.audienceId,
            sentOn: state.message.sentOn,
            lastModifiedBy: state.message.lastModifiedBy,
            title: state.message.title,
            content: state.message.content,
            importantFlag: state.message.importantFlag,
            description: state.message.description,
          },
        });
      }
    }
    setIsForClone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  useEffect(() => {
    if (apiState.responseStatus === 200 && !apiState.hasError && apiState.responseMethod === 'get') {
      setIsRequestGetMessageDone(true);
    }
    if (apiState.responseStatus === 200 && !apiState.hasError && state.isSubmitted && apiState.responseMethod === 'patch') {
      closeModal(true);
    }
    if (apiState.responseStatus === 201 && !apiState.hasError && state.isSubmitted && apiState.responseMethod === 'post') {
      closeModal(true);
    }
    if (apiState.hasError) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTitle(event.target.value);
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateDescription(event.target.value);
  };

  const validationForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    // Examples string accepted => 12:30, 1:25, 1:02
    const regExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/gs;
    // Case date is present
    if (inputDateValue) {
      // And time follow the regex
      if (regExp.test(inputTimeValue)) {
        const newPublishDate = new Date(inputDateValue);
        const timeSplitted = inputTimeValue.split(':');
        newPublishDate.setHours(Number(timeSplitted[0]), Number(timeSplitted[1]));
        // Test if the date is past, return error if yes
        if (new Date(newPublishDate) < new Date()) {
          return setTimeErrorMessage(DateError.DateIsPast);
        }
        updateScheduler(newPublishDate.toISOString());
        LastValidation();
        return setTimeErrorMessage('');
      }
      return setTimeErrorMessage(DateError.TimeErrorRegex);
    }
    // Case date is missing but time is present
    if (inputTimeValue) {
      return setTimeErrorMessage(DateError.DateIsMissing);
    }
    // Date and Time are missing (not yet decided), a simple validation is needed
    return validationFormTitle();
  };

  const updateDateInput = (timestamp: number) => {
    const dateObject = new Date(timestamp);
    setInputDateValue(dateObject);
    setInputTimeValue('');
  };

  const toggleDatePicker = () => {
    if (!isReadOnly(state.message.sentOn)) {
      setShowDatePickerContent((currentState) => !currentState);
    }
  };

  const resetScheduler = () => {
    setInputTimeValue('');
    setInputDateValue(null);
    updateScheduler(null);
  };

  const cloneMessage = () => {
    validationFormTitle();
    setIsForClone(true);
  };

  return (
    <BaseContentModal title="メッセージの編集" closeModal={() => closeModal(false)}>
      {apiState.isLoading && !isRequestGetMessageDone ? (
        <LoaderWrapper>
          <Loader height={2} width={1} />
        </LoaderWrapper>
      ) : (
        <UpdateMessageForm onSubmit={validationForm}>
          <TitleDate
            titleValue={state.message.title ?? ''}
            titlePlaceholder="新しいメッセージタイトルを入力"
            date={new Date(state.message.createdAt ?? '')}
            titleChange={titleChange}
            isReadOnly={isReadOnly(state.message.sentOn)}
          />
          <Flag isSelected={state.message.importantFlag} updateFlag={!isReadOnly(state.message.sentOn) ? updateImportant : () => {}} />
          <Separator />
          <HtmlMessage isReadOnly={isReadOnly(state.message.sentOn)} onClick={() => history.push(`/create-message/${messageId}/htmlcode`)} />
          <Separator />
          <AudienceInfo
            audienceId={state.message.audienceId ?? ''}
            isReadOnly={isReadOnly(state.message.sentOn)}
            onClick={() => history.push(`/create-message/${messageId}/audience`)}
          />
          <Separator />
          <SchedulerTitle>スケジュール</SchedulerTitle>
          <SchedulerWrapper>
            <SchedulerLabel onClick={toggleDatePicker}>
              <span>送信日</span>
              <SchedulerInput
                placeholder="〇〇〇〇年〇〇月〇〇日"
                readOnly={isReadOnly(state.message.sentOn)}
                defaultValue={inputDateValue ? transformTimestampToJapaneseStringDate(inputDateValue.getTime()) : ''}
              />
              <CalendarIcon height={20} width={20} />
            </SchedulerLabel>
            <SchedulerLabel>
              <span>時刻を設定する</span>
              <SchedulerInput
                placeholder="〇〇：〇〇"
                value={inputTimeValue}
                readOnly={isReadOnly(state.message.sentOn)}
                onChange={(event) => setInputTimeValue(event.target.value)}
                pattern="([0-1]?[0-9]|2[0-3]):[0-5][0-9]"
              />
              <HourglassIcon height={20} width={18} />
            </SchedulerLabel>
            {!isReadOnly(state.message.sentOn) && (
              <SchedulerResetButton type="button" onClick={resetScheduler}>
                Reset
              </SchedulerResetButton>
            )}
            {showDatePickerContent && (
              <Calendar updateDate={updateDateInput} closeCalendar={toggleDatePicker} minTimestamp={getTimestampOfTheCurrentDay()} />
            )}
          </SchedulerWrapper>
          <Separator />
          <Description
            description={state.message.description ?? ''}
            isReadOnly={isReadOnly(state.message.sentOn)}
            updateDescription={descriptionChange}
          />
          <ErrorMessages messages={[timeErrorMessage, state.isSubmitted ? state.validationMessage : '', ...(apiState.errorResponseData ?? [])]} />
          <FormFooter
            isLoading={apiState.isLoading}
            isReadOnly={isReadOnly(state.message.sentOn)}
            cloneMessage={cloneMessage}
            closeModal={closeModal}
          />
        </UpdateMessageForm>
      )}
    </BaseContentModal>
  );
};

export default UpdateMessage;
