import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Bloc = styled.li<{ isCurrentPage: boolean; isChevron: boolean }>`
  display: inline-block;
  margin-top: 0;
  background-color: ${(props) => (props.isCurrentPage ? colors.blueLight : colors.grayLight)};
  list-style-type: none;
  height: 2.3rem;
  width: ${(props) => (props.isChevron ? '4.8rem' : '2.3rem')};
  border-radius: 6px;
  border: none;
  margin-right: 1rem;
  :last-child {
    margin-right: 0;
  }

  transition: background-color 400ms;

  :hover {
    background-color: ${colors.blueLight};
  }
`;

export const Button = styled.button<{ isCurrentPage: boolean; contentValue: string }>`
  cursor: ${(props) => (props.isCurrentPage ? 'default' : 'pointer')};
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;

  color: ${(props) => {
    if (props.isCurrentPage) {
      return colors.blueDark;
    }
    if (props.contentValue === 'NEXT' || props.contentValue === 'PREV') {
      return colors.gray;
    }
    return colors.grayDark;
  }};

  :hover {
    color: ${colors.blueDark};
  }
`;

export const Content = styled.span<{ contentValue: string }>`
  position: absolute;
  top: 50%;
  left: ${(props) => {
    if (props.contentValue === 'NEXT') {
      return '40%';
    }
    if (props.contentValue === 'PREV') {
      return '60%';
    }
    return '50%';
  }};
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 0.9rem;
`;

export const Chevron = styled.span<{ contentValue: string }>`
  position: absolute;
  top: 55%;
  left: ${(props) => {
    if (props.contentValue === 'PREV') {
      return '20%';
    }
    return '80%';
  }};
  margin-right: -50%;
  transform: translate(-50%, -50%);
  svg {
    transform: ${(props) => props.contentValue === 'NEXT' && 'rotate(180deg)'};
  }
`;

export const SelectedLine = styled.div`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  height: 0px;
  width: 2.3rem;
  border: solid 1px ${colors.orange};
`;
