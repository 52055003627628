import React, { createContext, useContext, useReducer } from 'react';
import { AudienceActionType, AudienceActions } from './actions';
import audienceContextInitialState, { AudienceContextState, AudienceFileErrorType, FileError, ValidationAudienceEnum } from './state';

export const AudienceContext = createContext<{
  state: AudienceContextState;
  dispatch: React.Dispatch<AudienceActions>;
}>({
  state: audienceContextInitialState,
  dispatch: () => undefined,
});

type AudienceProviderProps = {
  audienceContextInit?: AudienceContextState;
};

const audienceFileValidator = (audienceFile: string): AudienceFileErrorType[] => {
  const errors: AudienceFileErrorType[] = [];

  const file = audienceFile.split('\n');

  if (file.length <= 1) {
    errors.push({ error: FileError.EmptyFile, line: 1 });
    return errors;
  }

  if (!file[file.length - 1]) {
    file.pop();
  }

  for (let index = 1; index < file.length; index++) {
    const rowValues = file[index].split(',');
    if (rowValues.length === 2) {
      const firstPartyId = rowValues[0];
      const firstPolicyNb = rowValues[1];
      if (firstPartyId.length !== 15) {
        errors.push({ error: FileError.PartyId, line: index + 1 });
      }
      if (firstPolicyNb.length > 11 || firstPolicyNb.length < 9) {
        errors.push({ error: FileError.PolicyNumbers, line: index + 1 });
      }
    } else {
      errors.push({ error: FileError.ComaSeparation, line: index + 1 });
    }
  }
  return errors;
};

const audienceReducer = (state: AudienceContextState, action: AudienceActions): AudienceContextState => {
  switch (action.type) {
    case AudienceActionType.ResetForm:
      return { ...state, isSubmitted: false, isValid: false };
    case AudienceActionType.UpdateTitle:
      return { ...state, audience: { ...state.audience, title: action.payload.title } };
    case AudienceActionType.UpdateDescription:
      return { ...state, audience: { ...state.audience, description: action.payload.description } };
    case AudienceActionType.UpdateFile:
      return { ...state, audience: { ...state.audience, audienceFile: action.payload.file } };
    case AudienceActionType.ValidationFormCreation:
      // Title is required
      if (state.audience.title === '') {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationAudienceEnum.Title, verificationFile: [] };
      }
      // File is required
      if (!state.audience.audienceFile) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationAudienceEnum.File, verificationFile: [] };
      }
      // eslint-disable-next-line no-case-declarations
      const resultFileChecking = audienceFileValidator(action.payload.file);
      // File check
      if (resultFileChecking.length) {
        const listErrors = resultFileChecking.map((data) => `[Line ${data.line}] ${data.error}`);
        return { ...state, isSubmitted: true, isValid: false, validationMessage: '', verificationFile: listErrors };
      }
      // Method is required
      if (!state.audience.method) {
        return { ...state, isSubmitted: true, isValid: false, validationMessage: ValidationAudienceEnum.Method };
      }
      return { ...state, isSubmitted: true, isValid: true, validationMessage: '', verificationFile: [] };
    default:
      return state;
  }
};

export const AudienceProvider: React.FC<AudienceProviderProps> = ({ audienceContextInit, children }) => {
  const initialState = {
    ...audienceContextInitialState,
    ...audienceContextInit,
  };
  const [state, dispatch] = useReducer(audienceReducer, initialState);

  return <AudienceContext.Provider value={{ state, dispatch }}>{children}</AudienceContext.Provider>;
};

type UseAudienceContextType = {
  resetForm: () => void;
  updateTitle: (newTitle: string) => void;
  updateDescription: (newDescription: string) => void;
  updateFile: (newFile: File | null) => void;
  validationFormCreation: () => void;
  state: AudienceContextState;
};

export const useAudienceContext = (): UseAudienceContextType => {
  const { state, dispatch } = useContext(AudienceContext);

  const resetForm = () => dispatch({ type: AudienceActionType.ResetForm });
  const updateTitle = (newTitle: string) => dispatch({ type: AudienceActionType.UpdateTitle, payload: { title: newTitle } });
  const updateDescription = (newDescription: string) =>
    dispatch({ type: AudienceActionType.UpdateDescription, payload: { description: newDescription } });
  const updateFile = (newFile: File | null) => dispatch({ type: AudienceActionType.UpdateFile, payload: { file: newFile } });
  const validationFormCreation = async () => {
    if (state.audience.audienceFile) {
      const file = await state.audience.audienceFile?.text();
      dispatch({ type: AudienceActionType.ValidationFormCreation, payload: { file } });
    } else {
      dispatch({ type: AudienceActionType.ValidationFormCreation, payload: { file: '' } });
    }
  };

  return {
    resetForm,
    updateTitle,
    updateDescription,
    updateFile,
    validationFormCreation,
    state,
  };
};

export default audienceReducer;
