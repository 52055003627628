export const base = 0;

export const background = base - 1;
export const sidebar = base + 1;
export const header = sidebar + 1;
export const boxFilter = header + 1;
export const modal = boxFilter + 1;
export const modalContent = modal + 1;
export const baseModalContent = modalContent + 1;
export const backgroundCalendar = baseModalContent + 1;
export const calendar = backgroundCalendar + 1;
