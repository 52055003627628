/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const AxaIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = '#00008e', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 44" {...rest}>
      <rect className="a" fill="#00008e" width="44" height="44" />
      <path className="b" fill="#ff1721" d="M161.9-262.311,178.6-284h-2.467L159.38-262.311Z" transform="translate(-134.687 284)" />
      <path
        className="c"
        fill="#fff"
        d="M33.557-126.128c.766,2.142,2.343,7.715,2.97,8.159H32.386a6.906,6.906,0,0,0-.194-1.462c-.179-.637-1.676-5.456-1.676-5.456H23.938L22.9-123.42s1.242,3.888,1.319,4.079c.134.343.727,1.372.727,1.372H20.981s-.1-.594-.141-.841c-.031-.2-.376-1.294-.376-1.294a11.774,11.774,0,0,0-1.145,1.443c-.247.456-.357.692-.357.692h-3.1s-.1-.594-.142-.841c-.03-.2-.41-1.381-.41-1.381a15.671,15.671,0,0,0-1.117,1.511c-.246.457-.352.711-.352.711H10.766s.868-.826,1.171-1.181c.511-.6,2.417-3.092,2.417-3.092l-.764-2.645H7.063S3.341-120,3.194-119.844a14.877,14.877,0,0,0-1.257,1.875H0V-119.2a.929.929,0,0,1,.076-.074c.06-.044,2.822-3.472,5.359-6.853,2.28-2.948,4.419-5.815,4.607-6.088a19.112,19.112,0,0,0,1.11-2.086h3.375a15.091,15.091,0,0,0,.2,1.63c.088.281,2.144,7.028,2.192,7.1l1.137-1.454-1.943-5.982a10.651,10.651,0,0,0-.6-1.294h3.942a3.434,3.434,0,0,0,.129,1.072c.16.493,1.005,3.543,1.005,3.543s2.689-3.37,2.846-3.612a2.184,2.184,0,0,0,.332-1h3.285A10.24,10.24,0,0,0,25.4-132.53c-.354.449-3.823,4.857-3.823,4.857s.3,1.032.45,1.546c.04.148.068.247.068.258s.076-.089.208-.258c.895-1.134,4.966-6.472,5.212-6.929a10.176,10.176,0,0,0,.663-1.246h3.2a8.258,8.258,0,0,0,.172,1.222Zm-4.876-5.053a43.408,43.408,0,0,1-3.24,4.384h4.386s-.85-2.618-1-3.208a4.684,4.684,0,0,1-.086-1.147C28.742-131.205,28.732-131.292,28.681-131.181Zm-16.852,0a43.409,43.409,0,0,1-3.24,4.384h4.386s-.849-2.618-1-3.208a4.684,4.684,0,0,1-.086-1.147c0-.054-.011-.141-.062-.029Zm6.6,10.223,1.207-1.658c-.111-.12-.791-2.182-.791-2.182l-1.167,1.514Z"
        transform="translate(0 157.495)"
      />
    </svg>
  );
};

export default AxaIcon;
