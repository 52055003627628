import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  SideBox,
  HtmlCodeStepWrapper,
  CodeSideContentTextArea,
  HtmlCodeHeader,
  HtmlCodeHeaderTitle,
  HtmlCodeHeaderButton,
  Footer,
  HtmlCodeStepContentWrapper,
  CodeHtmlContentWrapper,
  PreviewContentWrapper,
  ContentLabel,
} from './HtmlCodeStep.styles';
import UploadImage from './UploadImage/UploadImage';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import BaseContentModal from '../../../components/BaseContentModal/BaseContentModal';
import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import CloudDownloadIcon from '../../../svgs/CloudDownloadIcon';
import colors from '../../../styles/colors';
import { useMessageContext } from '../../../context/MessageContext/context';
import useApi from '../../../hooks/useApi';
import { MessageResponseType, PatchMessageType } from '../../../types/Message.type';
import { CreateMessageParams } from '../../../types/CreateMessage.type';
import isReadOnly from '../../../utils/message';

const HtmlCodeStep: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [shouldMoveToNextStep, setShouldMoveToNextStep] = useState(false);
  const { messageId } = useParams<CreateMessageParams>();
  const history = useHistory();
  const { updateContent, validationContent, resetForm, state } = useMessageContext();
  const [apiState, makeRequest] = useApi<MessageResponseType, PatchMessageType>();

  useEffect(() => {
    resetForm();
    if (apiState.responseStatus === 200 && !apiState.hasError && shouldMoveToNextStep) {
      history.push(`/create-message/${messageId}/audience`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  useEffect(() => {
    if (state.isSubmitted && state.isValid) {
      makeRequest({
        url: `/msg-box-api/messages/${messageId}`,
        method: 'PATCH',
        data: {
          audienceId: state.message.audienceId,
          sentOn: state.message.sentOn,
          lastModifiedBy: state.message.lastModifiedBy,
          title: state.message.title,
          content: state.message.content,
          importantFlag: state.message.importantFlag,
          description: state.message.description,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSubmitted, state.isValid]);

  const modalAction = () => {
    setShowModal((currentState) => !currentState);
  };

  const renderPreview = () => {
    try {
      return parse(state.message.content ?? '');
    } catch (error) {
      return 'Error with html code';
    }
  };

  const saveContent = (withNextStep: boolean) => {
    if (withNextStep) {
      setShouldMoveToNextStep(true);
    }
    validationContent();
  };

  return (
    <HtmlCodeStepWrapper>
      <HtmlCodeStepContentWrapper>
        <SideBox>
          <HtmlCodeHeader>
            <HtmlCodeHeaderTitle>コードを編集する</HtmlCodeHeaderTitle>
            {!isReadOnly(state.message.sentOn) && (
              <HtmlCodeHeaderButton>
                <Button
                  backgroundColor={colors.axaBlue}
                  textColor={colors.white}
                  icon={<CloudDownloadIcon height={20} width={20} />}
                  onClick={modalAction}
                >
                  画像を挿入
                </Button>
              </HtmlCodeHeaderButton>
            )}
          </HtmlCodeHeader>
          <CodeHtmlContentWrapper>
            <ContentLabel htmlFor="content">Content</ContentLabel>
            <CodeSideContentTextArea
              id="content"
              name="content"
              rows={5}
              onChange={(event) => updateContent(event.target.value)}
              value={state.message.content ?? ''}
              placeholder="Typing..."
              readOnly={isReadOnly(state.message.sentOn)}
            />
          </CodeHtmlContentWrapper>
        </SideBox>
        <SideBox>
          <HtmlCodeHeader>
            <HtmlCodeHeaderTitle>プレビュー</HtmlCodeHeaderTitle>
          </HtmlCodeHeader>
          <PreviewContentWrapper data-testid="preview_render">{renderPreview()}</PreviewContentWrapper>
        </SideBox>
        <Modal isDisplay={showModal} close={modalAction}>
          <BaseContentModal title="写真やファイルをアップロードする" closeModal={modalAction}>
            <UploadImage />
          </BaseContentModal>
        </Modal>
      </HtmlCodeStepContentWrapper>
      <ErrorMessages messages={[state.isSubmitted ? state.validationMessage : '', ...(apiState.errorResponseData ?? [])]} />
      {!isReadOnly(state.message.sentOn) && (
        <Footer>
          <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={() => saveContent(false)} isLoading={apiState.isLoading}>
            保存する
          </Button>
          <Button backgroundColor={colors.axaBlue} textColor={colors.white} onClick={() => saveContent(true)} isLoading={apiState.isLoading}>
            保存して続行
          </Button>
        </Footer>
      )}
    </HtmlCodeStepWrapper>
  );
};

export default HtmlCodeStep;
