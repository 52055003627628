import styled, { keyframes } from 'styled-components';
import colors from '../../styles/colors';

const AnimationLoader = (height: number) => keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: ${height - height * 0.2}rem;
  }
  40% {
    box-shadow: 0 -${height / 2}rem;
    height: ${height}rem;
  }
`;

const LoaderWrapper = styled.div<{ height: number; width: number }>`
  background: ${colors.axaRed};
  animation: ${(props) => AnimationLoader(props.height)} 1s infinite ease-in-out;
  width: ${(props) => props.width}rem;
  color: ${colors.axaRed};
  margin: auto;
  position: relative;
  transform: translateZ(0);

  animation-delay: -200ms;

  :before {
    position: absolute;
    top: 0;
    left: -${(props) => props.width * 1.5}rem;
    content: '';
    background-color: ${colors.axaBlue};
    color: ${colors.axaBlue};
    animation: ${(props) => AnimationLoader(props.height)} 1s infinite ease-in-out;
    animation-delay: -400ms;
    width: ${(props) => props.width}rem;
  }

  :after {
    position: absolute;
    top: 0;
    left: ${(props) => props.width * 1.5}rem;
    content: '';
    background-color: ${colors.axaBlue};
    color: ${colors.axaBlue};
    animation: ${(props) => AnimationLoader(props.height)} 1s infinite ease-in-out;
    width: ${(props) => props.width}rem;
  }
`;

export default LoaderWrapper;
