import styled from 'styled-components';
import colors from '../../styles/colors';

export const CreateMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0 1.4rem;
`;

export const HeaderTitle = styled.h1`
  font-size: 1.1rem;
  line-height: 1.1rem;
`;

export const HeaderTitleSpan = styled.span<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? colors.axaBlue : colors.gray)};
  ::after {
    content: ' > ';
    color: ${colors.gray};
  }
  :last-child {
    ::after {
      content: '';
      color: ${colors.gray};
    }
  }
`;

export const ContentStepWrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
  overflow: hidden;
`;
