import { createGlobalStyle } from 'styled-components';
import colors from './colors';

const GlobalStyle = createGlobalStyle`
  #root {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    background-color: ${colors.background};
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, Meiryo, sans-serif;
  }
  a {
    text-decoration: none;
  }
  li {
    list-style: none;
  }
`;

export default GlobalStyle;
