import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmationContent, ConfirmationContentText, ConfirmationStepWrapper, Footer } from './ConfirmationStep.styles';
import IllustrationConfirmation from '../../../svgs/IllustrationConfirmation';
import Button from '../../../components/Button/Button';
import colors from '../../../styles/colors';

const ConfirmationStep: React.FC = () => {
  const history = useHistory();

  return (
    <ConfirmationStepWrapper>
      <IllustrationConfirmation height={500} width={500} />
      <ConfirmationContent>
        <ConfirmationContentText>メッセージがスケジュールされました。</ConfirmationContentText>
      </ConfirmationContent>
      <Footer>
        <Button
          backgroundColor={colors.axaBlue}
          textColor={colors.white}
          onClick={() => {
            history.push(`/messages`);
          }}
        >
          トップに戻る
        </Button>
      </Footer>
    </ConfirmationStepWrapper>
  );
};

export default ConfirmationStep;
