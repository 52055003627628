/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  width = '100%',
  height = '100%',
  ...rest
}) => {
  return (
    <svg viewBox="0 0 32 32" enableBackground="new 0 0 32 32" width={width} height={height} {...rest}>
      <rect fill={fill} x="10" y="15" width="12" height="2" />
      <rect fill={fill} x="15" y="10" width="2" height="12" />
      <circle fill="none" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" cx="16" cy="16" r="12" />
    </svg>
  );
};

export default PlusIcon;
