import styled from 'styled-components';
import colors from '../../../styles/colors';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 10rem;
`;

export const UpdateMessageForm = styled.form`
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: start;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin: 1.2rem 0;
  border-bottom: solid 1px ${colors.grayLight};
`;

export const SchedulerTitle = styled.h3`
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: 600;
  margin: 0 0 1rem 0;
  padding: 0;
`;

export const SchedulerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SchedulerLabel = styled.label`
  position: relative;

  span {
    display: block;
    height: 0;
  }

  svg {
    position: absolute;
    left: 0.8rem;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const SchedulerInput = styled.input`
  position: relative;
  border: solid 1px ${colors.grayLight};
  border-radius: 5px;
  background-color: ${colors.white};
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.darkGray};
  padding: 0.6rem 0.5rem 0.6rem 2.5rem;
  margin-right: 1rem;
  width: 7rem;
  :nth-last-child() {
    margin-right: 0px;
  }
  ::placeholder {
    font-style: italic;
    font-size: 0.6rem;
    color: ${colors.gray};
  }
`;

export const SchedulerResetButton = styled.button`
  color: ${colors.blue};
  background-color: ${colors.blueLight};
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0rem 1.5rem;
  cursor: pointer;

  :hover {
    background-color: ${colors.blue};
    color: ${colors.white};
  }
`;
