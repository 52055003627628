export const fromDateToJapaneseStringDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}年${String(month).padStart(2, '0')}月${String(day).padStart(2, '0')}日`;
};

export const transformTimestampToJapaneseStringDate = (value: number | null): string => {
  if (!value) {
    return '';
  }
  const dateObject = new Date(value);
  return fromDateToJapaneseStringDate(dateObject);
};

export const getTimestampOfTheCurrentDay = (): number => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};
