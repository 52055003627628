import styled from 'styled-components';

export const FooterFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const FooterFormReadOnlyWrapper = styled.div`
  text-align: right;
`;

export const CloneSubmitWrapper = styled.div`
  button:last-child {
    margin-left: 1rem;
  }
`;
