import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderStatus } from '../../../types/HeaderStatus.type';
import { HeaderItem, HeaderLink, HeaderSelectedItem, LinkContent } from './MenuItem.styles';

export type MenuItemProps = {
  id: string;
  menuName: string;
  url: string;
  isSelected: boolean;
  icon: React.ReactNode;
  headerStatus: HeaderStatus;
};

const MenuItem: React.FC<MenuItemProps> = ({ menuName, url, isSelected, icon, headerStatus, id }) => {
  return (
    <HeaderItem>
      {isSelected && <HeaderSelectedItem />}
      <Link to={url}>
        <HeaderLink isSelected={isSelected}>
          {icon}
          {headerStatus === 'extended' && <LinkContent>{menuName}</LinkContent>}
        </HeaderLink>
      </Link>
    </HeaderItem>
  );
};

export default MenuItem;
