import React, { useEffect, useRef, useState } from 'react';
import useFormValidator from '@alj-react/efo';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import ItemMessage from './ItemMessage/ItemMessage';
import TabHeaderItem from '../../components/TabHeaderItem/TabHeaderItem';
import CreateMessage from './CreateMessage/CreateMessage';
import UpdateMessage from './UpdateMessage/UpdateMessage';
import { MessageProvider } from '../../context/MessageContext/context';
import PageHeader from '../../components/PageHeader/PageHeader';
import Button from '../../components/Button/Button';
import Pagination from '../../components/Pagination/Pagination';
import Modal from '../../components/Modal/Modal';
import DialogueBox from '../../components/DialogueBox/DialogueBox';
import ErrorMessages from '../../components/ErrorMessages/ErrorMessages';
import Loader from '../../components/Loader/Loader';
import CheckBoxCustom from '../../components/CheckBox/CheckBox';
import TabHeader from '../../components/TabHeader/TabHeader';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ContentHeaderTitleSide from '../../components/ContentHeader/ContentHeaderTitleSide/ContentHeaderTitleSide';
import ContentHeaderActionSide from '../../components/ContentHeader/ContentHeaderActionSide/ContentHeaderActionSide';
import {
  MessagesPageWrapper,
  MessageContentWrapper,
  ContentListMessage,
  PaginationWrapper,
  ErrorWrapper,
  FilterContainer,
  FilterAdminContainer,
  FilteringKey,
  NoMessageText,
} from './Messages.styles';
import ListAltIcon from '../../svgs/ListAltIcon';
import TrashIcon from '../../svgs/TrashIcon';
import EnvelopeIcon from '../../svgs/EnvelopeIcon';
import colors from '../../styles/colors';
import useApi, { errorMessageFormatting } from '../../hooks/useApi';
import { MessageItem, MessageItemForSearch } from '../../types/Message.type';
import useToggleHeader from '../../hooks/useToggleHeader';
import axiosInstance from '../../utils/axios';
import SearchIcon from '../../svgs/SearchIcon';
import CaretIcon from '../../svgs/CaretIcon';
import FilterBox from './FilterBox/FilterBox';
import { initFilterForm } from './FilterBox/FilterForm';

const FIELDS_FOR_MESSAGE_LIST = 'id,sentOn,title,importantFlag,createdFrom';
const PAGE_NUMBER = 1;

const Messages: React.FC = () => {
  // Env var inside the components, for the unit tests
  const PAGE_SIZE = process.env.REACT_APP_MESSAGE_DEFAULT_PAGE_SIZE;
  const filterForm = useFormValidator(initFilterForm);
  const [listMessageSelected, setListMessageSelected] = useState<string[]>([]);
  const [shouldDisplayCreateMessageModal, setShouldDisplayCreateMessageModal] = useState(false);
  const [shouldDisplayUpdateMessageModal, setShouldDisplayUpdateMessageModal] = useState(false);
  const [shouldDisplayDialogueDeleteModal, setShouldDisplayDialogueDeleteModal] = useState(false);
  const [errorDeleteRequest, setErrorDeleteRequest] = useState<string[]>([]);
  const [errorDeleteLoadingRequest, setErrorDeleteLoadingRequest] = useState<boolean>(false);
  const [messageUpdating, setMessageUpdating] = useState('');
  const [isCreatedFromMessageBoxAdmin, setIsCreatedFromMessageBoxAdmin] = useState(true);
  const [{ responseData, responseHeaders, isLoading, errorResponseData }, makeRequest] = useApi<MessageItem[]>();
  const [messages, setMessages] = useState<MessageItem[]>();
  const [isFiltered, setIsFiltered] = useState<boolean>();
  const [filteringKey, setFilteringKey] = useState<string>('');
  const [isDisplayFilteringBox, setIsDisplayFilteringBox] = useState<boolean>(false);
  const totalMessages = useRef<MessageItemForSearch[]>([]);
  const { show } = useToggleHeader();
  const { oidcUser } = useReactOidc();

  const getMessages = (pageNumber = PAGE_NUMBER) => {
    makeRequest({
      url: `/msg-box-api/messages?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}&fields=${FIELDS_FOR_MESSAGE_LIST}&withAdditionalData=true${
        isCreatedFromMessageBoxAdmin ? '&createdFrom=messageBoxAdmin' : ''
      }`,
    });
  };

  useEffect(() => {
    show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMessages(responseData);
  }, [responseData]);

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedFromMessageBoxAdmin]);

  const filterMessages = (messagesFiltered: MessageItem[], key: string) => {
    setFilteringKey(key);
    setMessages(messagesFiltered);
    setIsDisplayFilteringBox((prevValue) => !prevValue);
    if (!key) {
      setIsFiltered(false);
      getMessages();
      return;
    }
    setIsFiltered(true);
  };

  const changePage = (pageNumber: number) => {
    setListMessageSelected([]);
    getMessages(pageNumber);
  };

  const selectMessage = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const index = listMessageSelected.indexOf(id);
    if (index === -1) {
      setListMessageSelected((arr) => [...arr, id]);
    } else {
      setListMessageSelected((arr) => arr.filter((item) => item !== id));
    }
  };

  const selectOrUnselectAllMessage = () => {
    if (responseData) {
      if (listMessageSelected.length >= responseData.filter((message) => !message.sentOn || new Date(message.sentOn) > new Date()).length) {
        setListMessageSelected([]);
      } else {
        responseData
          .filter((message) => !message.sentOn || new Date(message.sentOn) > new Date())
          .forEach((message) => {
            if (!listMessageSelected.includes(message.id)) {
              setListMessageSelected((arr) => [...arr, message.id]);
            }
          });
      }
    }
  };

  const displayCreateMessage = () => {
    setShouldDisplayCreateMessageModal((currentState) => !currentState);
  };

  const displayUpdateMessage = (messageId: string, withRefresh = false) => {
    setMessageUpdating(messageId);
    setShouldDisplayUpdateMessageModal((currentState) => !currentState);
    if (withRefresh) {
      getMessages();
    }
  };

  const displayDialogueDelete = () => {
    if (listMessageSelected.length > 0) {
      setShouldDisplayDialogueDeleteModal((currentState) => !currentState);
    }
  };

  const deleteMessage = async () => {
    try {
      setErrorDeleteLoadingRequest(true);
      setErrorDeleteRequest([]);
      const deletePromises = listMessageSelected.map(async (messageSelected) => {
        await axiosInstance.request({
          url: `/msg-box-api/messages/${messageSelected}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${oidcUser.access_token}`,
          },
        });
      });
      await Promise.all(deletePromises);
    } catch (error) {
      const listError = errorMessageFormatting(error);
      setErrorDeleteRequest(listError);
    } finally {
      setErrorDeleteLoadingRequest(false);
      setListMessageSelected([]);
    }
    getMessages();
    setShouldDisplayDialogueDeleteModal((currentState) => !currentState);
  };

  const filterAdminMessage = async () => {
    setIsCreatedFromMessageBoxAdmin((prevValue) => !prevValue);
  };

  const displayFilterContainer = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDisplayFilteringBox((prevValue) => !prevValue);
  };

  return (
    <>
      <MessagesPageWrapper>
        <PageHeader pageTitle="メッセージ" />
        <MessageContentWrapper>
          {[...(errorResponseData ?? []), ...errorDeleteRequest].length > 0 && (
            <ErrorWrapper>
              <ErrorMessages messages={[...(errorResponseData ?? []), ...errorDeleteRequest]} />
            </ErrorWrapper>
          )}
          <ContentHeader>
            <ContentHeaderTitleSide>
              <Button
                icon={<ListAltIcon height="22" width="22" />}
                backgroundColor={colors.blueLight}
                textColor={colors.blue}
                onClick={selectOrUnselectAllMessage}
              >
                すべて選択
              </Button>
              <Button
                icon={<TrashIcon height="20" width="20" />}
                backgroundColor={colors.blueLight}
                textColor={colors.blue}
                onClick={displayDialogueDelete}
              >
                削除
              </Button>
              <FilterAdminContainer isChecked={isCreatedFromMessageBoxAdmin}>
                {isLoading ? (
                  <Loader height={0.5} width={0.5} />
                ) : (
                  <CheckBoxCustom
                    id="messageFromAdmin"
                    value="messageFromAdmin"
                    checked={isCreatedFromMessageBoxAdmin}
                    label="Message From Admin"
                    onClick={filterAdminMessage}
                  />
                )}
              </FilterAdminContainer>
              <FilterContainer onClick={(event) => displayFilterContainer(event)} data-testid="filter-container">
                <SearchIcon height={20} width={20} />
                <FilteringKey>{filteringKey}</FilteringKey>
                <CaretIcon height={18} width={18} fill={colors.axaBlue} />
                {isDisplayFilteringBox && <FilterBox filterMessages={filterMessages} filterForm={filterForm} totalMessages={totalMessages} />}
              </FilterContainer>
            </ContentHeaderTitleSide>
            <ContentHeaderActionSide>
              <Button
                icon={<EnvelopeIcon height="24" width="24" />}
                backgroundColor={colors.blue}
                textColor={colors.white}
                onClick={displayCreateMessage}
              >
                新しいメッセージ
              </Button>
            </ContentHeaderActionSide>
          </ContentHeader>
          <TabHeader>
            <TabHeaderItem title="メッセージ" flexGrow={30} hasSortItem={false} />
            <TabHeaderItem title="状態" flexGrow={10} hasSortItem={false} />
            <TabHeaderItem title="送信先" flexGrow={10} hasSortItem={false} />
            <TabHeaderItem title="日付" flexGrow={20} hasSortItem={false} />
            <TabHeaderItem title="結果" flexGrow={30} hasSortItem={false} />
          </TabHeader>
          {isLoading && <Loader height={2} width={1} />}
          {isFiltered && messages?.length === 0 && <NoMessageText>結果はありません</NoMessageText>}
          {messages && !isLoading && (
            <ContentListMessage data-testid="message_list">
              {messages &&
                messages.map((message) => (
                  <ItemMessage
                    key={message.id}
                    id={message.id}
                    title={message.title ?? ''}
                    isChecked={listMessageSelected.includes(message.id)}
                    sentOn={message.sentOn ?? ''}
                    importantFlag={message.importantFlag}
                    audienceSize={message.additionalData ? message.additionalData.audienceSize : 0}
                    totalRead={message.additionalData ? message.additionalData.totalRead : 0}
                    onClickCheckBox={selectMessage}
                    onClickItem={() => displayUpdateMessage(message.id)}
                  />
                ))}
            </ContentListMessage>
          )}
          {!isFiltered && responseHeaders && messages && messages.length > 0 && (
            <PaginationWrapper>
              <Pagination
                itemPerPage={Number(responseHeaders['pagination-page-size'])}
                currentPage={Number(responseHeaders['pagination-current-page'])}
                numberOfItems={Number(responseHeaders['pagination-total-count'])}
                changePage={changePage}
              />
            </PaginationWrapper>
          )}
        </MessageContentWrapper>
      </MessagesPageWrapper>
      <Modal isDisplay={shouldDisplayCreateMessageModal} close={displayCreateMessage}>
        <MessageProvider>
          <CreateMessage closeModal={displayCreateMessage} />
        </MessageProvider>
      </Modal>
      <Modal isDisplay={shouldDisplayUpdateMessageModal} close={() => displayUpdateMessage('')}>
        <MessageProvider>
          <UpdateMessage messageId={messageUpdating} closeModal={(withRefresh) => displayUpdateMessage('', withRefresh)} />
        </MessageProvider>
      </Modal>
      <Modal isDisplay={shouldDisplayDialogueDeleteModal} close={displayDialogueDelete}>
        <DialogueBox
          title="削除"
          confirmationButtonText="削除する"
          isLoading={errorDeleteLoadingRequest}
          confirmation={deleteMessage}
          closeModal={displayDialogueDelete}
        >
          選択されたメッセージを本当に削除してよろしいですか？
        </DialogueBox>
      </Modal>
    </>
  );
};

export default Messages;
