import styled from 'styled-components';
import colors from '../../../styles/colors';

export const FlagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 1.5rem;
`;

export const ImportantButton = styled.input.attrs({ type: 'button' })<{ selected: boolean }>`
  border: none;
  display: inline-block;
  background-color: ${(props) => (props.selected ? colors.redLight : colors.white)};
  border: ${(props) => (props.selected ? `1px solid ${colors.redLight}` : `1px solid ${colors.grayLight}`)};
  color: ${(props) => (props.selected ? colors.red : colors.gray)};
  border-radius: 5px;
  padding: 0.4rem 2rem;
  font-size: 0.7rem;
  font-weight: 600;
`;
