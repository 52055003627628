import styled from 'styled-components';
import colors from '../../../styles/colors';

export const TitleLabel = styled.label`
  height: 0;
`;

export const TitleInput = styled.input`
  width: 100%;
  margin: 0;
  border: none;
  font-size: 1.1rem;
  color: ${colors.grayDark};
  font-weight: 600;
  outline: none;
`;

export const DateCreationInfo = styled.p`
  margin: 0;
  padding: 0;
  color: ${colors.gray};
  font-size: 0.9rem;
`;
