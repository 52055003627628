import React from 'react';
import { DescriptionLabel, DescriptionTextarea } from './DescriptionHelper.styles';

type DescriptionHelperProps = {
  description: string;
  isReadOnly: boolean;
  updateDescription(event: React.ChangeEvent<HTMLTextAreaElement>): void;
};

const DescriptionHelper: React.FC<DescriptionHelperProps> = ({ description, isReadOnly, updateDescription }) => {
  return (
    <>
      <DescriptionLabel htmlFor="description">説明</DescriptionLabel>
      <DescriptionTextarea
        id="description"
        name="description"
        value={description}
        readOnly={isReadOnly}
        placeholder={isReadOnly ? '' : 'Start typing...'}
        onChange={updateDescription}
        rows={4}
      />
    </>
  );
};

export default DescriptionHelper;
