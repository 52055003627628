/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const IllustrationScheduler: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'currentColor', width = '100%', height = '100%', ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 739.8 813.371">
      <defs>
        <linearGradient id="a" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="gray" stopOpacity="0.251" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.122" />
          <stop offset="1" stopColor="gray" stopOpacity="0.102" />
        </linearGradient>
        <linearGradient id="b" x1="0.5" y1="1" x2="0.5" xlinkHref="#a" />
      </defs>
      <g transform="translate(-202.6 -227.315)">
        <path
          className="a"
          opacity="0.2"
          fill="#1b51e5"
          d="M284.611,17.984C257.417,7.644,228.68-.993,199.344.092,156.211,1.684,114.806,25.949,94.529,61.578c-17.2,30.188-19.327,65.57-26.738,99.071-9.12,41.268-26.62,80.5-45.305,118.856C12.452,300.111,1.863,321.162.216,343.748c-2.283,31.36,13.742,62.609,38.875,83.468S97.6,459.082,131.1,461.482c17.827,1.277,36.456.093,52.29-7.669,16.753-8.212,28.965-23.049,45.787-31.144,15.587-7.5,33.822-8.551,51.322-7.576a243.561,243.561,0,0,1,67,13.326c24.461,8.588,47.206,21.032,71.58,29.743s51.673,13.573,76.615,6.305c23.449-6.83,41.961-23.413,58.652-40.207a712.727,712.727,0,0,0,74.8-88.465c15.063-21.106,29.083-43.372,35.741-67.97,12.736-47.03-4.788-100.089-43.658-132.207C589.03,109.01,545.81,97.46,503.5,90.452c-38.579-6.392-83.187-6.786-118.7-23.444C350.94,51.1,320.123,31.5,284.611,17.984Z"
          transform="translate(202.6 806.685) rotate(-60)"
        />
        <path
          className="b"
          strokeWidth="2px"
          strokeMiterlimit="10"
          stroke="#535461"
          fill="none"
          d="M0,128.277S62.668,75.766,76.6,49.291A378.226,378.226,0,0,1,108.279,0"
          transform="translate(693.92 482.134)"
        />
        <path className="c" fill="#1b51e5" d="M27.109,6.112,0,0S6.4,28.592,27.109,6.112Z" transform="translate(766.851 535.21)" />
        <path className="c" fill="#1b51e5" d="M4.1,0,19.7,23.361S-10.8,25.564,4.1,0Z" transform="translate(748.437 512.134)" />
        <path className="c" fill="#1b51e5" d="M24.776,4.343,0,0S15.562,23.671,24.776,4.343Z" transform="translate(779.85 515.02)" />
        <path className="c" fill="#1b51e5" d="M2.369,0l12.2,15.983S-7.031,18.031,2.369,0Z" transform="translate(765.592 498.484)" />
        <path
          className="c"
          fill="#1b51e5"
          d="M0,0S21.1,1.005,23.578,3.022,20.432,16.858,12.41,14.929,0,0,0,0Z"
          transform="translate(792.663 495.301)"
        />
        <path
          className="c"
          fill="#1b51e5"
          d="M7.854,0s8.618,15.7,8.3,18.695S3.014,25.042.427,13.731,7.854,0,7.854,0Z"
          transform="translate(776.376 476.445)"
        />
        <circle className="d" fill="#ffd037" cx="5.866" cy="5.866" r="5.866" transform="translate(798.077 490.5) rotate(-72.474)" />
        <circle className="d" fill="#ffd037" cx="5.866" cy="5.866" r="5.866" transform="translate(788.894 484.463) rotate(-72.474)" />
        <path
          className="c"
          fill="#1b51e5"
          d="M153.489,149.708s10.058-29.919-53.7-65.988c0,0-64.74-18.881-58.12-83.721,0,0-74.631,54.676-24.13,124.325s94.089,54.447,94.089,54.447Z"
          transform="translate(344.963 666.546)"
        />
        <path
          className="b"
          strokeWidth="2px"
          strokeMiterlimit="10"
          stroke="#535461"
          fill="none"
          d="M100.913,163.222S69.8,129.6,39.982,116.6a59.386,59.386,0,0,1-16.2-10.79,74.138,74.138,0,0,1-22.4-68.873L8.549,0"
          transform="translate(378.1 666.546)"
        />
        <rect className="e" fill="#e2e2ec" width="354.05" height="411.084" transform="translate(401.416 440.6)" />
        <rect className="f" fill="#f7f7fd" width="345.804" height="401.504" transform="translate(405.542 445.384)" />
        <rect className="g" fill="#e2e2ec" width="87.419" height="6.962" transform="translate(426.427 490.641)" />
        <rect className="g" fill="#e2e2ec" width="149.305" height="6.962" transform="translate(426.427 507.661)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 483.99)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(672.409 483.679)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 483.99)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(609.74 483.679)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 538.145)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(672.409 537.835)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 538.145)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(609.74 537.835)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(547.42 538.145)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(547.103 537.835)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(484.758 538.145)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(484.442 537.835)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(422.096 538.145)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(421.78 537.835)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 592.294)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(672.409 591.99)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 592.294)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(609.74 591.99)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(547.42 592.294)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(547.103 591.99)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(484.758 592.294)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(484.442 591.99)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(422.096 592.294)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(421.78 591.99)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 646.449)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(672.409 646.139)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 646.449)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(609.74 646.139)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(547.42 646.449)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(547.103 646.139)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(484.758 646.449)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(484.442 646.139)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(422.096 646.449)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(421.78 646.139)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 700.598)" />
        <path className="g" fill="#e2e2ec" d="M0,54.149V0H62.668V54.149Z" transform="translate(672.409 700.294)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 700.598)" />
        <path className="g" fill="#e2e2ec" d="M0,54.149V0H62.668V54.149Z" transform="translate(609.74 700.294)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(547.42 700.598)" />
        <path className="g" fill="#e2e2ec" d="M0,54.149V0H62.668V54.149Z" transform="translate(547.103 700.294)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(484.758 700.598)" />
        <path className="g" fill="#e2e2ec" d="M0,54.149V0H62.668V54.149Z" transform="translate(484.442 700.294)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(422.096 700.598)" />
        <path className="g" fill="#e2e2ec" d="M0,54.149V0H62.668V54.149Z" transform="translate(421.78 700.294)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(672.744 754.753)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(672.409 754.443)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(610.082 754.753)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(609.74 754.443)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(547.42 754.753)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(547.103 754.443)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(484.758 754.753)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(484.442 754.443)" />
        <rect className="h" fill="#fff" width="62.041" height="53.535" transform="translate(422.096 754.753)" />
        <path className="g" fill="#e2e2ec" d="M0,54.155V0H62.668V54.155Z" transform="translate(421.78 754.443)" />
        <path
          className="c"
          fill="#1b51e5"
          d="M38.4,15.6c1.775-1.346,3.444-2.935,3.959-4.871a4.12,4.12,0,0,0-2.767-4.964c-2.538-.794-5.249.62-7.309,2.091s-4.412,3.1-7.1,2.8A7.919,7.919,0,0,0,28.519,2.4,3.2,3.2,0,0,0,27.589.722C26.18-.519,23.624.02,21.936.988c-5.367,3.1-6.862,9.028-6.893,14.383-.54-1.93-.087-3.94-.1-5.926A5.637,5.637,0,0,0,12.219,4.2a9.748,9.748,0,0,0-4.157-.8c-2.414-.074-5.1.124-6.745,1.564C-.73,6.753-.2,9.756,1.585,11.723s4.492,3.2,6.98,4.56a14.246,14.246,0,0,1,4.964,3.884,3.524,3.524,0,0,1,.372.695H29.022A43.477,43.477,0,0,0,38.4,15.6Z"
          transform="translate(514.011 841.767)"
        />
        <circle className="i" fill="#fc6681" cx="3.537" cy="3.537" r="3.537" transform="translate(426.744 542.749)" />
        <circle className="d" fill="#ffd037" cx="3.537" cy="3.537" r="3.537" transform="translate(435.579 542.749)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(551.633 595.179)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(560.475 595.179)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(740.735 696.503)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(742.504 692.383)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(744.272 688.846)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(678.291 542.749)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(687.126 542.749)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(695.968 542.749)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(704.804 542.749)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(678.291 652.318)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(687.126 652.318)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(695.968 652.318)" />
        <circle className="c" fill="#1b51e5" cx="3.537" cy="3.537" r="3.537" transform="translate(704.804 652.318)" />
        <circle className="d" fill="#ffd037" cx="3.537" cy="3.537" r="3.537" transform="translate(489.188 595.179)" />
        <circle className="i" fill="#fc6681" cx="3.537" cy="3.537" r="3.537" transform="translate(426.744 648.788)" />
        <path
          className="j"
          d="M8.207,378.7c-8.153-2.134-5.975-9.6-1.632-9.071a19.86,19.86,0,0,0,6.509-.857c-2.538-2.972-.509-7.011,2.73-6.614,4.343.534,14.122-3.344,14.122-3.344l.043.062a7.493,7.493,0,0,1,.962-.292,108.932,108.932,0,0,0,10.753-3.028c2.1-4.5,6.93-16.579,3.461-25.954-1.57-4.238-2.078-9.872-2.078-15.339a175.277,175.277,0,0,0-7.16-48.726l-6.515-22.4s-9.239-27.245-9.785-34.691l.075-10.9C12.029,202.476,5.98,200.986,2.1,198.7a4.194,4.194,0,0,1-.652-6.807,33.4,33.4,0,0,0,5.69-6.98c3.326-5.23,8.9-8.059,12.72-9.462v-1.7l-.236-.043.105-1.582h-.105l.273-4.176v-.038l1.359-20.867s4.9-13.874-3.8-26.681-.546-25.086,7.607-29.888c0,0,3.81-20.283.546-25.086a139.793,139.793,0,0,1,13.65-6.962q.105-.379.2-.77c.086-1.893.2-3.81.31-5.671a20.721,20.721,0,0,0-.533-2.911c-.1-.4-.218-.8-.348-1.21A20.825,20.825,0,0,1,25.592,28.55a20.478,20.478,0,0,1,1.266-7.129,9.126,9.126,0,0,1-.937-2.277,9.167,9.167,0,0,1,1.291-6.658C29.372,7.72,34.807,3.278,38.753,1.665,44.405-.631,52.912-.1,58.794.61c3.3.4,7.073,1.508,8.265,4.56a30.331,30.331,0,0,1,.757,3.512c1.229,4.667,6.577,6.837,9.872,10.4,2.91,3.146,4.194,7.4,5.137,11.553A106.635,106.635,0,0,1,84.73,66.92c-.329,2.785-.77,5.559-1.241,8.333,1.979,2.792,3.772,7.737,2.953,16.324l6.521,30.955,2.718,18.149a15.86,15.86,0,0,1-4.89,13.88c-4.163,4.082-9.208,10.989-12.31,15.456a36.833,36.833,0,0,1,2.023,12.28c-.068,1.9-.174,3.741-.3,5.491a48.932,48.932,0,0,1-2.085,10.939c-.763,7.452-2.271,16.982-5.218,23.063l-1.1,32.557s1.632,29.888,4.89,35.758-7.068,55.508-7.068,55.508v6.93l1.744,5.132.434-.329c8.153,10.157,0,13.881,0,13.881s-2.215.105-5.764.242a6.551,6.551,0,0,1-3.474,7.234s-20.531.949-36.634.949C17.87,379.652,10.925,379.414,8.207,378.7Z"
          transform="translate(739.118 510.215)"
        />
        <path
          className="k"
          fill="#db8b8b"
          d="M43.433,4.157l2.091,6.273L38.7,17.255,16.213,22.486,0,20.916l1.049-5.752h0A6.937,6.937,0,0,1,6.2,10.1C12.862,8.532,25.57,4.958,28.238,0Z"
          transform="translate(764.481 856.17)"
        />
        <path
          className="l"
          d="M43.433,4.157l2.091,6.273L38.7,17.255,16.213,22.486,0,20.916l1.049-5.752h0A6.937,6.937,0,0,1,6.2,10.1C12.862,8.532,25.57,4.958,28.238,0Z"
          transform="translate(764.481 856.17)"
        />
        <path
          className="m"
          d="M17.321,10.455l3.14-4.182-3.14-4.846S7.909,5.224,3.727,4.7s-6.273,6.825,1.57,8.891,52.294,0,52.294,0S65.433,9.934,57.59,0L50.765,5.231s-11.5,6.273-17.255,5.231Z"
          transform="translate(752.415 865.036)"
        />
        <path
          className="k"
          fill="#db8b8b"
          d="M0,20.935,16.213,22.5,38.7,17.274l6.825-6.825-.552-1.663L44.19,6.453l-.782-2.271L28.244,0a9.394,9.394,0,0,1-3.1,3.1C20.215,6.472,11.367,8.9,6.174,10.12a5.442,5.442,0,0,0-.62.192,6.912,6.912,0,0,0-4.5,4.877Z"
          transform="translate(755.614 863.435)"
        />
        <path
          className="l"
          d="M0,12.149l16.213,1.57L38.7,8.488l6.825-6.825L44.972,0,38.73,4.8s-11.5,6.273-17.255,5.231H5.231L8.37,5.851,5.553,1.508a6.912,6.912,0,0,0-4.5,4.877Z"
          transform="translate(755.614 872.221)"
        />
        <path
          className="m"
          d="M17.321,10.455l3.14-4.182-3.14-4.846S7.909,5.224,3.727,4.7s-6.273,6.825,1.57,8.891,52.294,0,52.294,0S65.433,9.934,57.59,0L50.765,5.231s-11.5,6.273-17.255,5.231Z"
          transform="translate(743.524 872.358)"
        />
        <path
          className="k"
          fill="#db8b8b"
          d="M0,27.71s25.1,2.612,21.444-7.843a18.825,18.825,0,0,1-.776-9.233A25.6,25.6,0,0,1,25.1,0L1.57,3.661a29.473,29.473,0,0,1,3.189,7.557C7.564,22.306,0,27.71,0,27.71Z"
          transform="translate(773.918 551.795)"
        />
        <path
          className="n"
          fill="#474463"
          d="M30.36,0s-.521,22.486-5.752,33.468l-1.049,31.9s1.57,29.286,4.709,35.038-6.825,54.385-6.825,54.385v8.37S4.691,169.433,0,162.112L4.709,99.878,2.618,13.595Z"
          transform="translate(786.44 698.736)"
        />
        <path
          className="l"
          d="M30.36,0s-.521,22.486-5.752,33.468l-1.049,31.9s1.57,29.286,4.709,35.038-6.825,54.385-6.825,54.385v8.37S4.691,169.433,0,162.112L4.709,99.878,2.618,13.595Z"
          transform="translate(786.44 698.736)"
        />
        <path
          className="i"
          fill="#fc6681"
          d="M23.528,0l3.14,8.37L29.28,26.153a15.729,15.729,0,0,1-4.709,13.595C18.3,46.021,9.928,59.094,9.928,59.094L0,29.311s9.928-1.57,8.37-8.37-1.055-19.4-1.055-19.4Z"
          transform="translate(803.732 626.569)"
        />
        <path
          className="o"
          opacity="0.05"
          d="M23.528,0l3.14,8.37L29.28,26.153a15.729,15.729,0,0,1-4.709,13.595C18.3,46.021,9.928,59.094,9.928,59.094L0,29.311s9.928-1.57,8.37-8.37-1.055-19.4-1.055-19.4Z"
          transform="translate(803.732 626.569)"
        />
        <path
          className="l"
          d="M0,3.109C6.707,13.657,17.15,7.681,19,6.49l-.757-2.308L3.078,0A9.394,9.394,0,0,1,0,3.109Z"
          transform="translate(780.781 863.435)"
        />
        <path
          className="n"
          fill="#474463"
          d="M0,58.07c.521,7.322,9.413,33.99,9.413,33.99l6.273,21.946a174.627,174.627,0,0,1,6.893,47.739c0,5.355.49,10.877,2,15.028,4.182,11.5-4.182,27.2-4.182,27.2,7.322,13.073,19.855,4.182,19.855,4.182v-8.364s9.928-45.5,7.322-54.385-3.661-32.947-3.661-32.947V69.022A49.253,49.253,0,0,0,58.269,37.8c.124-1.713.223-3.512.292-5.38.422-11.789-7.644-25.632-10.8-30.558C47.007.67,46.523,0,46.523,0L.347,18.316l-.105.043V24.1Z"
          transform="translate(759.802 661.061)"
        />
        <path
          className="l"
          d="M0,3.655a29.473,29.473,0,0,1,3.189,7.557A20.476,20.476,0,0,0,19.1,10.629,25.6,25.6,0,0,1,23.535,0Z"
          transform="translate(775.488 551.795)"
        />
        <circle className="k" fill="#db8b8b" cx="20.395" cy="20.395" r="20.395" transform="translate(765.554 522.496)" />
        <path
          className="l"
          d="M0,24.056l.223.043C22.151,28.61,49.681,5.752,49.681,5.752a27.1,27.1,0,0,1-1.9-3.89C47.02.67,46.536,0,46.536,0L.36,18.316l-.118,1.83Z"
          transform="translate(759.802 661.086)"
        />
        <path
          className="i"
          fill="#fc6681"
          d="M11.436,12.229S40.722-5.553,50.135,1.768L63.208,18.521s8.37,2.612,6.825,19.346L76.281,68.19,57.456,72.9S46.474,83.361,55.365,98.525c0,0-27.717,23.007-49.681,18.3l1.57-24.577s4.709-13.595-3.661-26.147-.521-24.577,7.322-29.286C10.914,36.813,14.569,16.939,11.436,12.229Z"
          transform="translate(754.119 566.749)"
        />
        <path
          className="k"
          fill="#db8b8b"
          d="M23.048,0S12.065,2.091,6.835,10.461a32.594,32.594,0,0,1-5.473,6.825,4.151,4.151,0,0,0,.62,6.614c4.151,2.482,10.92,4.027,19.471-3C36.121,8.891,23.048,0,23.048,0Z"
          transform="translate(740.937 685.663)"
        />
        <path
          className="l"
          d="M17.752.393S28.213-.656,30.825,26.539,37.65,60.008,37.65,60.008,40.263,74.13,33.99,82.494s-18.3,26.147-18.3,26.147S2.612,112.3,0,99.228L16.213,68.9s2.612-10.461-3.661-17.783S1.539-4.838,17.752.393Z"
          transform="translate(762.446 588.526)"
        />
        <path
          className="i"
          fill="#fc6681"
          d="M17.752.393S28.213-.656,30.825,26.539,37.65,60.008,37.65,60.008,40.263,74.13,33.99,82.494s-18.3,26.147-18.3,26.147S2.612,112.3,0,99.228L16.213,68.9s2.612-10.461-3.661-17.783S1.557-4.838,17.752.393Z"
          transform="translate(761.378 586.956)"
        />
        <path className="l" d="M.1,0S-.944,16.194,3.747,25.1s4.709,20.4,0,26.68" transform="translate(770.419 606.956)" />
        <path className="l" d="M0,8.891S4.182,4.709,16.213,4.182,33.468,0,33.468,0" transform="translate(767.906 578.196)" />
        <path
          className="l"
          d="M31.221.6c3.177.391,6.825,1.483,7.954,4.467A30.567,30.567,0,0,1,39.9,8.513c1.185,4.567,6.329,6.695,9.5,10.188,2.8,3.1,4.033,7.253,4.964,11.317a106.35,106.35,0,0,1,1.861,35.528c-1.018,8.687-3.1,17.212-3.686,25.923s.484,17.876,5.243,25.2c2.048,3.146,4.753,5.913,6.2,9.375-3,2.432-7.135,2.78-11,2.91q-6.745.23-13.5,0c-2.358-.081-4.821-.236-6.856-1.427a10.585,10.585,0,0,1-3.6-3.866c-2.575-4.343-3.22-9.617-3.1-14.68s.912-10.1,1.005-15.171c.05-2.761-.168-5.7-1.756-7.954-1.148-1.632-2.891-2.73-4.381-4.052-5.584-4.964-7.52-12.918-7.706-20.4s1.049-14.966.416-22.418c-.248-2.916-1.34-6.422-4.207-6.987a14.806,14.806,0,0,1-2.73-.347c-2.3-.887-2.079-4.132-2.736-6.509C3.1,22.48.824,20.42.185,17.7-1.323,11.373,6.731,3.822,12.011,1.619,17.378-.627,25.562-.093,31.221.6Z"
          transform="translate(765.773 515.938)"
        />
        <path
          className="p"
          fill="#472727"
          d="M31.221.606C34.4,1,38.046,2.089,39.176,5.074A30.567,30.567,0,0,1,39.9,8.517c1.185,4.567,6.329,6.695,9.5,10.188,2.8,3.1,4.033,7.253,4.964,11.317a106.35,106.35,0,0,1,1.861,35.528c-1.018,8.687-3.1,17.212-3.686,25.923s.484,17.876,5.243,25.2c2.048,3.146,4.753,5.913,6.2,9.375-3,2.432-7.135,2.78-11,2.91q-6.745.23-13.5,0c-2.358-.081-4.821-.236-6.856-1.427a10.585,10.585,0,0,1-3.6-3.866c-2.575-4.343-3.22-9.617-3.1-14.68s.912-10.1,1.005-15.171c.05-2.761-.168-5.7-1.756-7.954-1.148-1.632-2.891-2.73-4.381-4.052-5.584-4.964-7.52-12.918-7.706-20.4S14.146,46.447,13.513,39c-.248-2.916-1.34-6.422-4.207-6.987a14.806,14.806,0,0,1-2.73-.347c-2.3-.887-2.079-4.132-2.736-6.509C3.1,22.484.824,20.424.185,17.707-1.323,11.378,6.731,3.827,12.011,1.624,17.378-.628,25.562-.095,31.221.606Z"
          transform="translate(766.294 514.891)"
        />
      </g>
    </svg>
  );
};

export default IllustrationScheduler;
